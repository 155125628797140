import React, { useState, useEffect } from "react";
import { Row, Col, Card, Divider, Button, message, Tooltip, Modal, Input, Spin, Badge, Layout, Form, Menu, List, Progress } from "antd";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import Settings from "../../../../assets/images/settings.gif";
import Sync from "../../../../assets/images/dataSync.gif";
import Cashup from "../../../../assets/images/cashup.gif";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import Logo from "../../../../assets/images/logo-without-bg.png";
import leftLoading from "../../../../assets/images/opo.gif";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import userIcon from "../../../../assets/images/userId.svg";
import Online from "../../../../assets/images/green.svg";
import Ofline from "../../../../assets/images/red.svg";
import LockScreen from "../../../../assets/images/lockScreen.svg";
import db from "../../../../database";
import Axios from "axios";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import kioskBGI from "../../../../assets/images/kioskBGI.png";
import { useGlobalContext } from "../../../../lib/auth/globalStore";
import { processSync } from "../../../Sync/syncData";
import { clock } from "../../../../utility/clock";
import LoadingScreen from "../../Retail/SuperMarket/LoadingScreen";

const AdminComponent = (props) => {
  const { access_token, username } = getOAuthHeaders();
  console.log("username---------->", username);
  const SSOURL = process.env.REACT_APP_redirectURL;
  const domainURL = process.env.REACT_APP_domain;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const { kioskUI, setKioskUI, isLocked, setIsLocked, showAdminDetails, setShowAdminDetails, syncLoad, setSyncLoad } = props;
  const [showLogin, setShowLogin] = useState(false);
  const history = useHistory();
  let tillData = JSON.parse(localStorage.getItem("tillData"));
  const serverUrl = process.env.REACT_APP_serverUrl;
  // const [isLocked, setIsLocked] = useState(false);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  // const [syncLoad, setSyncLoad] = useState(false);
  const { globalStore, setGlobalStore } = useGlobalContext();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [clock1, setClock1] = useState(clock());

  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const userData = JSON.parse(localStorage.getItem("userData"));

  const logoStyles = isThemeLogo
    ? { height: "8vh", position: "relative", cursor: "pointer" } // Styles for theme logo
    : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  const handleLoginClick = () => {
    console.log("Login button clicked");
    setShowLogin(false);
  };

  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  const handleLogin = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());

    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }
    localStorage.setItem("locked", isLocked);
    localStorage.setItem("redirected", "true");
    const redirectWithIsLocked = `${SSOURL}sign-in/?username=${username}&readOnly=true`;
    window.location.assign(redirectWithIsLocked);
    // setShowLogin(false);
    // setShowAdminDetails(true);
    setKioskUI(6);
    const customEvent = new CustomEvent("customStorageChange", {
      detail: { key: "kioskUI", newValue: 6 },
    });
    window.dispatchEvent(customEvent);
    localStorage.setItem("kioskUI", 6);
    setShowLogin(false);
  };

  const mainDivStyles = themeJSON.appBgImageURL
    ? {
        // backgroundImage: `url(${themeJSON.appBgImageURL})`,
        // backgroundPosition: "100% 100%",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        // display: isLocked ? "none" : "block",
      }
    : {
        // backgroundImage: `url(${kioskBGI})`,
        // backgroundSize: "cover",
        // backgroundPosition: "10% 10%",
        height: "100vh",
        width: "100%",
        position: "absolute",
      };

  return (
    <div style={mainDivStyles}>
      {syncLoad ? (
        <>
          <div style={{ backgroundColor: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", width: "100%" }}>
            <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
              <Col span={6}>
                <img src={Logo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} />
              </Col>
              <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "1vw", fontSize: "1vw" }}>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{formattedDateTime}</div>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{clock1}</div>
              </Col>
            </Row>
            <div style={{ display: "flex", height: "90vh" }}>
              <div
                style={{
                  width: "48%",
                  background: "#fff",
                  height: "83vh",
                  margin: "1.5%",
                  marginRight: 0,
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1vw" }}>
                  <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
                    <img
                      src={leftLoading}
                      alt="Loading"
                      style={{
                        width: "100%",
                        height: "100%",
                        // transform: `rotate(${rotation}deg)`,
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "1.5em",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {percent}
                    </div>
                  </div>
                  <p style={{ color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "2.5em", marginBottom: 0 }}>Till loading in process</p>
                  {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
           <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
                </div>
              </div>
              <div style={{ width: "48%", height: "83vh", background: "#fff", margin: "1.5%", borderRadius: "10px", color: "#0F0718" }}>
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "17vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>
                  Product Categories
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productCategoryPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Products</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Promotions</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={promotionPercentage}
                  status="active"
                />
              </div>
            </div>
          </div>
        </>
      ) : isLocked ? (
        <div style={{ backgroundColor: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", width: "100%" }}>
          <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
            <Col span={6}>
              <img
                // src={POSLogo}
                style={{
                  height: "2.8vw",
                  paddingLeft: "1vw",
                  paddingTop: "1vh",
                }}
              />
            </Col>
            <Col
              span={18}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                paddingRight: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  padding: "0.5vw",
                  border: "1px solid #3C424B",
                  borderRadius: "50%",
                }}
              >
                <img src={userIcon} style={{ height: "2.7vh" }} />
              </div>
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "1vw",
                  fontFamily: "Inter",
                  padding: "0 2%",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
              </span>
              <div style={{ paddingRight: "2%" }}>
                <img src={navigator.onLine ? Online : Ofline} style={{ height: "3.5vh", cursor: "pointer" }} />
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: "17vh" }}>
            <Col span={8} />
            <Col
              span={8}
              style={{
                background: "#fff",
                borderRadius: "5px",
                height: "45vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 4px 4px 0px #00000033",
              }}
            >
              <img
                src={LockScreen}
                style={{
                  marginBottom: "0.2vh",
                  marginTop: "-17vh",
                  width: "25%",
                }}
                alt=""
              />
              <span style={{ fontSize: "14px", fontWeight: 400 }}>Screen Locked </span>
              <Form layout="vertical" style={{ width: "80%" }}>
                <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                  <label style={{ fontWeight: 500, fontSize: "15px" }}>Username</label>
                  <Form.Item>
                    <Input
                      value={userData.user}
                      style={{
                        width: "100%",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                  <Button
                    style={{
                      background: "#2F3856",
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#fff",
                      width: "100%",
                      height: "7vh",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={handleLogin}
                  >
                    Log In
                  </Button>
                </Col>
              </Form>
            </Col>
            <Col span={8} />
          </Row>
          <Row style={{ position: "absolute", bottom: "1vh" }}>
            <Col span={15} />
            <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div
                // className="bottom-img"
                //  onClick={()=>setKioskUI(0)}
                onClick={() => {
                  setKioskUI(0);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                  localStorage.setItem("kioskUI", 0);
                  // setIsLocked(false);
                  // setShowLogin(false);
                }}
              >
                <img src={backImg} style={{ height: "2.7vw", cursor: "pointer" }} />
                <p style={{ color: "#2F3856", margin: "0", fontSize: "2vh", marginLeft: "0.5vw", cursor: "pointer" }}>Back</p>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div
            style={{
              height: "9vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "0 1.2vw",
              paddingTop: "3vh",
            }}
          >
            <img
              // src={themeJSON.logoURL || kisokLogo}
              alt="Kiosk Logo"
              src={logoURL}
              style={logoStyles}
            />
          </div>
          {showLogin ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5vh",
                flexDirection: "column",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <div>
                <p
                  style={{
                    color: "#2F3856",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "3vh",
                    fontWeight: "600",
                    margin: 0,
                  }}
                >
                  WELCOME ADMIN
                </p>
                <p
                  style={{
                    color: "#2F3856",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "1.7vh",
                    fontWeight: "400",
                    marginTop: 0,
                  }}
                >
                  Please sign in with your username and password
                </p>
              </div>
              <Form Form layout="vertical" name="kioskLogin">
                <Form.Item name={"email"} label={<p style={{ margin: "0", color: "#2F3856", fontSize: "1.4vw" }}>Username</p>}>
                  <input
                    inputMode="none"
                    className="ad-input-field"
                    autoFocus={true}
                    style={{ width: "26.2rem", padding: "0.5rem", boxSizing: "border-box", border: "1px solid" }}
                  />
                </Form.Item>
                <Form.Item name={"password"} label={<p style={{ margin: "0", color: "#2F3856", fontSize: "1.4vw" }}>Password</p>}>
                  <input
                    inputMode="none"
                    className="ad-input-field"
                    autoFocus={true}
                    style={{ width: "26.2rem", padding: "0.5rem", boxSizing: "border-box", border: "1px solid" }}
                  />
                </Form.Item>
              </Form>
              <Button
                style={{
                  // backgroundColor: "#EEC302",
                  backgroundColor: themeJSON.btnsBgColor || "#EEC302",
                  width: "26.2rem",
                  height: "3.7rem",
                  borderRadius: "7px",
                  color: "#fff",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                  marginTop: "2vh",
                }}
                onClick={handleLoginClick}
              >
                Login
              </Button>

              <div className="left-bottom-images">
                <div
                  className="bottom-img"
                  //  onClick={()=>setKioskUI(0)}
                  onClick={() => {
                    setKioskUI(0);
                    const customEvent = new CustomEvent("customStorageChange", {
                      detail: { key: "kioskUI", newValue: 0 },
                    });
                    window.dispatchEvent(customEvent);
                    localStorage.setItem("kioskUI", 0);
                  }}
                >
                  <img src={backImg} style={{ height: "2.7vw" }} />
                  <p style={{ color: "#2F3856", margin: "0", fontSize: "2vh" }}>Back</p>
                </div>
                <div className="bottom-img">
                  <img src={helpImg} style={{ height: "2.7vw" }} />
                  <p style={{ color: "#2F3856", margin: "0", fontSize: "2vh" }}>Help</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                maxHeight: "90vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "2%",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "28vh",
                    width: "35vw",
                    // backgroundColor: "#626367",
                    backgroundColor: themeJSON.productTableBGColor || "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "2%",
                    borderRadius: "10px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                  }}
                  // onClick={() => setShowLogin(true)}
                  onClick={() => {
                    let posConfig = JSON.parse(localStorage.getItem("posConfig"));
                    if (posConfig.shiftClose === "Y") {
                      if (posConfig.showDenominations === "Y") {
                        history.push("/close-till");
                      } else if (posConfig.showDenominations === "N") {
                        history.push("/close-till-all");
                      }
                    } else {
                      history.push("/");
                    }
                    // handleKeyvalue("8");
                  }}
                >
                  <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "5.5vh" }}>
                    <img src={Cashup} alt="Cash Up Details" style={{ height: "15vh" }} />
                  </div>
                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "1.2",
                      position: "relative",
                      fontWeight: "500",
                      margin: "9%",
                      boxSizing: "border-box",
                    }}
                  >
                    Close Till
                  </p>
                </div>
                <div
                  style={{
                    height: "28vh",
                    width: "35vw",
                    // backgroundColor: "#626367",
                    backgroundColor: themeJSON.productTableBGColor || "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "2%",
                    borderRadius: "10px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                  }}
                  onClick={async () => {
                    setSyncLoad(true);
                    localStorage.removeItem("lastUpdatedTime");
                    let tillData = JSON.parse(localStorage.getItem("tillData"));
                    await processSync(tillData, setGlobalStore, "Full Sync");
                    setSyncLoad(false);
                    // setKeyValue("0");
                    setGlobalStore({ percent: "0%" });
                    setGlobalStore({ productCategoryPercentage: "0" });
                    setGlobalStore({ productPercentage: "0" });
                    setGlobalStore({ promotionPercentage: "0" });
                  }}
                >
                  <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "5.5vh" }}>
                    <img src={Sync} alt="Data Sync" style={{ height: "15vh" }} />
                  </div>
                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "1.2",
                      position: "relative",
                      fontWeight: "500",
                      margin: "9%",
                      boxSizing: "border-box",
                    }}
                  >
                    Data Sync
                  </p>
                </div>
                {/* <div
                style={{
                  height: "43vh",
                  width: "22vw",
                  backgroundColor: "#626367",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "10px",
                  cursor: "pointer",
                  boxSizing: "border-box"
                }}
                // onClick={() => setShowLogin(true)}
               >
                <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={Settings} alt="Kiosk Settings" style={{ height: "15vh" }} />
                </div>
                <p
                  style={{
                    wordBreak: "break-word",
                    fontSize: "2vw",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "1.2",
                    position: "relative",
                    fontWeight: "500",
                    margin: "9%",
                    boxSizing: "border-box"
                  }}
                 >
                  Kiosk Settings
                </p>
              </div> 
              */}
              </div>
              <div
                className="left-bottom-images"
                onClick={() =>
                  //  setShowLogin(true)
                  setIsLocked(true)
                }
              >
                <div className="bottom-img">
                  <img src={backImg} style={{ height: "2.7vh" }} />
                  <p style={{ color: "#2F3856", margin: "0", fontSize: "2vh" }}>Back</p>
                </div>
                {/* <div className="bottom-img">
                  <img src={helpImg} style={{ height: "2.7vh" }} />
                  <p style={{ color: "#2F3856", margin: "0", fontSize: "2vh" }}>Hel</p>
                </div> */}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminComponent;
