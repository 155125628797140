import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
// import { useSingleInstance } from "./hooks/useSingleInstance";

const Main = () => {
  // const basePath = window.location.origin;
  // const { initializeTab } = useSingleInstance(basePath);

  // useEffect(() => {
  //   if (initializeTab()) {
  //     ReactDOM.render(
  //       <I18nextProvider i18n={i18n}>
  //         <App />
  //       </I18nextProvider>,
  //       document.getElementById("root")
  //     );
  //   }
  // }, [initializeTab]);

  useEffect(() => {
    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>,
      document.getElementById("root")
    );
  }, []);

  return null;
};

ReactDOM.render(<Main />, document.getElementById("root"));
