import React, { useState, useEffect, useRef } from "react";
import { Drawer, Table, Input, Button, Row, Col, Modal } from "antd";
import { WarningOutlined, CloseOutlined } from "@ant-design/icons";
import CancelArrow from "../../assets/images/cancelArrow.svg";

const BOMModals = (props) => {
  const {
    isStockModal,
    setIsStockModal,
    searchedProductsData,
    setSearchedProductsData,
    handleCancel,
    updateCartTotals,
    isWeightPopupVisible,
    setIsWeightPopupVisible,
    tempSelectProduct,
    savingSearchedProductsData,
    setQuantityInputValue,
  } = props;
  const [keyBoardInputValue, setKeyBoardInputValue] = useState(0);
  const [isFirstInput, setIsFirstInput] = useState(true);
  const [validationFlag, setValidationFlag] = useState(false);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};
  const inputRef = useRef(null);

  useEffect(() => {
    if (isWeightPopupVisible && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 0);
    }
  }, [isWeightPopupVisible]);

  useEffect(() => {
    if (tempSelectProduct && tempSelectProduct[0]?.weight !== undefined) {
      setKeyBoardInputValue(tempSelectProduct[0].weight);
    } else {
      setKeyBoardInputValue(0);
    }
  }, [tempSelectProduct]);

  const handleAddQuantity = (value) => {
    const amountInput = document.getElementById("sm-amount-input");
    if (amountInput) {
      amountInput?.focus();
    }
    setKeyBoardInputValue((prevQty) => {
      let newQty = prevQty.toString();
      if (value === "x") {
        setIsFirstInput(false);
        const newValue = newQty.slice(0, -1);
        return newValue === "" ? "" : newValue;
      }
      if (value === ".") {
        setIsFirstInput(false);
        if (tempSelectProduct && Array.isArray(tempSelectProduct[0]?.uomData) && tempSelectProduct[0].uomData[0] && tempSelectProduct[0].uomData[0].decimal === "Y") {
          if (newQty === "") {
            return "0.";
          }
          if (!newQty.includes(".")) {
            return newQty + ".";
          }
        }
        return newQty;
      }
      if (value === "clear") {
        setValidationFlag(false);
        setIsFirstInput(true);
        return "";
      }
      newQty = isFirstInput ? value : newQty + value;
      const decimalLimit = tempSelectProduct[0]?.uomData[0]?.stdprecision || 2;
      const [integerPart, decimalPart] = newQty.split(".");
      if (decimalPart && decimalPart.length > decimalLimit) {
        return prevQty;
      }
      if (parseFloat(newQty) + tempSelectProduct[0].weight > tempSelectProduct[0]?.stock) {
        setValidationFlag(true);
        return prevQty;
      }
      setValidationFlag(false);
      setIsFirstInput(false);
      return newQty;
    });
  };

  const handleEnter = () => {
    setIsFirstInput(true);
    setSearchedProductsData((prevData) => {
      prevData = prevData || [];
      if (isNaN(parseFloat(keyBoardInputValue)) || parseFloat(keyBoardInputValue) <= 0) {
        return prevData;
      }

      let inputQty = 1;
      setQuantityInputValue((prev) => (inputQty = prev));
      let index = prevData?.findIndex((item) => item.m_product_id === tempSelectProduct[0]?.m_product_id);
      let updatedData = [...prevData];
      if (index !== -1 && prevData.length > 0) {
        const newTotalWeight = parseFloat(prevData[index].weight || 0) + (keyBoardInputValue === "" ? 1 : parseFloat(keyBoardInputValue));
        if (newTotalWeight * inputQty <= prevData[index].stock) {
          updatedData[index] = {
            ...prevData[index],
            weight: parseFloat(newTotalWeight.toFixed(prevData[index].uomData[0]?.stdprecision)),
            totalWeight: parseFloat((newTotalWeight * inputQty).toFixed(prevData[index].uomData[0]?.stdprecision)),
            total: parseFloat((newTotalWeight * prevData[index].sale_price * inputQty).toFixed(precision)),
          };
          updatedData = updatedData.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          });
          savingSearchedProductsData(updatedData);
          updateCartTotals(updatedData);
          setIsWeightPopupVisible(false);
          setKeyBoardInputValue(0);
          setValidationFlag(false);
          setIsFirstInput(true);
          return updatedData;
        } else {
          setValidationFlag(true);
        }
      } else if (index === -1) {
        const newProduct = {
          ...tempSelectProduct[0],
          weight: parseFloat((keyBoardInputValue === "" ? 1 : parseFloat(keyBoardInputValue) || 0).toFixed(tempSelectProduct[0]?.uomData[0]?.stdprecision)),
          totalWeight: parseFloat((keyBoardInputValue === "" ? 1 : parseFloat(keyBoardInputValue * inputQty) || 0).toFixed(tempSelectProduct[0]?.uomData[0]?.stdprecision)),
          total: parseFloat((keyBoardInputValue === "" ? 1 : parseFloat(keyBoardInputValue) * tempSelectProduct[0]?.sale_price * inputQty || 0).toFixed(precision)),
        };
        if (keyBoardInputValue * inputQty > newProduct.stock) {
          setValidationFlag(true);
          return;
        }
        updatedData = [...prevData, newProduct];
        updatedData = updatedData.map((item, index) => {
          return {
            ...item,
            key: index,
          };
        });
        savingSearchedProductsData(updatedData);
        updateCartTotals(updatedData);
        setIsWeightPopupVisible(false);
        setKeyBoardInputValue(0);
        setValidationFlag(false);
        setIsFirstInput(true);
        return updatedData;
      }
      setValidationFlag(true);
      return prevData;
    });
  };

  return (
    <>
      <Drawer
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "7vh", fontSize: "1vw", flexDirection: "column", paddingBottom: "2vh" }}>
            <p style={{ fontSize: "1.5em", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center", margin: 0 }}>Stock Validation</p>
            <p style={{ fontWeight: 500, textAlign: "center", color: "red", fontSize: "0.8em", margin: "0" }}>
              <WarningOutlined style={{ color: "orange", fontSize: "1.4rem" }} /> Production cannot be processed. Insufficient stock for one or more components.
            </p>
          </div>
        }
        closeIcon={null}
        visible={isStockModal}
        className="filter"
        bodyStyle={{ padding: "0 4vw" }}
        height="80vh"
      >
        <Table
          className="sm-cart-table-pos-stock"
          style={{
            width: "100%",
            overflowY: "auto",
            border: "none",
            borderRadius: "7px",
            padding: "0px 7px",
            height: "54vh",
            background: themeJSON.productTableBGColor || "#fff",
          }}
          pagination={false}
          dataSource={searchedProductsData?.filter((product) => product.isBom === "Y") || []}
          sticky
          id="sm-cart-table"
          columns={[
            {
              title: "Item Name",
              dataIndex: "product_name",
              key: "product_name",
              width: "32%",
              render: (text, record) => {
                return (
                  <>
                    <div onClick={() => {}} id="sm-product-name" className="tableRow">
                      {record.product_name}
                    </div>
                    <div className="offerName">
                      {record.stock >= record.totalWeight ? (
                        <p style={{ margin: 0, color: "#009000" }}>Available</p>
                      ) : record.stock > 0 && record.totalWeight > 0 ? (
                        <p style={{ margin: 0, color: "#FF5E0E" }}>Only {record.stock - record.totalWeight} Available</p>
                      ) : (
                        <p style={{ margin: 0, color: "red" }}>Out Of Stock</p>
                      )}
                    </div>
                  </>
                );
              },
            },
            {
              title: "UOM",
              dataIndex: "bom_uomname",
              key: "bom_uomname",
              width: "12%",
              render: (text, record) => {
                return <div style={{ textAlign: "left" }}>{record?.uomData && Array.isArray(record?.uomData) && record?.uomData?.length > 0 ? record.uomData[0].name : ""}</div>;
              },
              align: "center",
            },
            {
              title: "Required Qty",
              dataIndex: "totalWeight",
              key: "totalWeight",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return <div style={{ textAlign: "right" }}>{record?.totalWeight?.toFixed(record?.uomData[0]?.stdprecision)}</div>;
              },
            },
            {
              title: "Stock Qty",
              dataIndex: "stock",
              key: "stock",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return (
                  <div style={{ textAlign: "right", color: record.stock - record.totalWeight > 0 ? "#0F0718" : "red" }}>
                    {(record?.stock ?? 0).toFixed(record?.uomData[0]?.stdprecision)}
                  </div>
                );
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "7vh",
              width: "10vw",
              borderRadius: "7px",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={() => {
              setIsStockModal(false);
              handleCancel();
            }}
          >
            OK
          </Button>
        </div>
      </Drawer>
      <Modal visible={isWeightPopupVisible} closable={false} keyboard={false} footer={null} width="30%" centered bodyStyle={{ padding: 10, backgroundColor: "#F3F4F9" }}>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <CloseOutlined
              onClick={() => {
                setIsFirstInput(true);
                setValidationFlag(false);
                setKeyBoardInputValue(0);
                setIsWeightPopupVisible(false);
              }}
              style={{ fontSize: "18px" }}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 3.5% 0 3.5%" }} gutter={[8, 6]}>
          <Col span={24} style={{ paddingBottom: "1vh" }}>
            Product: {tempSelectProduct[0]?.product_name}
          </Col>
          <Col span={24} style={{ paddingBottom: "1vh" }}>
            Stock Qty: {(tempSelectProduct[0]?.stock || 0)?.toFixed(tempSelectProduct[0]?.uomData[0]?.stdprecision)}
          </Col>
          <Col span={24} style={{ paddingBottom: "1vh" }}>
            Sale Price: {tempSelectProduct[0]?.sale_price.toFixed(precision)}
          </Col>
        </Row>
        <Row style={{ padding: "0 3.5% 0 3.5%" }} gutter={[8, 6]}>
          {}
          <Col span={24} style={{ paddingBottom: "2vh" }}>
            {validationFlag && (
              <p style={{ color: "#FF0000", fontWeight: "500", fontSize: "0.8em" }}>
                Insufficient stock: The entered Qty cannot exceed the Stock Qty of {tempSelectProduct[0]?.stock?.toFixed(tempSelectProduct[0]?.uomData[0]?.stdprecision) ?? 0}.
              </p>
            )}
            <Input
              className="productSearch sm-payment-search totalQtyInput"
              ref={inputRef}
              style={{
                borderRadius: "0.5vw",
                height: "8.3vh",
                borderRadius: "5px",
                textAlign: "right",
                fontWeight: "500",
                fontSize: "2.5em",
                borderColor: "#fff",
                color: "#2F3856",
                fontFamily: "Inter",
              }}
              value={keyBoardInputValue}
              id="sm-amount-input"
              onChange={(e) => {
                if (parseFloat(e.target.value.replace(/[^0-9.]/g, "")) + tempSelectProduct[0].totalWeight > tempSelectProduct[0]?.stock) {
                  setValidationFlag(true);
                } else {
                  setKeyBoardInputValue(e.target.value.replace(/[^0-9.]/g, ""));
                }
              }}
              placeholder="Enter Quantity"
              onPressEnter={(e) => {
                e.preventDefault();
                handleEnter();
              }}
            />
          </Col>
          <Col span={6}>
            <button id="sm-amount-button1" className="amt-dial-btn-production" onClick={() => handleAddQuantity("1")}>
              1
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button2" className="amt-dial-btn-production" onClick={() => handleAddQuantity("2")}>
              2
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button3" className="amt-dial-btn-production" onClick={() => handleAddQuantity("3")}>
              3
            </button>
          </Col>
          <Col span={6}>
            <button className="amt-dial-btn-production" id="sm-amount-buttonRemove" onClick={() => handleAddQuantity("x")}>
              <img src={CancelArrow} alt="back space" width={25} height={15} />
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button4" className="amt-dial-btn-production" onClick={() => handleAddQuantity("4")}>
              4
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button5" className="amt-dial-btn-production" onClick={() => handleAddQuantity("5")}>
              5
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button6" className="amt-dial-btn-production" onClick={() => handleAddQuantity("6")}>
              6
            </button>
          </Col>
          <Col span={6}>
            <button id="sm-amount-button." className="amt-dial-btn-production" onClick={() => handleAddQuantity("clear")}>
              Clear
            </button>
          </Col>
          <Col span={18}>
            <Row gutter={[8, 6]}>
              <Col span={8}>
                <button id="sm-amount-button7" className="amt-dial-btn-production" onClick={() => handleAddQuantity("7")}>
                  7
                </button>
              </Col>
              <Col span={8}>
                <button id="sm-amount-button8" className="amt-dial-btn-production" onClick={() => handleAddQuantity("8")}>
                  8
                </button>
              </Col>
              <Col span={8}>
                <button id="sm-amount-button0" className="amt-dial-btn-production" onClick={() => handleAddQuantity("9")}>
                  9
                </button>
              </Col>
              <Col span={8}>
                <button id="sm-amount-button0" className="amt-dial-btn-production" onClick={() => handleAddQuantity("0")}>
                  0
                </button>
              </Col>
              <Col span={8}>
                <button id="sm-amount-button." className="amt-dial-btn-production" onClick={() => handleAddQuantity("00")}>
                  00
                </button>
              </Col>
              <Col span={8}>
                <button id="sm-amount-button00" className="amt-dial-btn-production" onClick={() => handleAddQuantity(".")}>
                  .
                </button>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <button id="sm-amount-buttonEnter" className="amt-dial-btn-production-ent" onClick={handleEnter}>
              Enter
            </button>
          </Col>
        </Row>

        <Row style={{ padding: "3% 3.5% 0 3.5%" }} gutter={[8, 6]}>
          <Col span={12}>
            <Button
              onClick={() => {
                setKeyBoardInputValue(0);
                setValidationFlag(false);
                setIsWeightPopupVisible(false);
              }}
              style={{ width: "100%", height: "7vh", borderRadius: "5px", backgroundColor: "#fff", color: "#2F3856" }}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={handleEnter} style={{ width: "100%", height: "7vh", borderRadius: "5px", backgroundColor: "#2F3856", color: "#fff" }}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BOMModals;
