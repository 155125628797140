import { io } from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
let socket = null;

if (SOCKET_SERVER_URL) {
  socket = io(SOCKET_SERVER_URL, {
    transports: ["websocket"],
    path: "/socket.io",
    withCredentials: true,
  });

  socket.on("connect", () => {
    console.log("✅ Connected to WebSocket Server:", socket.id);
  });

  socket.on("order_update", (orderData) => {
    console.log("📦 New Order Update:", orderData);
    // alert(`New Order Received: ${orderData.orderNo}`);
  });

  socket.on("connect_error", (err) => {
    console.error("❌ WebSocket Connection Error:", err.message);
  });
} else {
  console.warn("⚠️ WebSocket connection is disabled as no SOCKET_SERVER_URL is provided.");
}

export default socket;
