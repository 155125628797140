import React, { Fragment, useContext, useState, useEffect, useRef, createContext, Component } from "react";
import { Row, Col, Card, Button, Input, Modal, Form, Select, Tabs, Divider, Spin, Table, message } from "antd";
import { SearchOutlined, ArrowLeftOutlined, CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
// import { serverUrl } from "../../../constants/config";
import { Scrollbars } from "react-custom-scrollbars";
import DropdownCircle from "../../../assets/images/DropdownCircle.png";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";
import Axios from "axios";
import db from "../../../database";
import { useTranslation } from "react-i18next";
import { customerSearch, verifyReturn } from "../../../services";

const EditableContext = createContext(null);

const { Option } = Select;
const TabPane = Tabs.TabPane;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, setModalEditState, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    setModalEditState(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const checkManualQty = (value) => {
    if (!Number.isInteger(value)) {
      if (record.product.isManualQty) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.qtyToReturn = parseFloat(values.qtyToReturn);
      let returnTotal = record.qty - record.returnQty;
      if (values.qtyToReturn > returnTotal) {
        {
          message.warning(`${t("sales_return_error")}`);
        }
        throw new Error("Qty is more than ordered, invalid value");
      } else if (checkManualQty(values.qtyToReturn)) {
        message.warning("Return Qty can't be a Decimal Value !");
        throw new Error("Qty is not a valid type, invalid value");
      } else {
        toggleEdit();
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class ReturnTable extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Description",
        dataIndex: "product",
        width: "30%",
        render: (_, record) => {
          return record.product.name;
        },
      },
      {
        title: "Unit Price",
        dataIndex: "grossstd",
        render: (_, record) => {
          return parseFloat(record.grossstd).toFixed(2);
        },
      },
      {
        title: "Qty Ordered",
        dataIndex: "qty",
      },
      {
        title: "Qty Returned",
        dataIndex: "returnQty",
      },
      {
        title: "Qty to Return",
        dataIndex: "qtyToReturn",
      },
    ];

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    const { dataSource } = this.props;
    dataSource.forEach((_, i) => {
      dataSource[i].key = `${i}`;
      dataSource[i].qtyToReturn = dataSource[i].qtyToReturn ? dataSource[i].qtyToReturn : 0;
    });
    dataSource.itemsToReturn = 0;
    dataSource.itemsQtyToReturn = 0;
    dataSource.returnAmountTotal = 0;
    this.setState({
      dataSource: [...dataSource],
    });
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
    this.props.handleSaveReturnCartRecord(newData);
    setTimeout(() => {
      this.props.returnProcessRef.current.focus();
    }, 750);
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.dataIndex === "qtyToReturn" && record.product.returnable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setModalEditState: this.props.setModalEditState,
        }),
      };
    });
    return <Table scroll={{ y: 300 }} components={components} pagination={false} rowClassName={() => "editable-row"} bordered dataSource={dataSource} columns={columns} />;
  }
}

const ReturnBill = (props) => {
  const { t } = useTranslation();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const { addProduct, setCart, cart, posLogActivity, displayReturnOrderSearch, setDisplayReturnOrderSearch } = props;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const accessToken = JSON.parse(localStorage.getItem("tokens"));
  const cleanToken = accessToken?.access_token.replace(/['"]+/g, "");

  // const [displayReturnOrderSearch, setDisplayReturnOrderSearch] = useState(false);
  const [returnSearchInput, setReturnSearchInput] = useState("");
  const [retrunSearchType, setReturnSearchType] = useState("orderNo");
  const [returnSearchHistory, setReturnSearchHistory] = useState([]);
  const [returnSelectedDetailOrderId, setReturnSelectedDetailOrderId] = useState("");
  const [loader, setLoader] = useState(false);

  const searchReturnHistory = async () => {
    const searchQuery = `${retrunSearchType} : "${returnSearchInput}"`;
    try {
      setLoader(true);
      const data= {
        query: `query {
                  verifyReturn(${searchQuery}) {
                      sOrderID
                      created
                      createdby
                      updated
                      updatedby
                      documentno
                      dateordered
                      totalQty
                      orderTime
                      cwrProductQty
                      taxamt
                      grosstotal
                      discAmount
                      csBUnit {
                          csBunitId
                          name
                      }
                      cwrB2cCustomer {
                          cwrCustomerId
                          code
                          name
                          mobileNo
                          pincode
                          email
                          retlLoyaltyBalance
                          sCustomer {
                              sCustomerID
                              customerCategory {
                                  sCustomerCateforyId
                                  value
                                  name
                                  description
                              }
                          }
                      }
                      saleType {
                          cwrSaletypeId
                          name
                          value
                      }
                      cwrTill {
                          cwrTillID
                          till
                      }
                      finReceiptPlan {
                          finReceiptPlanDetails {
                              amount
                              cwrPaymentmethod {
                                  cWRPaymentMethodID
                                  finFinancialAccountId
                                  finPaymentmethodId
                                  integratedPayment
                                  isloyalty
                                  paymentProvider
                              }
                          }
                      }
                      line {
                          sOrderlineID
                          sOrderId
                          line
                          description
                          qty
                          netlist
                          netunit
                          linetax
                          unittax
                          linenet
                          linegross
                          grosslist
                          grossstd
                          returnline
                          returnQty
                          discount
                          product {
                              mProductId
                              name
                              value
                              upc
                              hsncode
                              imageurl
                              isManualQty
                              shortDescription
                              returnable
                              returnDays
                          }
                          uom {
                              csUomId
                              name
                          }
                          tax {
                              csTaxID
                              name
                              rate
                          }
                          pricingRule {
                              mPricingrulesId
                              name
                          }
                      }
                  }
              }`,
      }
      const searchData = await verifyReturn(data);
      setReturnSearchHistory([...searchData.data.data.verifyReturn]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const [displayReturnQtyUpdateModal, setDisplayReturnQtyUpdateModal] = useState(false);
  const [selectedReturnOrder, setSelectedReturnOrder] = useState({});
  const [modalEditState, setModalEditState] = useState(false);

  const selectPurchaseBillForReturn = (returnBillItem) => {
    setDisplayReturnOrderSearch(false);
    setReturnSearchInput("");
    setReturnSearchType("orderNo");
    setReturnSearchHistory([]);
    setReturnSelectedDetailOrderId("");
    setDisplayReturnQtyUpdateModal(true);

    const discountValue = returnBillItem.discAmount;
    let grossstdTotal = 0;
    for (let i = 0; i < returnBillItem.line.length; i += 1) {
      grossstdTotal += parseFloat(returnBillItem.line[i].grossstd);
    }
    const zeroDiscountCount = returnBillItem.line.filter((item) => item.discount === 0).length;

    if (zeroDiscountCount === returnBillItem.line.length && discountValue > 0) {
      returnBillItem.line.forEach((addedToCart, index) => {
        const discountAmt = parseFloat((addedToCart.grossstd / grossstdTotal) * discountValue) / addedToCart.qty;
        const sp = parseFloat(addedToCart.grossstd) - discountAmt;
        addedToCart.grossstd = sp;
        returnBillItem.line[index] = addedToCart;
      });
    }
    setSelectedReturnOrder({ ...returnBillItem });
  };

  const handleSaveReturnCartRecord = (data) => {
    let itemsToReturn = 0;
    let itemsQtyToReturn = 0;
    let returnAmountTotal = 0;

    data.forEach((item, i) => {
      if (item.qtyToReturn > 0) {
        itemsToReturn += 1;
        itemsQtyToReturn += item.qtyToReturn;
        returnAmountTotal += item.qtyToReturn * item.grossstd;
      }
    });

    selectedReturnOrder.line = data;
    selectedReturnOrder.itemsToReturn = itemsToReturn;
    selectedReturnOrder.itemsQtyToReturn = itemsQtyToReturn;
    selectedReturnOrder.returnAmountTotal = returnAmountTotal;
    setSelectedReturnOrder({ ...selectedReturnOrder });
  };

  const addSelectedReturnProductsToCart = async () => {
    setLoader(true);
    const customerResult = await getCustomerData(selectedReturnOrder.cwrB2cCustomer.mobileNo);
    if (customerResult) {
      setCart({
        ...cart,
        customer: customerResult,
      });
    }
    for (let index = 0; index < selectedReturnOrder.line.length; index++) {
      const item = { ...selectedReturnOrder.line[index] };
      const productItem = await getProductData(item.product.mProductId);
      if (productItem) {
        productItem.realPrice = item.grossstd;
        productItem.salePrice = item.grossstd;
        productItem.mrpPrice = item.grossstd;
        productItem.sOrderReturnId = item.sOrderId;
        productItem.sOrderlineReturnId = item.sOrderlineID;
        addProduct(productItem, -item.qtyToReturn);
        if (
          db.logConfiguration.toArray().then((fetched) => {
            fetched.map((item) => {
              item.salesReturn.filter((item) => item.log === "Y").length > 0;
            });
          })
        ) {
          posLogActivity(productItem, "SLR");
        }
        await new Promise((r) => setTimeout(r, 900));
      }
    }
    setLoader(false);
    setDisplayReturnQtyUpdateModal(false);
  };

  const getCustomerData = (mobileNo) => {
    return new Promise(async function (resolve) {
      const data = {
        query: `query{
          customerSearch(mobileNo:"${mobileNo}"){
            cwrCustomerId 
            code   
            name
            email        
            mobileNo
            name
            pincode
            retlLoyaltyBalance
            b2cRegisteredstoreId
             iscredit
            balancepoints
            loyaltyLevel{
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
            }
            sCustomer{
              sCustomerID
              customerCategory{
                sCustomerCateforyId
                value
                name
                description
              }
            }
            csBunit{
              csBunitId
              name
            }
            b2cCustomerSegment{
              name
              cwrB2CCustomerSegmentId
            }
          }
        }`,
      }
   const response = await customerSearch(data);
          if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
            const result = response.data.data.customerSearch;
            resolve(result[0]);
          } else {
            console.warn("No customer found, try searching with different customer");
            resolve(null);
          }
       
    });
  };

  const getProductData = (data) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: 0,
              discountName: "",
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              name2: obj.name2,
              nettotal: 0,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice,
              listPrice: obj.slistprice,
              sunitprice: obj.sunitprice,
              returnQty: null,
              salePrice: obj.sunitprice,
              mrpPrice: obj.sunitprice,
              stock: obj.onhandQty,
              tax: obj.cTaxId,
              taxCategory: obj.taxCategory,
              taxName: obj.taxName,
              taxAmount: 0,
              taxRate: obj.taxRate,
              uom: obj.csUomId,
              uom_name: obj.uomName,
              isDecimalQty: obj.uomData[0]?.length > 0 ? (obj.uomData[0].decimal === "Y" ? true : false) : false,
              isQtyDesimal: obj.uomData[0]?.length > 0 ? obj.uomData[0].stdprecision : 2,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
            };
            resolve(productDefined);
          } else {
            {
              message.warning(`${t("product_search_error")}`);
            }
            resolve(null);
          }
        });
    });
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey && keyCode === 82) {
      if (posConfig.showSalesReturn === "Y") {
        setDisplayReturnOrderSearch(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const returnBillSearchInputRef = useRef();
  useEffect(() => {
    if (displayReturnOrderSearch) {
      setTimeout(() => {
        returnBillSearchInputRef.current.focus();
      }, 100);
    }
  }, [retrunSearchType, displayReturnOrderSearch]);

  const returnProcessRef = useRef();

  return (
    <Fragment>
      {/* VERIFY RETURN SEARCH MODAL START */}
      <Modal keyboard={false} visible={displayReturnOrderSearch} closable={false} footer={null} bodyStyle={{ padding: "20px 15px 15px" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loader}>
          <Row gutter={[0, 8]}>
            <Col span={8}>
              <ArrowLeftOutlined
                style={data.posScreenStyles.orderHistoryModal.backArrowIcon}
                onClick={() => {
                  setDisplayReturnOrderSearch(false);
                  setReturnSearchInput("");
                  setReturnSearchType("orderNo");
                  setReturnSearchHistory([]);
                  setReturnSelectedDetailOrderId("");
                }}
              />
            </Col>
            <Col span={16}>
              <p style={{ ...data.posScreenStyles.orderHistoryModal.orderHistoryModalTitle, fontSize: "1vw" }}>Select Retun Purchase</p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={10}>
              <Select
                defaultValue="orderNo"
                suffixIcon={<CaretDownOutlined />}
                placeholder="Document"
                style={data.posScreenStyles.orderHistoryModal.orderHistoryAttribute}
                size="large"
                onChange={setReturnSearchType}
              >
                <Option value="orderNo">Document</Option>
                <Option value="mobileNo">Mobile</Option>
                {/* <Option value="dateOrdered">Date</Option> */}
              </Select>
            </Col>
            <Col span={14}>
              <Input
                ref={returnBillSearchInputRef}
                placeholder="Search"
                size="large"
                allowClear={false}
                style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
                onPressEnter={searchReturnHistory}
                onChange={(e) => {
                  setReturnSearchInput(e.target.value);
                }}
                value={returnSearchInput}
                suffix={<SearchOutlined onClick={searchReturnHistory} style={data.posScreenStyles.customerModal.searchIcon} />}
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Tabs defaultActiveKey="1" centered style={data.posScreenStyles.orderHistoryModal.orderHistoryTabs}>
              <TabPane key="1">
                <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                  <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                    <Scrollbars className="orderHistoryScroll">
                      {returnSearchHistory.map((item) => (
                        <Card
                          tabIndex={0}
                          onKeyPress={(e) => (e.charCode === 13 ? selectPurchaseBillForReturn(item) : null)}
                          key={item.documentno}
                          style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                          bodyStyle={{ padding: 10 }}
                        >
                          <Row>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                            </Col>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.cwrB2cCustomer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img
                                style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                                src={DropdownCircle}
                                alt=""
                                width={15}
                                height={15}
                                onClick={() => (returnSelectedDetailOrderId ? setReturnSelectedDetailOrderId("") : setReturnSelectedDetailOrderId(item.sOrderID))}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>
                                {"Total Qty : "}
                                {item.totalQty}
                              </p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === returnSelectedDetailOrderId ? (
                            <Row>
                              <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                                <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                  <th>Description</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                                </tr>
                                {item.line.map((subItem, index) => (
                                  <tr key={`${subItem.product.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                    <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.product.name}</td>
                                    <td>{subItem.qty}</td>
                                    <td>{parseFloat(subItem.grossstd).toFixed(2)}</td>
                                    <td>{parseFloat(subItem.linenet).toFixed(2)}</td>
                                  </tr>
                                ))}
                              </table>
                              <Divider />
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.taxamt).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.grosstotal).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                                <Col span={24}>
                                  <Button size="large" onClick={() => selectPurchaseBillForReturn(item)} style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                    Return
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ))}
                    </Scrollbars>
                  </Card>
                </Row>
              </TabPane>
            </Tabs>
          </Row>
        </Spin>
      </Modal>
      {/* VERIFY RETURN SEARCH MODAL END */}

      {/* RETURN TABLE MODAL START */}
      <Modal
        visible={displayReturnQtyUpdateModal}
        closable={false}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setDisplayReturnQtyUpdateModal(false)}
        width={"80%"}
        style={{ /* position: "relative", */ top: "50px" /* transform: "translateY(-50%)" */ }}
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <h2 style={{ textAlign: "center", color: "rgb(12, 77, 169)", fontWeight: "600" }}>Return Purchase</h2>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loader}>
          <ReturnTable
            dataSource={selectedReturnOrder.line}
            handleSaveReturnCartRecord={handleSaveReturnCartRecord}
            setModalEditState={setModalEditState}
            returnProcessRef={returnProcessRef}
          />
          <Card style={{ borderTop: "1px solid #ebeded" }} bodyStyle={{ padding: 10 }}>
            <Row>
              <Col span={18}></Col>
              <Col span={6}>
                <table width={"100%"}>
                  <tr>
                    <td>Return Items / Qty :</td>
                    <td style={{ textAlign: "right" }}>
                      {selectedReturnOrder.itemsToReturn ? selectedReturnOrder.itemsToReturn : "0"} /{" "}
                      {selectedReturnOrder.itemsQtyToReturn ? parseFloat(selectedReturnOrder.itemsQtyToReturn).toFixed(2) : "0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Return Amount Total :</td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>
                      {selectedReturnOrder.returnAmountTotal ? parseFloat(selectedReturnOrder.returnAmountTotal).toFixed(2) : "0.00"}
                    </td>
                  </tr>
                </table>
              </Col>
            </Row>
          </Card>
          <Row style={{ marginTop: 20 }}>
            <Col span={20} />
            <Col span={2} style={{ textAlign: "right" }}>
              <Button onClick={() => setDisplayReturnQtyUpdateModal(false)} disabled={loader}>
                Cancel
              </Button>
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              <Button ref={returnProcessRef} type="primary" onClick={(e) => addSelectedReturnProductsToCart(e)} disabled={modalEditState || loader} className="focusDashboardCard">
                Return
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
      {/* RETURN TABLE MODAL END */}
    </Fragment>
  );
};

export default ReturnBill;
