import React from "react";
import { Row, Col, Input, Table, DatePicker, Drawer, Button, Popover } from "antd";
import { CalendarOutlined, DownOutlined, RightOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Back from "../../../assets/images/backIcon.svg";
import CustomerSearch from "../../../assets/images/c-serach.svg";
import Logout from "../../../assets/images/logout-new.svg";
import Retrieve from "../../../assets/images/retrieve.png";

import printerIcon from "../../../assets/images/printerIcon.svg";
import clearFilters from "../../../assets/images/clearFilters.svg";

import unlinkTill from "../../../assets/images/unlink-icon.svg";
import POSLogo from "../../../assets/images/NewLogoCW.svg";
import Close from "../../../assets/images/close-x.svg";
import Keyboard from "react-simple-keyboard";
import { Scrollbars } from "react-custom-scrollbars";
import TextArea from "antd/lib/input/TextArea";

const InventoryHistorySupermarket = (props) => {
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const {
    showHistory,
    setShowHistory,
    tillData,
    tillUnlink,
    tillLink,
    domainURL,
    setTillLink,
    showComments,
    setShowComments,
    setTempComments,
    comments,
    setComments,
    textAreaRef,
    tempComments,
    keyboardRef,
    handleShift,
    setLayoutName,
    layoutName,
    showTargetLocation,
    setShowTargetLocation,
    warehouseData,
    setSelectedWarehouse,
    setInventoryOrder,
    inventoryHistoryData,
    seachHistoryData,
    setSearchHistoryData,
  } = props;

  const handleExpand = () => {
    console.log("expand");
  };

  const orderHistoryColumns = [
    {
      title: "Document No:",
      dataIndex: "documentno",
      key: "documentno",
      width: "12%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "12%",
    },
    {
      title: "From Warehouse",
      dataIndex: "issuewarehouse",
      key: "issuewarehouse",
      width: "23%",
    },
    {
      title: "To Warehouse",
      dataIndex: "receiptwarehouse",
      key: "receiptwarehouse",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "27%",
    },
    {
      title: "Type",
      dataIndex: "docstatus",
      key: "docstatus",
      width: "20%",
    },
  ];


  const filteredData = seachHistoryData
    ? inventoryHistoryData.filter((item) => {
        const query = seachHistoryData.toLowerCase();
        return item.documentno?.toLowerCase().includes(query) || item.date?.toLowerCase().includes(query);
      })
    : inventoryHistoryData;

  return (
    <div>
      {showHistory && (
        <>
          <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
            <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
              <Col span={8}>
                <img
                  src={Back}
                  id="sm-salesHistory-back"
                  style={{
                    height: "3vw",
                    paddingBottom: "0.7em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setSearchHistoryData("");
                  }}
                />
                <span
                  style={{
                    paddingRight: "1vw",
                    color: "#0F0718",
                    fontSize: "1.8em",
                    fontWeight: 500,
                    marginLeft: "0.5em",
                  }}
                >
                  Inventory History
                </span>
              </Col>
              <Col
                span={8}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search for Document Number/UPC/Product"
                  prefix={<img src={CustomerSearch} style={{ width: "1.4em" }} alt="" />}
                  id="sm-salesHistory-customer"
                  className="salesHistory-input-sm"
                  value={seachHistoryData}
                  onChange={(e) => setSearchHistoryData(e.target.value)}
                />
              </Col>
              <Col
                span={8}
                style={{
                  paddingRight: "",
                  paddingLeft: "1.5vw",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Popover
                  //   content={content}
                  trigger="click"
                  //   visible={isPopoverVisible}
                  // onVisibleChange={(visible) => setPopoverVisible(visible)}
                  //   onVisibleChange={(visible) => {
                  //     setPopoverVisible(visible);
                  //     if (visible) {
                  //       setTimeout(() => {
                  //         const datePickerElement = document.querySelector('.salesHistory-datePicker-sm');
                  //         const inputs = datePickerElement?.querySelectorAll('.ant-picker-input input');
                  //         if (inputs && inputs.length > 0) {
                  //           inputs[0].focus();
                  //         }
                  //       }, 100);
                  //     }
                  //   }}
                >
                  <Button
                    icon={<CalendarOutlined style={{ paddingLeft: "0.5rem" }} />}
                    className="popover-trigger-button"
                    style={{
                      border: "none",
                      width: "27vw",
                      display: "flex",
                      alignItems: "center",
                      color: "#929098",
                      fontWeight: 400,
                      fontSize: "1em",
                      fontFamily: "Inter",
                    }}
                  >
                    {/* {getDisplayDate()} */}
                  </Button>
                </Popover>
                {/* {filtersFlag ? (
                  <Button
                    tabIndex={3}
                    onClick={() => {
                      setOrderHistoryDetails([]);
                      setFiltersFlag(false);
                      setDateRange([]);
                      setSearchHistoryInput("");
                      setDateRange(null);
                    }}
                    style={{ border: "none", padding: "4px 10px" }}
                    onFocus={(e) => {
                      e.target.style.outline = "1px solid #40a9ff";
                      e.target.style.boxShadow = "0 0 5px #40a9ff"
                    }}
                    onBlur={(e) => {
                      e.target.style.outline = "none";
                    }}
                  >
                    <img src={clearFilters} alt="clear" />
                  </Button>
                ) : null} */}
              </Col>
            </Row>
            <br />
            <Row style={{ padding: "0 1.5em" }}>
              <Table
                className="sm-cart-table"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  fontSize: "1.5vw",
                  borderRadius: "7px",
                  height: "84vh",
                  padding: "0 1em",
                  background: "#fff",
                  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                }}
                pagination={false}
                columns={orderHistoryColumns}
                // dataSource={inventoryHistoryData}
                dataSource={filteredData}
                // dataSource={seachHistoryData ? filteredData : inventoryHistoryData}
                onExpand={handleExpand}
                expandable={{
                  // expandedRowKeys,
                  onExpand: handleExpand,
                  expandedRowRender: (record) => (
                    <div
                      style={{
                        padding: "1% 4% 1% 4%",
                        backgroundColor: "#f3f4f9",
                      }}
                    >
                      <Row>
                        <Col span={21}>
                          {record?.lines && record?.lines?.length > 0 ? (
                            <>
                              <Row>
                                <Col span={12} className="td-nested-title-sm">
                                  Component
                                </Col>
                                <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                  Qty Requested
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                  Qty Issued
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
                                  Qty Confirmed
                                </Col>
                              </Row>
                              <Row style={{ paddingBottom: "0.5vw" }}>
                                {record?.lines?.map((e) => {
                                  const stdPrecision = Array.isArray(e?.uomData) && e?.uomData[0]?.stdprecision ? e.uomData[0].stdprecision : 0;
                                  return (
                                    <>
                                      <Col span={12} className="td-nested-description">
                                        {e.product}
                                      </Col>
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {/* {e.actualqty.toFixed(e?.uomData[0]?.stdprecision) || 0} */}
                                        {e.qtyissued.toFixed(stdPrecision) || 0}
                                      </Col>
                                      <Col
                                        span={1}
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "400",
                                        }}
                                      />
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {e?.qtyrequested?.toFixed(2) || 0}
                                      </Col>
                                      <Col span={2} style={{ textAlign: "center" }} />
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {e?.qtyconfirmed?.toFixed(2) || 0}
                                      </Col>
                                    </>
                                  );
                                })}
                              </Row>
                              {/* <p
                                style={{
                                  width: "96%",
                                  marginLeft: "0%",
                                  borderTop: "1.3px solid rgba(52, 40, 44, 0.55)",
                                  marginBottom: "0px",
                                }}
                              />
                              <Row style={{ marginBottom: "1%" }}>
                                <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                                  <span className="td-total">SUB TOTAL</span>
                                </Col>
                                <Col
                                  span={3}
                                  style={{
                                    fontSize: "1em",
                                    borderBottom: "1px solid rgba(52, 40, 44, 0.55)",
                                    textAlign: "right",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <span className="td-total">
                                    {record.lines && record.lines.length > 0 ? record.lines.reduce((sum, item) => sum + parseFloat(item.totalPrice || 0), 0).toFixed(2) : "0.00"}
                                  </span>
                                </Col>
                              </Row> */}
                            </>
                          ) : null}
                        </Col>
                        <Col
                          span={3}
                          className="td-bottons"
                          style={{
                            fontSize: "1vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {record?.lines && record?.reverse === "N" && record?.lines?.length > 0 && (
                            <p
                              id="sm-salesHistory-printer"
                              style={{
                                cursor: "pointer",
                                padding: "5.5% 12%",
                                borderRadius: "5px",
                                border: "1px solid #fff",
                                backgroundColor: "#fff",
                                fontSize: "1em",
                                fontWeight: 500,
                                color: "#0F0718",
                                width: "7rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={async () => {}}
                            >
                              <img
                                src={Retrieve}
                                style={{
                                  height: "1vw",
                                  paddingRight: "0.4vw",
                                  cursor: "pointer",
                                }}
                              />
                              Reverse
                            </p>
                          )}
                          <p
                            id="sm-salesHistory-printer"
                            style={{
                              cursor: "pointer",
                              padding: "5.5% 12%",
                              borderRadius: "5px",
                              border: "1px solid #fff",
                              backgroundColor: "#fff",
                              fontSize: "1em",
                              fontWeight: 500,
                              color: "#0F0718",
                              width: "7rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            // onClick={() => print(record)}
                          >
                            <img
                              src={printerIcon}
                              style={{
                                height: "1vw",
                                paddingRight: "0.4vw",
                                cursor: "pointer",
                              }}
                            />
                            Print
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ),
                  rowExpandable: (record) => record.name !== "Not Expandable",
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <span id="sm-salesHistory-expand" onClick={(e) => onExpand(record, e)}>
                      {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                    </span>
                  ),
                }}
              />
            </Row>
          </div>
        </>
      )}
      <Drawer
        placement="bottom"
        visible={showComments}
        height="53vh"
        maskClosable={false}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              Add Your Comments
            </p>
            <img
              src={Close}
              onClick={() => {
                setShowComments(false);
                // setTempRemarks(remarks);
                setTempComments(comments);
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingTop: 0 }}
        closable={true}
        onClose={() => {
          setShowComments(false);
        }}
        footer={null}
      >
        <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
          <Col span={11}>
            <TextArea
              id="sm-notes-input"
              style={{ resize: "none" }}
              ref={textAreaRef}
              maxLength={200}
              rows={11}
              value={tempComments}
              onPressEnter={(e) => {
                e.preventDefault();
                // savingRemarks(tempRemarks);
                setComments(tempComments);
                setShowComments(false);
              }}
              onChange={(e) => setTempComments(e.target.value)}
            />
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "0.8em",
                color: "#929098",
                fontWeight: 500,
              }}
            >
              Max Characters : 200
            </span>
          </Col>
          <Col span={12} offset={1}>
            <Keyboard
              theme={"hg-theme-default aprvl"}
              keyboardRef={(r) => (keyboardRef.current = r)}
              onKeyPress={(button) => {
                const amountInput = document.getElementById("sm-notes-input");
                if (amountInput) {
                  amountInput?.focus();
                }
                if (button === "{shift}" || button === "{lock}") {
                  handleShift();
                  return;
                }
                if (button === "{number}") {
                  setLayoutName("number");
                  return;
                }
                if (button === "{numbers}") {
                  setLayoutName("numbers");
                  return;
                }
                if (button === "{small}") {
                  setLayoutName("shift");
                  return;
                }
                if (button === "{caps}") {
                  setLayoutName("default");
                  return;
                }
                if (button === "{enter}") {
                  //   savingRemarks(tempRemarks);
                  setComments(tempComments);
                  setShowComments(false);
                  return;
                }
                if (button === "{space}") {
                  setTempComments((prev) => prev + " ");
                } else if (button === "{backspace}") {
                  setTempComments((prev) => prev.slice(0, -1));
                } else {
                  setTempComments((prev) => prev + button.replace(/[{}]/g, ""));
                }
              }}
              layoutName={layoutName}
              display={{
                "{abc}": "ABC",
                "{smileys}": "\uD83D\uDE03",
                "{back}": "Enter",
                "{numbers}": "123",
                "{number}": "123",
                "{bksp}": "backspace",
                "{backspace}": "⌫",
                "{shift}": "⇧",
                "{tab}": "tab",
                "{lock}": "lock",
                "{enter}": "Enter",
                "{space}": "Space",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
              }}
            />
          </Col>
        </Row>
      </Drawer>

      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2.5vh",
              marginTop: "2vh",
              fontSize: "1vw",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "4vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
                paddingRight: "5vw",
              }}
            >
              Select A Target Location
            </p>
            <img
              src={Close}
              onClick={() => {
                setShowTargetLocation(false);
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        visible={showTargetLocation}
        className="filter"
        closeIcon={null}
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        height="45vh"
        onClose={() => {
          setShowTargetLocation(false);
        }}
      >
        <Scrollbars style={{ minHeight: "15vh" }}>
          <Row
            gutter={16}
            style={{
              borderRadius: "10px",
              padding: "0 1.5vw",
              fontSize: "1vw",
            }}
          >
            {warehouseData.map((warehouse, index) => (
              <Col
                key={warehouse.m_warehouse_id || index}
                span={6}
                style={{
                  marginTop: "1.5vh",
                  cursor: "pointer",
                  padding: "0 1vw",
                }}
                onClick={() => {
                  setInventoryOrder((prevOrder) => {
                    return { ...prevOrder, toWarehouseId: warehouse.m_warehouse_id };
                  });
                  setSelectedWarehouse(warehouse);
                  setShowTargetLocation(false);
                }}
              >
                <div
                  className="productName"
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "1.5vh",
                    height: "14vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "1.25em",
                      color: "#0F0718",
                      fontFamily: "Inter",
                    }}
                  >
                    {warehouse.name}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </Scrollbars>
      </Drawer>

      <Drawer
        placement="left"
        width="20%"
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={POSLogo} style={{ height: "2vw" }} />
            {/* <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Add Discount</p> */}
            <img
              src={Close}
              onClick={() => setTillLink(false)}
              // style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }}
              style={{
                marginLeft: "auto",
                // paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        bodyStyle={{ paddingTop: 0 }}
        visible={tillLink}
        onClose={() => setTillLink(false)}
      >
        <Row gutter={[16, 16]} style={{ fontSize: "1vw" }}>
          <Col span={24}>
            <Scrollbars style={{ height: "80vh" }}>
              <Row>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
                <Col
                  onClick={tillUnlink}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                    marginBottom: "2vh",
                  }}
                  span={22}
                >
                  <img src={unlinkTill} style={{ height: "2.5vh" }} alt="" />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                      color: "#0F0718",
                    }}
                  >
                    Unlink Till
                  </span>
                </Col>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
              </Row>
            </Scrollbars>
          </Col>
          <Col
            span={24}
            style={{
              width: "80%",
              borderTop: "1px solid #929098",
              position: "absolute",
              bottom: "2.5vh",
              padding: "1vh 1vw",
            }}
          >
            <Row>
              <Col span={1} />

              <Col
                style={{ cursor: "pointer", paddingTop: "1vh" }}
                onClick={async () => {
                  // if (posConfigData?.posType === "PRD") {
                  const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
                  for (let i = 0; i < cookiesToDelete.length; i++) {
                    const cookie = cookiesToDelete[i];
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    const domain = "domain=" + domainURL;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                  }
                  localStorage.removeItem("tillSession");
                  window.location.assign("/");
                }}
                span={20}
              >
                <img src={Logout} alt="" />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    color: "#0F0718",
                  }}
                >
                  Logout
                </span>
              </Col>
              <Col span={24} style={{ position: "absolute", bottom: "5vh", right: "0" }}>
                <p style={{ color: "#0f071840", textAlign: "right", paddingRight: "1vw", fontSize: "0.6rem", fontWeight: 400 }}>v24Q3.4.0</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default InventoryHistorySupermarket;
