import React from "react";
import { Row, Col, Progress } from "antd";

const LoadingScreen = ({ POSLogo, formattedDateTime, clock1, leftLoading, percent, productCategoryPercentage, productPercentage, promotionPercentage }) => {
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  return (
    <div
      style={{
        backgroundColor: colorVariable || "#f3f4f9",
        height: "100vh",
        width: "100%",
      }}
    >
      <Row style={{ height: "9vh", padding: "0.5% 0" }}>
        <Col span={6}>
          <img src={POSLogo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} alt="POS Logo" />
        </Col>
        <Col
          span={18}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            paddingRight: "1vw",
            fontSize: "1vw",
          }}
        >
          <div style={{ fontWeight: 400, fontSize: "1.2em", color: "#0F0718" }}>{formattedDateTime}</div>
          <div style={{ fontWeight: 400, fontSize: "1.2em", color: "#0F0718" }}>{clock1}</div>
        </Col>
      </Row>
      <div style={{ display: "flex", height: "90vh" }}>
        <div
          style={{
            width: "48%",
            background: "#fff",
            height: "83vh",
            margin: "1.5%",
            marginRight: 0,
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1vw" }}>
            <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
              <img src={leftLoading} alt="Loading" style={{ width: "100%", height: "100%" }} />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "1.5em",
                  fontWeight: "500",
                  color: "#0F0718",
                }}
              >
                {percent}
              </div>
            </div>
            <p style={{ color: "#0F0718", fontWeight: 500, fontSize: "2.5em", marginBottom: 0 }}>Till loading in process</p>
          </div>
        </div>
        <div
          style={{
            width: "48%",
            height: "83vh",
            background: "#fff",
            margin: "1.5%",
            borderRadius: "10px",
            color: "#0F0718",
          }}
        >
          {["Product Categories", "Products", "Promotions"].map((label, index) => {
            const percentages = [productCategoryPercentage, productPercentage, promotionPercentage];
            return (
              <div key={index}>
                <p style={{ fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", padding: "0 2.5vw" }}>{label}</p>
                <Progress strokeColor={{ from: "#2F3856", to: "#2F3856" }} style={{ padding: "0 2.5vw", fontSize: "1em" }} percent={percentages[index]} status="active" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
