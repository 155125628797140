import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <div style={{ fontSize: "2vh", lineHeight: "1.4" }}>
      <h2 style={{ fontSize: "2vh", fontWeight: "bold" }}>Privacy Policy</h2>
      <p>
        At Sproutz Integrated Services Private Limited, we understand that the privacy and security of your personal information is important. This Privacy Policy explains how we
        collect, use, and disclose your information when you use our online ERP business solutions and apps for retailers. By using and visiting our website and by submitting your
        personal information to us you agree to us using your personal information as set out in this privacy policy. <br />
        <p style={{ marginTop: "10px" }}>
          {" "}
          We respect the privacy of users of our website and recognize that when you choose to provide us with information about yourself, you trust us to act in a responsible
          manner with that information. This privacy policy contains important information about how we use your personal information.
        </p>
      </p>
      <h3 style={{ fontSize: "2vh", fontWeight: "bold" }}>Collection of Information</h3>
      We collect the following information from you when you use our apps and services:
      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
        <li>Contact Information such as name, email address, and phone number</li>
        <li>Company Information such as Sproutz Integrated services private Limited, address, and industry</li>
        <li>Job title</li>
        <li>Country of origin</li>
        <li>Your I.P. address</li>
        <li>Mobile device ID</li>
        <li>Billing Information such as credit card details and billing address</li>
        <li>Usage Data such as the pages you visit on our website and the features you use in our apps</li>
        <li>Information provided by you in relation to submitting a job application to us. This may at times include sensitive personal information, e.g., ethnic origin.</li>
      </ul>
      <h3 style={{ fontSize: "2vh", fontWeight: "bold" }}>Refund Policy</h3>
      <p style={{ fontSize: "2vh", fontWeight: "bold" }}>NO-REFUND Policy</p>
      Refunds take us away from this core objective. Keeping this in mind, we do not have a refund policy. We hope you appreciate this and accommodate us in the spirit of
      supporting our members who are looking to live an empowered and dignified life.
      <h3 style={{ fontSize: "2vh", fontWeight: "bold", marginTop: "7px" }}>Terms of Use</h3>
      <p>
        ● Please read these terms and conditions ("terms and conditions", "terms") carefully before using [pos.caftrix.cwcloud.in] website (“Caftrix ”, "cafteria") operated by
        [Sproutz Integrated services private Limited ] ("us", 'we", "our").{" "}
      </p>
      <h3 style={{ fontSize: "2vh", fontWeight: "bold" }}>Conditions of use</h3>
      <p>
        ● By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms
        of this Agreement, you are advised to stop using the website accordingly. [Sproutz Integrated services private Limited] only grants use and access of this website, its
        products, and its services to those who have accepted its terms.
      </p>
      <p>
        ● <strong>Privacy policy</strong>
      </p>
      <p>Before you continue using our website, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.</p>
      <p>
        ● <strong>Age restriction</strong>
      </p>
      <p>
        You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years old and legally adhere to
        this Agreement. [Sproutz Integrated services private Limited] assumes no responsibility for liabilities related to age misrepresentation.
      </p>
      <p>
        ● <strong>Intellectual property</strong>
      </p>
      <p>
        You agree that all materials, products, and services provided on this website are the property of [Sproutz Integrated services private Limited], its affiliates, directors,
        officers, employees, agents, suppliers, or licensors, including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you
        will not reproduce or redistribute the [Sproutz Integrated services private Limited]’s intellectual property in any way, including electronic, digital, or new trademark
        registrations.
      </p>
      <p>
        ● You grant [Sproutz Integrated services private Limited] a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and
        publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.
      </p>
      <p>
        ● <strong>User accounts</strong>
      </p>
      <p>
        As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information and
        maintaining the safety and security of your identifying information.
      </p>
      <p>Website terms and conditions template by WebsitePolicies.com</p>
      <p>● You are also responsible for all activities that occur under your account or password.</p>
      <p>● If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.</p>
      <p>● We reserve all rights to terminate accounts, edit or remove content, and cancel orders at our sole discretion.</p>
      <p>
        ● <strong>Applicable law</strong>
      </p>
      <p>
        By using this website, you agree that the laws of India, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort
        that might come between [Sproutz Integrated services private Limited] and you, or its business partners and associates.
      </p>
      <p>
        ● <strong>Disputes</strong>
      </p>
      <p>
        Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by state or federal court India, and you consent to
        exclusive jurisdiction and venue of such courts.
      </p>
      <p>
        ● <strong>Indemnification</strong>
      </p>
      <p>
        You agree to indemnify [Sproutz Integrated services private Limited] and its affiliates and hold [Sproutz Integrated services private Limited] harmless against legal claims
        and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
      </p>
      <p>
        ● <strong>Limitation on liability</strong>
      </p>
      <p>
        <strong>Sproutz Integrated Services Private Limited</strong> is not liable for any damages that may occur to you as a result of your misuse of our website.
      </p>
      <p>
        <strong>Sproutz Integrated Services Private Limited</strong> reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these
        changes through electronic mail. This Agreement is an understanding between [Sproutz Integrated services private Limited] and the user, and this supersedes and replaces all
        prior agreements regarding the use of this website.
      </p>
      <p>
        ● <strong>Contact details</strong>
      </p>
      <p>
        <strong>Phone :</strong> +91-8925927933 <br></br>
        <strong>Email :</strong> info@sproutz.co.in <br></br>
        <strong>Location :</strong> Dev's Ark, 5th Floor. 284/1B, Perungudi, Chennai - 600096
      </p>
    </div>
  );
};

export default PrivacyPolicyContent;
