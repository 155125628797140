import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, Input, Rate, Spin, message, Progress } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LoadingOutlined, SearchOutlined, CloseOutlined, LayoutOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import "react-simple-keyboard/build/css/index.css";
import Scan from "../../../../assets/images/scan.gif";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import SMS from "../../../../assets/images/sms-solid.svg";
import Receipt from "../../../../assets/images/receipt.svg";
import CrediCard from "../../../../assets/images/credit-card.svg";
import AddBag from "../../../../assets/images/bag-outline.svg";
import QRCode from "../../../../assets/images/qr-code.svg";
import GiftCard from "../../../../assets/images/gift-card.svg";
import Loyality from "../../../../assets/images/loyalty.svg";
import Sucess from "../../../../assets/images/sucess.gif";
import Settings from "../../../../assets/images/settings.gif";
import Sync from "../../../../assets/images/dataSync.gif";
import Cashup from "../../../../assets/images/cashup.gif";
import kioskBGI from "../../../../assets/images/kioskBGI.png";
import cashIcon from "../../../../assets/images/cashIcon.svg";
import Qrcode from "../../../../assets/images/qrcode.svg";
import Logo from "../../../../assets/images/logo-without-bg.png";
import leftLoading from "../../../../assets/images/opo.gif";
import { clock } from "../../../../utility/clock";
import { useGlobalContext } from "../../../../lib/auth/globalStore";
import kioskStyle from "./kioskStyle.json";
import db from "../../../../database";
import { processSync } from "../../../Sync/syncData";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import _ from "lodash";

import { PlusOutlined, MinusOutlined, CloseSquareFilled } from "@ant-design/icons";

const PaymentSelection = (props) => {
  const {
    isCardPaymentLoading,
    setIsCardPaymentLoading,
    addProduct,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeCutomer,
    selectedProductCategory,
    handleHorizantalScroll,
    handleScroll,
    handleKeyvalue,
    selectdata,
    removeProductModal,
    ref,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    requestPayment,
    selectedPaymentMethod,
    processOrder,
    amount,
    setKioskUI,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    openPaymentModalByCustomer,
    setProductsList,
    setOriginalProductsList,
    selectedCategory,
    setSelectedCategory,
    syncLoad,
    setSyncLoad,
    orderDelay,
    setOrderDelay,
    layoutType,
    setLayoutType,
    setCart,
    // deleteReceipt,
  } = props;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  const currenciesList = tillData?.tillAccess?.csBunit?.currencies;
  const customerId = tillData?.tillAccess?.csBunit.b2cCustomer.cwrCustomerId;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userName = userData?.user;
  // const [layoutType, setLayoutType] = useState(0);
  const [rating, setRating] = useState(0);
  const [improvement, setImprovement] = useState("");
  const [recommendation, setRecommendation] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [feedbackJson, setFeedbackJson] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [clock1, setClock1] = useState(clock());
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const isLandscape = window.innerWidth > window.innerHeight && (themeJSON?.viewMode === "landscape" || !themeJSON?.viewMode);
  const itemsPerPage = 3;
  const isCardPaymentLoadingRef = useRef(isCardPaymentLoading);
  const tillId = tillData.tillAccess?.cwrTill?.cwrTillID;
  const [showFinal, setShowFinal] = useState(false);
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);

  useEffect(() => {
    isCardPaymentLoadingRef.current = isCardPaymentLoading;
  }, [isCardPaymentLoading]);

  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  const deleteReceipt = (param) => {
    let cartObj = {
      items: [],
      couponInput: [],
      advancePayment: 0,
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      docType: "Invoice",
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
      giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      // customer: selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer,
      salesRepId: null,
      cardPaymentData: {},
      // documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence}`,
      // documentno: `${tillData.tillAccess.cwrTill.prefix}${documentSequence}`,
      documentno: param ? `${tillData.tillAccess.cwrTill.prefix}${tillDocumentSequence + 1}` : `${tillData.tillAccess.cwrTill.prefix}${tillDocumentSequence + 1}`,
      // documentno: param ? `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}` : `${tillData.tillAccess.cwrTill.prefix}${documentSequence + 1}`,
    };
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    // setSelectedRowKeys([]);
    // setAmount("");
    // setSelectedPaymentMethod("");
  };

  const [isLocked, setIsLocked] = useState(false);
  const mainDivStyles = themeJSON.appBgImageURL
    ? {
        backgroundImage: `url(${themeJSON.appBgImageURL})`,
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: isLocked ? "none" : "block",
      }
    : {
        backgroundImage: `url(${kioskBGI})`,
        backgroundSize: "cover",
        backgroundPosition: "10% 10%",
        // backgroundColor: colorVariable ? colorVariable : "#989898",
        height: "100vh",
        width: "100%",
        position: "absolute",
      };

  const logoStyles = isThemeLogo
    ? { height: "8vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer", width: "5vw" } // Styles for theme logo
    : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  useEffect(() => {
    const storedFeedbackJson = localStorage.getItem("feedbackJson");
    if (storedFeedbackJson) {
      setFeedbackJson(JSON.parse(storedFeedbackJson));
    }
  }, []);

  // useEffect(() => {
  //   let syncOrdersInterval;

  //   const startSyncInterval = () => {
  //     syncOrdersInterval = setInterval(async () => {
  //       // Check if cartObj exists in localStorage
  //       if (!localStorage.getItem("cartObj")) {
  //         return;
  //       }

  //       const lastUpdatedTimeString = moment(localStorage.getItem("lastUpdatedTime")).format("HH:mm:ss");
  //       const lastUpdatedTime = moment(lastUpdatedTimeString, "HH:mm:ss");
  //       const lastUpdatedOrder = moment(orderDelay).format("HH:mm:ss");
  //       const lastOrderTime = moment(lastUpdatedOrder, "HH:mm:ss");

  //       // Create a moment object for the current time
  //       let cartObj = JSON.parse(localStorage.getItem("cartObj"));
  //       const currentTime = moment();
  //       const syncTime = posConfig["Incremental Data Sync Frequency (In Minutes)"] ? posConfig["Incremental Data Sync Frequency (In Minutes)"] : 15;
  //       const lastFullSyncTime = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");

  //       const fullSync = posConfig["fullDataSyncFrequency"] ? posConfig["fullDataSyncFrequency"] : 360;
  //       // Calculate the difference in minutes and seconds
  //       const diffMinutes = currentTime.diff(lastUpdatedTime, "minutes");
  //       let lastUpdatedFullTimeString = moment(new Date()).format("HH:mm:ss");
  //       if (lastFullSyncTime?.length > 0) {
  //         lastUpdatedFullTimeString = moment(lastFullSyncTime[0]?.syncEndTime).format("HH:mm:ss");
  //       }
  //       const lastUpdatedFullTime = moment(lastUpdatedFullTimeString, "HH:mm:ss");
  //       const diffMinutesFullSync = currentTime.diff(lastUpdatedFullTime, "minutes");
  //       const diffSeconds = currentTime.diff(lastOrderTime, "seconds");
  //       if (cartObj.items?.length === 0 && diffMinutesFullSync >= fullSync) {
  //         localStorage.removeItem("lastUpdatedTime");
  //         setSyncLoad(true);
  //         let tillData = JSON.parse(localStorage.getItem("tillData"));
  //         // const defaultCustomer = selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : defaultCustomer;
  //         // setCart({ ...cart, customer: defaultCustomer });
  //         await processSync(tillData, setGlobalStore, "Full Sync");
  //         setSyncLoad(false);
  //         setGlobalStore({ percent: "0%" });
  //         setGlobalStore({ productCategoryPercentage: "0" });
  //         setGlobalStore({ productPercentage: "0" });
  //         setGlobalStore({ promotionPercentage: "0" });
  //         // window.location.reload();
  //       } else if (cartObj.items?.length === 0 && diffMinutes >= syncTime && diffSeconds >= 15) {
  //         clearInterval(syncOrdersInterval); // Clear the interval while syncing
  //         setSyncLoad(true);
  //         let tillData = JSON.parse(localStorage.getItem("tillData"));
  //         // const defaultCustomer = selectedSaleTypeData?.customer?.b2cCustomerId ? selectedSaleTypeData?.customer : tillData.tillAccess.csBunit.b2cCustomer;
  //         // setCart({ ...cartObj, customer: defaultCustomer });
  //         await processSync(tillData, setGlobalStore, "Incremental Sync");
  //         setSyncLoad(false);
  //         setGlobalStore({ percent: "0%" });
  //         setGlobalStore({ productCategoryPercentage: "0" });
  //         setGlobalStore({ productPercentage: "0" });
  //         setGlobalStore({ promotionPercentage: "0" });
  //         // window.location.reload();
  //         // startSyncInterval(); // Restart the interval after sync is complete
  //       }
  //     }, 1000); // 30000 milliseconds = 30 seconds
  //   };

  //   startSyncInterval();

  //   return () => {
  //     clearInterval(syncOrdersInterval);
  //   };
  // }, []);

  const getFeedbackName = () => {
    if (feedbackJson && feedbackJson.enable_rating === "Y") {
      return feedbackJson.name || "How was your experience?";
    }
    return "How was your experience?";
  };

  const handleRatingChange = (value) => {
    setRecommendation(value);
  };

  const handleInputChange = (questionId, value) => {
    switch (questionId) {
      case "Recommendation":
        setRecommendation(value);
        break;
      case "SelectedFeatures":
        setSelectedFeatures(value);
        break;
      case "Improvement":
        setImprovement(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (questionId, value) => {
    setSelectedFeatures((prevFeatures) => ({
      ...prevFeatures,
      [questionId]: value, // Only one value per question
    }));
  };

  const handleChange = (value) => {
    setRating(value);
    // setLayoutType(4);
  };

  // useEffect(() => {
  //   if (rating > 0) {
  //     const timer = setTimeout(() => {
  //       setLayoutType(4);
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [rating]);

  useEffect(() => {
    if (rating > 0) {
      const timer = setTimeout(() => {
        // setLayoutType(4);
        const feedbackJson = localStorage.getItem("feedbackJson");
        if (feedbackJson && JSON.parse(feedbackJson).length === 0) {
          localStorage.setItem("kioskUI", 0);
          setKioskUI(0);
          const customEvent = new CustomEvent("customStorageChange", {
            detail: { key: "kioskUI", newValue: 0 },
          });
          window.dispatchEvent(customEvent);
        } else {
          setLayoutType(4);
        }
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [rating]);

  const handleSubmit = () => {
    const feedbackData = {
      recommendation,
      bestFeature: selectedFeatures,
      improvement,
    };
    localStorage.setItem("kioskUI", 0);
    setKioskUI(0);
    const customEvent = new CustomEvent("customStorageChange", {
      detail: { key: "kioskUI", newValue: 0 },
    });
    window.dispatchEvent(customEvent);
  };

  const feedbackData = [
    {
      gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=jGU94kCxz7ihER4ybN5di3xnmCLvNL5CxJRAlIq0jTPXIyGTxLlqOZv9t3EXTH4lp0U03SWsSbbvb~NG1fBr6fcPabL524SWDcoY7f-qkbs6nmGLblfXCVXIyVhMqd4rrRFgO6HsDbdbzP5pS1dVb9Y80p7D~Gj3YslyBDdVQcW2ulewjmNhuGPJ3o7bVLLItf175jbh9jb5GNREVuiCT-yrKUTavB7ish98Go-8V3ew-ZNHmXNxNjrKaaxmaPYHAOW~lUnkwG~izr3WyCvZCxkv-6VmnBzTG60xYBaphSCrtMIc4mWjjoM5PBiJClceWgHQs44j7cBx3-dlYAK8cQ__",
      text: "Disappointing",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=bx7l6KEpeS4UHuKj3RXAvLTQtqOzXwG1iYnXyVLo542vUQ86wbe40TSl-SeetZKxzY73~QoP6CF2akF9MezFe0wZs9BASnyoSGr18UNP3okozS8h1VgpNWxP0nWDwRr12LOo3XBraz2LY8b82-JfkLGlWL2neuJSwiu98iL40KoROkepKTVxy7YGW~ZLPZVBw4hTS3xWL2em9UtfORdCN~BuP1WZZOJQuxLQBEoG~oRWUr98F~MUjFkLh7e-cQxnRVeOPRJsAPO-lFNHXLR-o8syqwct4AXDReFJ-Ey3zWmDRMf5Mx6XU2TPqEYsadb6nmbTlnAI-Tu7KTXjZdeVTg__",
      text: "Just OK",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=eLSmlVGX4d0L7V7Xt~x8B6Zb0-OGybvPk2RKJAxSqPTqQMJMmZDzcsAMn0q3ahZQHfl~xtmnE4aFtQOuTTmZVBlKHd~h8BKg9mdAS1Epm6UgCC2bE5BVFdiwBCzWCwA4vO606C~rFDlgsdVA87nhmDdRqF9Z4-ezZ2NLplW4gsQZjTYPrKklV1ciP4eJqRp1V6jFx8CS3nN2LKs4sQY4dJmBcATaTbYTxxU8DkMKiBgsqmoQCHrT9DUOcJJZ-zPqcHkVTIp73O6mgY76nfKpxogiaULKJrX-b1GehQXWtetFayFoLBGFmV1iI2CQrxM~Ilbjm2~FEsAMVtn5~rrhgg__",
      text: "Satisfactory",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Ux-Wg23HqYz5~PXabW9eIChv03DdvaLpL8XNR4E4ct4BYHEubXWsixLcn0G~nAMSieB5RTixuHP~~555~ocC~yfnG3S5fqyDu4O9LNTpPRP~C8eUReFnN0~f~cserw9NQVv6fGT9gbix7bHu-uQOXO-1lp86fuQBpBrVO4RFLMM2HAu3CDqfBiZvy-q8lAjPdob0pjF7RrDuo91M~cio0Ft~~glRkHlPr5nSmLEakqZAz0INFieAys5rfV4natn8DMo47dV1ZJt2LftzajCrsZDbftaiTLHRdJQTKxRgimVrqkWYKcW3-oMbq8-ktRR-RHRVOCJ86qI-5dbZCnvHTg__",
      text: "Impressive",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=r9sVNgInlaN5e3DMohqG8VDaw~3wK-wUz8NU5DAWpCDfyKZE5unriTs5THzfE3cvCBplNZr79-nMdEmjahT6q3DSQzNY84ZHy7O2icjHxT4s9igDTBmL1f5ECH5vA48froZTJmktWD~792VMijLF-qjiJ4Jpe4KfG8GwgxZ-APTqqOMARSPymotPNoVDUyohhZ8ylGj1ezfZCbvfJgGiVDmqvCBulf13OyCgbNs0l04H~J7OBexbyyu5Xgx7h79kl9jalsAZ-5l-5~ssIPLI8CkOJAZPo1jn7LCU-Li3XdbQ5nKXUxogKIK-2L3mhsyC52u47K9~7ft8i9HgS0O-vw__",
      text: "Outstanding",
    },
  ];

  const feedbackData1 = [
    {
      gif: "https://s3-alpha-sig.figma.com/img/03a9/c8a4/e35687011ba2313ecaa09fc4b050f63c?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=jGU94kCxz7ihER4ybN5di3xnmCLvNL5CxJRAlIq0jTPXIyGTxLlqOZv9t3EXTH4lp0U03SWsSbbvb~NG1fBr6fcPabL524SWDcoY7f-qkbs6nmGLblfXCVXIyVhMqd4rrRFgO6HsDbdbzP5pS1dVb9Y80p7D~Gj3YslyBDdVQcW2ulewjmNhuGPJ3o7bVLLItf175jbh9jb5GNREVuiCT-yrKUTavB7ish98Go-8V3ew-ZNHmXNxNjrKaaxmaPYHAOW~lUnkwG~izr3WyCvZCxkv-6VmnBzTG60xYBaphSCrtMIc4mWjjoM5PBiJClceWgHQs44j7cBx3-dlYAK8cQ__",
      text: "Disappointing",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/b221/1cd2/15af41f80db44a56689429583b89c8ae?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=bx7l6KEpeS4UHuKj3RXAvLTQtqOzXwG1iYnXyVLo542vUQ86wbe40TSl-SeetZKxzY73~QoP6CF2akF9MezFe0wZs9BASnyoSGr18UNP3okozS8h1VgpNWxP0nWDwRr12LOo3XBraz2LY8b82-JfkLGlWL2neuJSwiu98iL40KoROkepKTVxy7YGW~ZLPZVBw4hTS3xWL2em9UtfORdCN~BuP1WZZOJQuxLQBEoG~oRWUr98F~MUjFkLh7e-cQxnRVeOPRJsAPO-lFNHXLR-o8syqwct4AXDReFJ-Ey3zWmDRMf5Mx6XU2TPqEYsadb6nmbTlnAI-Tu7KTXjZdeVTg__",
      text: "Just OK",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=eLSmlVGX4d0L7V7Xt~x8B6Zb0-OGybvPk2RKJAxSqPTqQMJMmZDzcsAMn0q3ahZQHfl~xtmnE4aFtQOuTTmZVBlKHd~h8BKg9mdAS1Epm6UgCC2bE5BVFdiwBCzWCwA4vO606C~rFDlgsdVA87nhmDdRqF9Z4-ezZ2NLplW4gsQZjTYPrKklV1ciP4eJqRp1V6jFx8CS3nN2LKs4sQY4dJmBcATaTbYTxxU8DkMKiBgsqmoQCHrT9DUOcJJZ-zPqcHkVTIp73O6mgY76nfKpxogiaULKJrX-b1GehQXWtetFayFoLBGFmV1iI2CQrxM~Ilbjm2~FEsAMVtn5~rrhgg__",
      text: "Satisfactory",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/0f17/b5a3/47afe79f315fc8178f9a03cf6b455405?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Ux-Wg23HqYz5~PXabW9eIChv03DdvaLpL8XNR4E4ct4BYHEubXWsixLcn0G~nAMSieB5RTixuHP~~555~ocC~yfnG3S5fqyDu4O9LNTpPRP~C8eUReFnN0~f~cserw9NQVv6fGT9gbix7bHu-uQOXO-1lp86fuQBpBrVO4RFLMM2HAu3CDqfBiZvy-q8lAjPdob0pjF7RrDuo91M~cio0Ft~~glRkHlPr5nSmLEakqZAz0INFieAys5rfV4natn8DMo47dV1ZJt2LftzajCrsZDbftaiTLHRdJQTKxRgimVrqkWYKcW3-oMbq8-ktRR-RHRVOCJ86qI-5dbZCnvHTg__",
      text: "Impressive",
    },
    {
      gif: "https://s3-alpha-sig.figma.com/img/0975/2a13/d8f893cb3f1cb5938e1253295f70e9e3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=r9sVNgInlaN5e3DMohqG8VDaw~3wK-wUz8NU5DAWpCDfyKZE5unriTs5THzfE3cvCBplNZr79-nMdEmjahT6q3DSQzNY84ZHy7O2icjHxT4s9igDTBmL1f5ECH5vA48froZTJmktWD~792VMijLF-qjiJ4Jpe4KfG8GwgxZ-APTqqOMARSPymotPNoVDUyohhZ8ylGj1ezfZCbvfJgGiVDmqvCBulf13OyCgbNs0l04H~J7OBexbyyu5Xgx7h79kl9jalsAZ-5l-5~ssIPLI8CkOJAZPo1jn7LCU-Li3XdbQ5nKXUxogKIK-2L3mhsyC52u47K9~7ft8i9HgS0O-vw__",
      text: "Outstanding",
    },
  ];

  const renderInputField = (feedbackLine) => {
    switch (feedbackLine.question_type) {
      case "Multiple Choice":
        return (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "35px", marginLeft: "10vw" }}>
            {feedbackLine.option_list &&
              feedbackLine.option_list.map((option, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    name={feedbackLine.title}
                    value={option}
                    checked={selectedFeatures[feedbackLine.title] === option}
                    onChange={(e) => handleCheckboxChange(feedbackLine.title, e.target.value)}
                    id={`option-${index}`}
                    style={{ transform: "scale(1.4)", margin: 0 }}
                  />
                  <label htmlFor={`option-${index}`} style={{ marginLeft: "12px", fontSize: "20px" }}>
                    {option}
                  </label>
                </div>
              ))}
          </div>
        );

      case "Rating":
        const maxRating = feedbackLine.max_rating || 5;
        return (
          <div style={{ display: "flex", gap: "20px", marginLeft: "11vw" }}>
            {[...Array(maxRating).keys()].map((index) => (
              <button
                key={index}
                onClick={() => handleRatingChange(index + 1)}
                style={{
                  display: "inline-block",
                  width: "70px",
                  height: "70px",
                  lineHeight: "70px",
                  textAlign: "center",
                  borderRadius: "50%",
                  backgroundColor: index + 1 <= recommendation ? "#FFF" : "transparent",
                  color: index + 1 <= recommendation ? "#000" : "#FFF",
                  border: "1px solid #FFF",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        );

      case "Text":
        return (
          <textarea
            value={improvement}
            placeholder={feedbackLine.placeholder_text || ""}
            onChange={(e) => handleInputChange("Improvement", e.target.value)}
            style={{ width: "100%", height: "100px", resize: "none", color: "#0F0718" }}
          />
        );

      default:
        return null;
    }
  };

  const getCurrentPageItems = () => {
    if (!feedbackJson) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return feedbackJson.feedbackLines.slice(startIndex, endIndex);
  };

  const styles = {
    container: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fff",
    },
    header: {
      background: "#fff",
      color: "#2F3856",
      textAlign: "center",
      padding: "1.3vh 0",
      fontSize: "2.5vh",
      fontWeight: "500",
      width: "100%",
    },
    searchBar: {
      width: "100%",
      padding: "0.7vh 2vw",
      borderRadius: "25px",
    },
    featuredSection: {
      width: "100%",
      padding: "0 0 2vh 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    featuredList: {
      display: "flex",
      gap: "2vw",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    featuredCard: {
      width: "22vw",
      minWidth: "150px",
      padding: "1.5vh",
      borderRadius: "1.5vh",
      textAlign: "center",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
    featuredHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "94%",
    },
    featuredTitle: {
      fontSize: "2vh",
      fontWeight: "600",
      color: "#2F3856",
    },
    viewAll: {
      fontSize: "1.6vh",
      color: "#2F3856",
      textDecoration: "none",
      cursor: "pointer",
    },
    menuCategories: {
      display: "flex",
      justifyContent: "center",
      gap: "2vw",
      margin: "2vh 0",
    },
    categoryBtn: {
      fontSize: "1.8vh",
      padding: "1vh 2vw",
      borderRadius: "1vh",
      backgroundColor: "white",
    },
    activeCategoryBtn: {
      backgroundColor: "#2F3856",
      color: "white",
    },
    productGrid: {
      width: "94%",
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(280px, 1fr))",
      gap: "2vw",
      margin: "2vh auto",
    },
    productCard: {
      padding: "2vh",
      borderRadius: "1.5vh",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
    productName: {
      fontSize: "2vh",
      fontWeight: "500",
    },
    productDesc: {
      fontSize: "1.5vh",
      color: "#555",
    },
    price: {
      fontSize: "2vh",
      fontWeight: "500",
      color: "#2F3856",
    },
    footer: {
      width: "100%",
      height: "8vh",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      position: "fixed",
      bottom: 0,
    },
    footerIcon: {
      fontSize: "2.5vh",
      color: "white",
      cursor: "pointer",
    },
    menuContainer: {
      display: "flex",
      backgroundColor: "#fff",
      justifyContent: "space-between",
      alignItems: "center",
      width: "94%",
      height: "8vh",
    },
    categoryButton: {
      flex: 1, // Ensures equal width for each button
      padding: "10px 0",
      border: "none",
      backgroundColor: "#f3f4f9",
      color: "#333",
      fontSize: "14px",
      cursor: "pointer",
      transition: "background 0.3s",
      textAlign: "center", // Centers text inside each button
    },
    activeButton: {
      backgroundColor: "#2F3856",
      color: "#fff",
      fontWeight: "500",
      borderRadius: "10px",
      boxShadow: "0 0.5vh 1vh rgba(0, 0, 0, 0.1)",
    },
  };

  return (
    <>
      {syncLoad ? (
        <>
          <div style={{ backgroundColor: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", width: "100%" }}>
            <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
              <Col span={6}>
                <img src={Logo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} />
              </Col>
              <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "1vw", fontSize: "1vw" }}>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{formattedDateTime}</div>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{clock1}</div>
              </Col>
            </Row>
            <div style={{ display: "flex", height: "90vh" }}>
              <div
                style={{
                  width: "48%",
                  background: "#fff",
                  height: "83vh",
                  margin: "1.5%",
                  marginRight: 0,
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1vw" }}>
                  <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
                    <img
                      src={leftLoading}
                      alt="Loading"
                      style={{
                        width: "100%",
                        height: "100%",
                        // transform: `rotate(${rotation}deg)`,
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "1.5em",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {percent}
                    </div>
                  </div>
                  <p style={{ color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "2.5em", marginBottom: 0 }}>Till loading in process</p>
                  {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
         <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
                </div>
              </div>
              <div style={{ width: "48%", height: "83vh", background: "#fff", margin: "1.5%", borderRadius: "10px", color: "#0F0718" }}>
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "17vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>
                  Product Categories
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productCategoryPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Products</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Promotions</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={promotionPercentage}
                  status="active"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        // <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={isCardPaymentLoading}>
        <div style={{ display: "flex", height: "100vh", width: "100vw" }}>
          {/* <div className="customer-login">
          <img
            src={kisokLogo}
            style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }}
            onClick={() => {
              localStorage.setItem("layoutType", 0);
              const customEvent1 = new CustomEvent("customStorageChange", {
                detail: { key: "layoutType", newValue: 0 },
              });
            }}
          />
          <p className="user-name">Hey, {userName}</p>
        </div> */}
          {layoutType === 0 ? (
            // <div style={isLandscape ? kioskStyle.paymentScreen.landscape.container : kioskStyle.paymentScreen.portrait.container}>
            //   <p style={isLandscape ? kioskStyle.paymentScreen.landscape.text : kioskStyle.paymentScreen.portrait.text}>Choose Your Preferred Payment Method</p>
            //   <div style={isLandscape ? kioskStyle.paymentScreen.landscape.paymentContainer : kioskStyle.paymentScreen.portrait.paymentContainer}>
            //     {_.sortBy(tillDataPaymentMethods, "sequenceNo")
            //       .filter((pm) => ["cash", "upi", "card"].includes(pm.name.toLowerCase())) // Filter payment methods
            //       .map((pm, ind) => (
            //         <div
            //           key={ind}
            //           style={{
            //             backgroundColor: themeJSON.productTableBGColor || "#626367",
            //             ...(isLandscape ? kioskStyle.paymentScreen.landscape.paymentCard : kioskStyle.paymentScreen.portrait.paymentCard),
            //           }}
            //           onClick={() => {
            //             requestPayment(pm, cart.total);
            //             openPaymentModalByCustomer();

            //             const waitForCardPaymentToComplete = () => {
            //               setTimeout(() => {
            //                 if (!isCardPaymentLoadingRef.current) {
            //                   // Only proceed if loading is false
            //                   setLayoutType(1);
            //                   localStorage.setItem("layoutType", 1);

            //                   const customEvent1 = new CustomEvent("customStorageChange", {
            //                     detail: { key: "layoutType", newValue: 1 },
            //                   });
            //                   window.dispatchEvent(customEvent1);

            //                   const customEvent = new CustomEvent("customStorageChange", {
            //                     detail: { key: "kioskUI", newValue: 0 },
            //                   });
            //                   window.dispatchEvent(customEvent);
            //                 } else {
            //                   waitForCardPaymentToComplete(); // Keep checking if still loading
            //                 }
            //               }, 500); // Check every 500ms
            //             };

            //             waitForCardPaymentToComplete();
            //           }}
            //         >
            //           <p style={isLandscape ? kioskStyle.paymentScreen.landscape.textContainer : kioskStyle.paymentScreen.portrait.textContainer}>
            //             {pm.name.toLowerCase() === "cash" ? (
            //               <>
            //                 <img src={cashIcon} style={isLandscape ? kioskStyle.paymentScreen.landscape.cashImg : kioskStyle.paymentScreen.portrait.cashImg} />
            //                 <p>{pm.name}</p>
            //               </>
            //             ) : pm.name.toLowerCase() === "upi" ? (
            //               <>
            //                 <img src={Qrcode} style={isLandscape ? kioskStyle.paymentScreen.landscape.upiImg : kioskStyle.paymentScreen.portrait.upiImg} />
            //                 <p>{pm.name}</p>
            //               </>
            //             ) : pm.name.toLowerCase() === "card" ? (
            //               <>
            //                 <img src={CrediCard} style={isLandscape ? kioskStyle.paymentScreen.landscape.cashImg : kioskStyle.paymentScreen.portrait.cashImg} />
            //                 <p>{pm.name}</p>
            //               </>
            //             ) : null}
            //           </p>
            //         </div>
            //       ))}
            //   </div>
            // </div>
            <div style={styles.container}>
              <header style={styles.header}>Payment</header>
              <div style={styles.menuContainer}>
                {_.sortBy(tillDataPaymentMethods, "sequenceNo")
                  .filter((pm) => ["cash", "upi", "card"].includes(pm.name.toLowerCase()) && pm?.cwrTillId === tillId)
                  .map((pm, ind) => (
                    <div
                      key={ind}
                      style={{
                        ...styles.categoryButton,
                        ...(selectedPaymentMethod.name === pm.name ? styles.activeButton : {}),
                      }}
                      onClick={() => {
                        setSelectedPaymentMethod(pm);
                      }}
                    >
                      <p style={isLandscape ? kioskStyle.paymentScreen.landscape.textContainer : kioskStyle.paymentScreen.portrait.textContainer}>
                        {pm.name.toLowerCase() === "cash" ? (
                          <>
                            {/* <img src={cashIcon} style={isLandscape ? kioskStyle.paymentScreen.landscape.cashImg : kioskStyle.paymentScreen.portrait.cashImg} /> */}
                            {pm.name}
                          </>
                        ) : pm.name.toLowerCase() === "upi" ? (
                          <>
                            {/* <img src={Qrcode} style={isLandscape ? kioskStyle.paymentScreen.landscape.upiImg : kioskStyle.paymentScreen.portrait.upiImg} /> */}
                            {pm.name}
                          </>
                        ) : pm.name.toLowerCase() === "card" ? (
                          <>
                            {/* <img src={CrediCard} style={isLandscape ? kioskStyle.paymentScreen.landscape.cashImg : kioskStyle.paymentScreen.portrait.cashImg} /> */}
                            {pm.name}
                          </>
                        ) : null}
                      </p>
                    </div>
                  ))}
              </div>
              <img src={cashIcon} style={isLandscape ? kioskStyle.paymentScreen.landscape.cashImg : kioskStyle.paymentScreen.portrait.cashImg} />
            </div>
          ) : layoutType === 1 ? (
            <div style={isLandscape ? kioskStyle.printScreen.landscape.printContainer : kioskStyle.printScreen.portrait.printContainer}>
              <img src={Sucess} style={isLandscape ? kioskStyle.printScreen.landscape.printSuccess : kioskStyle.printScreen.portrait.printSuccess} />
              <p style={isLandscape ? kioskStyle.printScreen.landscape.headerText : kioskStyle.printScreen.portrait.headerText}>Payment Successful</p>
              <p style={isLandscape ? kioskStyle.printScreen.landscape.labelText : kioskStyle.printScreen.portrait.labelText}>How would you like your receipt ?</p>
              <div style={isLandscape ? kioskStyle.printScreen.landscape.cardContainer : kioskStyle.printScreen.portrait.cardContainer}>
                {posConfig.eBill === "Y" ? (
                  <div style={isLandscape ? kioskStyle.printScreen.landscape.cardWrapper : kioskStyle.printScreen.portrait.cardWrapper}>
                    <div
                      style={{
                        backgroundColor: themeJSON.productTableBGColor || "#626367",
                        ...(isLandscape ? kioskStyle.printScreen.landscape.ebillCard : kioskStyle.printScreen.portrait.ebillCard),
                      }}
                      // onClick={() => setLayoutType(5)}
                      onClick={() => {
                        if (posConfig.enableCustomerFeedback === "Y") {
                          setLayoutType(5);
                        } else {
                          localStorage.setItem("kioskUI", 0);
                          setKioskUI(0);
                          const customEvent = new CustomEvent("customStorageChange", {
                            detail: { key: "kioskUI", newValue: 0 },
                          });
                          window.dispatchEvent(customEvent);
                        }
                      }}
                    >
                      <img src={Receipt} style={isLandscape ? kioskStyle.printScreen.landscape.image : kioskStyle.printScreen.portrait.image} />
                      <p style={isLandscape ? kioskStyle.printScreen.landscape.cardText : kioskStyle.printScreen.portrait.cardText}>Print Receipt</p>
                    </div>
                    <div
                      style={{
                        backgroundColor: themeJSON.productTableBGColor || "#626367",
                        ...(isLandscape ? kioskStyle.printScreen.landscape.smsCard : kioskStyle.printScreen.portrait.smsCard),
                      }}
                      // onClick={() => setLayoutType(5)}
                      onClick={() => {
                        if (posConfig.enableCustomerFeedback === "Y") {
                          setLayoutType(5);
                        } else {
                          localStorage.setItem("kioskUI", 0);
                          setKioskUI(0);
                          const customEvent = new CustomEvent("customStorageChange", {
                            detail: { key: "kioskUI", newValue: 0 },
                          });
                          window.dispatchEvent(customEvent);
                        }
                      }}
                    >
                      <img src={SMS} style={isLandscape ? kioskStyle.printScreen.landscape.image : kioskStyle.printScreen.portrait.image} />
                      <p style={isLandscape ? kioskStyle.printScreen.landscape.cardText : kioskStyle.printScreen.portrait.cardText}>Digital Receipt</p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: themeJSON.productTableBGColor || "#626367",
                      ...(isLandscape ? kioskStyle.printScreen.landscape.ebillCard : kioskStyle.printScreen.portrait.ebillCard),
                    }}
                    // onClick={() => setLayoutType(5)}
                    onClick={() => {
                      if (posConfig.enableCustomerFeedback === "Y") {
                        setLayoutType(5);
                      } else {
                        localStorage.setItem("kioskUI", 0);
                        setKioskUI(0);
                        const customEvent = new CustomEvent("customStorageChange", {
                          detail: { key: "kioskUI", newValue: 0 },
                        });
                        window.dispatchEvent(customEvent);
                      }
                    }}
                  >
                    <img src={Receipt} style={isLandscape ? kioskStyle.printScreen.landscape.image : kioskStyle.printScreen.portrait.image} />
                    <p style={isLandscape ? kioskStyle.printScreen.landscape.cardText : kioskStyle.printScreen.portrait.cardText}>Print Receipt</p>
                  </div>
                )}
              </div>
            </div>
          ) : layoutType === 3 ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
              <div style={{ display: "flex", paddingTop: "3.4%", paddingBottom: "8vh", justifyContent: "center", width: "100%" }}>
                <div
                  style={{
                    height: "43vh",
                    width: "22vw",
                    backgroundColor: "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "5%",
                    borderRadius: "10px",
                  }}
                  onClick={() => setLayoutType(1)}
                >
                  <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={Cashup} style={{ height: "15vh" }} />
                  </div>

                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "1.2",
                      position: "relative",
                      fontWeight: "500",
                      margin: "9%",
                    }}
                  >
                    Cash Up Details
                  </p>
                  <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                    Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                  </p>
                </div>

                <div
                  style={{
                    height: "43vh",
                    width: "22vw",
                    backgroundColor: "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginRight: "5%",
                    borderRadius: "10px",
                  }}
                  onClick={() => setLayoutType(1)}
                >
                  <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={Sync} style={{ height: "15vh" }} />
                  </div>

                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "1.2",
                      position: "relative",
                      fontWeight: "500",
                      margin: "9%",
                    }}
                  >
                    Data Sync
                  </p>
                  <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                    Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                  </p>
                </div>

                <div
                  style={{
                    height: "43vh",
                    width: "22vw",
                    backgroundColor: "#626367",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    //   marginRight: "5%",
                    borderRadius: "10px",
                  }}
                  onClick={() => setLayoutType(1)}
                >
                  <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={Settings} style={{ height: "15vh" }} />
                  </div>

                  <p
                    style={{
                      wordBreak: "break-word",
                      fontSize: "2vw",
                      color: "#fff",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "1.2",
                      position: "relative",
                      fontWeight: "500",
                      margin: "9%",
                    }}
                  >
                    Kiosk Settings
                  </p>
                  <p style={{ textAlign: "center", width: "94%", color: "#fff", lineHeight: "1.2" }}>
                    Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                  </p>
                </div>
              </div>
            </div>
          ) : layoutType === 4 ? (
            <div style={{ display: "flex", justifyContent: "center", width: "100%", flexDirection: "column", color: "#FFF", alignItems: "center" }}>
              {feedbackJson?.feedbackLines?.map((feedbackLine, index) => (
                <div key={index} style={{ marginBottom: "20px", marginTop: "30px" }}>
                  <div style={{ fontSize: "23px", marginBottom: "15px", fontWeight: "300" }}>
                    {feedbackLine.title}
                    {feedbackLine.is_required === "Y" && <span style={{ color: "red" }}>*</span>}
                  </div>
                  {renderInputField(feedbackLine)}
                </div>
              ))}

              <div style={{ display: "flex", width: "86.5%", marginTop: "20px", paddingLeft: "57vw" }}>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  style={{
                    //  backgroundColor: "#EEC302",
                    backgroundColor: themeJSON.btnsBgColor || "#EEC302",
                    color: "#FFF",
                    borderRadius: "5px",
                    width: "11vw",
                    height: "7.5vh",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : layoutType === 5 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                color: "#FFF",
              }}
            >
              <div>
                {rating > 0 ? (
                  <img src={feedbackData1[rating - 1].gif} alt={feedbackData[rating - 1].text} style={{ width: "150px", height: "150px" }} />
                ) : (
                  // https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__
                  // <img src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I7rZpJJRybQy8OaQYbMDMbwUgHppJ2DSblWS2pRuut3-P-P49DFaUQcAYVGse8oilLtkl4haMcuaayJ7o9vFvl6~Pht7cfxACVrTFggMA2Kk-To~h4e06Ru-rsSwJt18xrZk81APpJDNWtXFCo6PI3aGelMeAwVtAo6kNJ3-VNsGvaDz8OjYQBn5VzD9O0H4OLwjQ1~8iVriH8pGgAHl3BJJ5U~FFkyYCfjH~X7fdnBlKKObAn-NrNWLc~CA7y3T~1MqiQ6F1-SS1iC8UiZYJtmWycYbDBoBfNtyozsQqipCCDDEMEsVgE4Fh1CcQ7h-H7I3o6TckuUnUHjVvBgYpg__" alt="Default" style={{ width: '150px', height: '150px' }} />
                  <img
                    // src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__"
                    src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=eLSmlVGX4d0L7V7Xt~x8B6Zb0-OGybvPk2RKJAxSqPTqQMJMmZDzcsAMn0q3ahZQHfl~xtmnE4aFtQOuTTmZVBlKHd~h8BKg9mdAS1Epm6UgCC2bE5BVFdiwBCzWCwA4vO606C~rFDlgsdVA87nhmDdRqF9Z4-ezZ2NLplW4gsQZjTYPrKklV1ciP4eJqRp1V6jFx8CS3nN2LKs4sQY4dJmBcATaTbYTxxU8DkMKiBgsqmoQCHrT9DUOcJJZ-zPqcHkVTIp73O6mgY76nfKpxogiaULKJrX-b1GehQXWtetFayFoLBGFmV1iI2CQrxM~Ilbjm2~FEsAMVtn5~rrhgg__"
                    alt="Default"
                    style={{ width: "150px", height: "150px" }}
                  />
                )}
              </div>
              {rating > 0 && (
                <div
                  style={{
                    // marginTop: "10px",
                    fontSize: "45px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    lineHeight: "normal",
                    fontStyle: "normal",
                    color: "#FFF",
                  }}
                >
                  {feedbackData[rating - 1].text}
                </div>
              )}

              <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                <Rate
                  onChange={handleChange}
                  //  onClick={()=>{setLayoutType(4)}}
                  value={rating}
                  style={{ fontSize: "100px", display: "flex", justifyContent: "space-between", width: "57%" }}
                />
              </div>
              <hr
                style={{
                  borderColor: "gray",
                  width: "50%",
                  margin: "20px 0",
                  borderWidth: "1px",
                }}
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "40px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  lineHeight: "normal",
                  fontStyle: "normal",
                  color: "#FFF",
                }}
              >
                {getFeedbackName()}
              </div>
            </div>
          ) : layoutType === 6 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                color: "#FFF",
              }}
            >
              <div>
                {rating > 0 ? (
                  <img src={feedbackData1[rating - 1].gif} alt={feedbackData[rating - 1].text} style={{ width: "150px", height: "150px" }} />
                ) : (
                  // https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__
                  // <img src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I7rZpJJRybQy8OaQYbMDMbwUgHppJ2DSblWS2pRuut3-P-P49DFaUQcAYVGse8oilLtkl4haMcuaayJ7o9vFvl6~Pht7cfxACVrTFggMA2Kk-To~h4e06Ru-rsSwJt18xrZk81APpJDNWtXFCo6PI3aGelMeAwVtAo6kNJ3-VNsGvaDz8OjYQBn5VzD9O0H4OLwjQ1~8iVriH8pGgAHl3BJJ5U~FFkyYCfjH~X7fdnBlKKObAn-NrNWLc~CA7y3T~1MqiQ6F1-SS1iC8UiZYJtmWycYbDBoBfNtyozsQqipCCDDEMEsVgE4Fh1CcQ7h-H7I3o6TckuUnUHjVvBgYpg__" alt="Default" style={{ width: '150px', height: '150px' }} />
                  <img
                    // src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BqYsqHk6IPytFDCjYlMN84L1EWhfQVAZtFc1V9D36x56-Yjp9U-pwRf00PrCj4xThhbDXy1z385bAevf~r9F-GWoCylnpYGWlC4VqnchNEgCwPKHh~nAMaKceRSfzrrysYTtH2yR8Ol56tmxsFFXuITuc8EJtgiTf03yIuJJMv9tlM~j7RsY~VozCB7A7ajMJN9tIA58fbaKZEkDjre6xwLsof5uT2I86hJ3T1VMwuDOamPRuatki2rlZiS0~XUXbzE5Zqwa7had~F8jP1rE4fK052OOtGoeQnSXgc2mQjHqXS1PQJ54i060lqBtWRCT2rIBpLX2dKlM2SsChBTcHw__"
                    src="https://s3-alpha-sig.figma.com/img/283b/1bf8/1aec97a54ed8ff1b37ceaf459c0860d3?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=eLSmlVGX4d0L7V7Xt~x8B6Zb0-OGybvPk2RKJAxSqPTqQMJMmZDzcsAMn0q3ahZQHfl~xtmnE4aFtQOuTTmZVBlKHd~h8BKg9mdAS1Epm6UgCC2bE5BVFdiwBCzWCwA4vO606C~rFDlgsdVA87nhmDdRqF9Z4-ezZ2NLplW4gsQZjTYPrKklV1ciP4eJqRp1V6jFx8CS3nN2LKs4sQY4dJmBcATaTbYTxxU8DkMKiBgsqmoQCHrT9DUOcJJZ-zPqcHkVTIp73O6mgY76nfKpxogiaULKJrX-b1GehQXWtetFayFoLBGFmV1iI2CQrxM~Ilbjm2~FEsAMVtn5~rrhgg__"
                    alt="Default"
                    style={{ width: "150px", height: "150px" }}
                  />
                )}
              </div>
              {rating > 0 && (
                <div
                  style={{
                    // marginTop: "10px",
                    fontSize: "45px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    lineHeight: "normal",
                    fontStyle: "normal",
                    color: "#FFF",
                  }}
                >
                  {feedbackData[rating - 1].text}
                </div>
              )}

              <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                <Rate
                  onChange={handleChange}
                  //  onClick={()=>{setLayoutType(4)}}
                  value={rating}
                  style={{ fontSize: "100px", display: "flex", justifyContent: "space-between", width: "57%" }}
                />
              </div>
              <hr
                style={{
                  borderColor: "gray",
                  width: "50%",
                  margin: "20px 0",
                  borderWidth: "1px",
                }}
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "40px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  lineHeight: "normal",
                  fontStyle: "normal",
                  color: "#FFF",
                }}
              >
                {getFeedbackName()}
              </div>
            </div>
          ) : layoutType === 7 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                color: "#FFF",
                textAlign: "center",
              }}
            >
              <img
                src="https://s3-alpha-sig.figma.com/img/da40/1f26/dc3724b8a75b7e8a22741ffb0c7fe624?Expires=1743379200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pRbMncKDe3sOt3SCZNMxDWuATQH0U-XdeQUVBKKT2a2CPS2k1G6gp3sGTL5FFhE~A-GSwzVof39W80PQujBw8SISF53kDtkfkZ9BjajxO6RHSmykzKai~dgQad70OgM2ba1Rsu0vFKaYdUCskOEidjKFexpVhz6v7Se3pWK8dEAVTwAUNEqGkUGtsi0Qi6D1LuaV832WhDGTHHns7YkPGkKuLH1QkZX-6MQ5QU1IvSeM6aNZyTnPpIdhKZ2XNlTrevDhoMYbQo18DnNmeP2NVGDxkGtRCvn9Q67O6xABs6ld2nROJ~x7W5XA9i0RhQUIwpquvlZh95rJlDOQn-fJXg__"
                alt="Default"
                style={{ width: "350px", height: "200px" }}
              />
              <p
                style={{
                  fontSize: "45px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  marginBottom: "15px",
                  color: "#0F0718",
                  marginBottom: 0,
                }}
              >
                Thank You!
              </p>
              <p
                style={{
                  fontSize: "30px",
                  fontFamily: "Inter",
                  color: "#0F0718",
                }}
              >
                Your order is placed successfully
              </p>
            </div>
          ) : layoutType === 8 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                textAlign: "center",
                position: "relative",
                bottom: "15%",
              }}
            >
              <img
                src="https://s3-alpha-sig.figma.com/img/ff40/54ba/8c36716f9a38f01e1c667d4cf8e883c4?Expires=1743379200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=NR~VtAxzZ~192EQZwbBFgvOY2D69Bq880VLgVW51cqPFqog8toC9Ipo8R3c~xqAMXn1vzcqTgdVbszI-lUqBp-g-t13mT8GflT2fO5xM-AqOQ~jchzV2XW45IL7HsnuVODmz5DZbPddQM4OkCUrtAe0FPXMGuOiPU5zjW7r5kkuMaJ4Wu6Y~7hzjrd3ZDPiDK-lYEXzVZJ5ikUC0x5WCRlUyKe6oRUx1aAmjNAPscRdmZ8gsG~110pUueeCcyIdZE~0CZKpu67lIuZXWOSijrRPNPvNsWDqGJLGKSfyGcc3PATMTS-lnO1qpxmd8xHN9aOczvdP9jBMYIpj~uqGdZA__"
                alt="Default"
                style={{
                  // width: "30%",
                  height: "auto",
                  display: "block",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "13%",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "25px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#0F0718",
                    marginTop: "0",
                    marginBottom: "10px",
                  }}
                >
                  Processing your payment.
                </p>
                <p
                  style={{
                    fontSize: "25px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#0F0718",
                    marginTop: "0",
                  }}
                >
                  This may take a moment
                </p>
              </div>
            </div>
          ) : layoutType === 9 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                color: "#FFF",
                textAlign: "center",
                marginBottom: "4vh",
              }}
            >
              <img
                src="https://s3-alpha-sig.figma.com/img/e11c/0df3/9dddbbabae045b1f4936456ebd898106?Expires=1743379200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=BGWA7mC6AlIbxyU9Lvk9e6dIdwIPcFckfIxs6Wlp98e2xfP6frkXWSpXYt72pZJ5huKPmp2AMETN7dDde8F5wNOCM~QmxTuDQJh2YtP6dVGMBDdBSpB63pmehjzJJQtaCgDo7qMzNzS1r9dqFf5sqd3-7mvDrar6gaBzetfqFhbtmsbj63gHwPWRQ~edWSNtUMav4gfU9RwumQPX-qaoQt~wxwi7sv0p-2qGwEWGLkGxSAGFiCFqnONZ1S2QJV52ZC-ZShZFb1o6CZUBuK3FpRzDUoOH4j62afcx86HLiIDSc6iZAGjlXartMWyxElDyRLdEonfQHOu4Fvzc9U-fcQ__"
                alt="Default"
                style={{ width: "100px", height: "100px" }}
              />
              <p
                style={{
                  fontSize: "25px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  marginBottom: "10px",
                  color: "#0F0718",
                  marginBottom: 0,
                }}
              >
                Your payment request has timed out.
              </p>
              <p
                style={{
                  fontSize: "25px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  marginBottom: "10px",
                  color: "#0F0718",
                  marginBottom: 0,
                }}
              >
                Please try again
              </p>
              <Button
                style={{
                  backgroundColor: "#2F3856",
                  borderRadius: "20px",
                  color: "#fff",
                  fontSize: "1em",
                  marginTop: "2vh",
                  // width: "10vw",
                  width: "auto",
                  height: "6vh",
                }}
                onClick={() => {
                  setSelectedPaymentMethod({});
                  localStorage.setItem("kioskUI", 0);
                  setKioskUI(0);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 0 },
                  });
                  window.dispatchEvent(customEvent);
                }}
              >
                Try Again
              </Button>
            </div>
          ) : layoutType === 10 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                color: "#FFF",
                textAlign: "center",
              }}
            >
              <img
                src="https://s3-alpha-sig.figma.com/img/ff40/54ba/8c36716f9a38f01e1c667d4cf8e883c4?Expires=1743379200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=NR~VtAxzZ~192EQZwbBFgvOY2D69Bq880VLgVW51cqPFqog8toC9Ipo8R3c~xqAMXn1vzcqTgdVbszI-lUqBp-g-t13mT8GflT2fO5xM-AqOQ~jchzV2XW45IL7HsnuVODmz5DZbPddQM4OkCUrtAe0FPXMGuOiPU5zjW7r5kkuMaJ4Wu6Y~7hzjrd3ZDPiDK-lYEXzVZJ5ikUC0x5WCRlUyKe6oRUx1aAmjNAPscRdmZ8gsG~110pUueeCcyIdZE~0CZKpu67lIuZXWOSijrRPNPvNsWDqGJLGKSfyGcc3PATMTS-lnO1qpxmd8xHN9aOczvdP9jBMYIpj~uqGdZA__"
                alt="Default"
                style={{ width: "350px", height: "200px" }}
              />
              <p
                style={{
                  fontSize: "45px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  marginBottom: "15px",
                  color: "#0F0718",
                  marginBottom: 0,
                }}
              >
                Your payment could not be completed. Please try again or use a different payment method
              </p>
              <Button
                style={{
                  backgroundColor: "#2F3856",
                  borderRadius: "10px",
                  color: "#fff",
                  fontSize: "1em",
                }}
              >
                Try Again
              </Button>
            </div>
          ) : null}

          {layoutType === 0 ? (
            // <div className="left-bottom-images">
            //   <p style={{ fontSize: "2.15vw", color: "#fff", margin: "0", fontWeight: "500" }}>
            //     Total Amount To Pay {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
            //     &nbsp;
            //     {parseFloat(cart.total).toFixed(2)}
            //     &nbsp;
            //     {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
            //   </p>
            // </div>
            <div className="left-bottom-images">
              {/* <footer style={styles.footer}>
               <Button
                style={{ width: "94%", height: "5.5vh", backgroundColor: "#2F3856", color: "#fff", borderRadius: "5px", fontSize: "2vh", fontWeight: 500 }}
                onClick={() => {
                  requestPayment(selectedPaymentMethod, cart.total);
                  openPaymentModalByCustomer();
                  const waitForCardPaymentToComplete = () => {
                    setTimeout(() => {
                      if (!isCardPaymentLoadingRef.current) {
                        // Only proceed if loading is false
                        setLayoutType(1);
                        localStorage.setItem("layoutType", 1);
                        const customEvent1 = new CustomEvent("customStorageChange", {
                          detail: { key: "layoutType", newValue: 1 },
                        });
                        window.dispatchEvent(customEvent1);
                        const customEvent = new CustomEvent("customStorageChange", {
                          detail: { key: "kioskUI", newValue: 0 },
                        });
                        window.dispatchEvent(customEvent);
                      } else {
                        waitForCardPaymentToComplete(); // Keep checking if still loading
                      }
                    }, 500); // Check every 500ms
                  };

                  waitForCardPaymentToComplete();
                }}
              >
                Pay Now
              </Button>
            </footer> */}
              <footer style={styles.footer}>
                <div style={{ display: "flex", width: "94%", gap: "10px" }}>
                  {/* <Button
                      style={{
                        width: "50%",
                        height: "5.5vh",
                        // backgroundColor: "#2F3856",
                        backgroundColor: themeJSON.orderNoTextColor,
                        color: "#fff",
                        borderRadius: "5px",
                        fontSize: "2vh",
                        fontWeight: 500,
                      }}
                      onClick={() => {
                        if (!selectedPaymentMethod || (typeof selectedPaymentMethod === "object" && Object.keys(selectedPaymentMethod).length === 0) || cart.items.length === 0) {
                          message.warning("Please select a payment method before proceeding.");
                          return;
                        }
                        requestPayment(selectedPaymentMethod, cart.total);
                        openPaymentModalByCustomer();
                        const waitForCardPaymentToComplete = () => {
                          setTimeout(() => {
                            if (!isCardPaymentLoadingRef.current) {
                              if (posConfig.eBill === "Y") {
                                setLayoutType(1);
                                localStorage.setItem("layoutType", 1);
                                const customEvent1 = new CustomEvent("customStorageChange", {
                                  detail: { key: "layoutType", newValue: 1 },
                                });
                                window.dispatchEvent(customEvent1);
                                const customEvent = new CustomEvent("customStorageChange", {
                                  detail: { key: "kioskUI", newValue: 0 },
                                });
                                window.dispatchEvent(customEvent);
                              } else {
                                localStorage.setItem("kioskUI", 0);
                                setKioskUI(0);
                                const customEvent = new CustomEvent("customStorageChange", {
                                  detail: { key: "kioskUI", newValue: 0 },
                                });
                                window.dispatchEvent(customEvent);
                              }
                            } else {
                              waitForCardPaymentToComplete();
                            }
                          }, 500);
                        };
                        waitForCardPaymentToComplete();
                      }}
                    >
                      Pay Now
                    </Button> */}

                  <Button
                    style={{
                      width: "50%",
                      height: "5.5vh",
                      backgroundColor: themeJSON.orderNoTextColor,
                      color: "#fff",
                      borderRadius: "5px",
                      fontSize: "2vh",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      if (!selectedPaymentMethod || (typeof selectedPaymentMethod === "object" && Object.keys(selectedPaymentMethod).length === 0) || cart.items.length === 0) {
                        message.warning("Please select a payment method before proceeding.");
                        return;
                      }

                      setLayoutType(8);
                      localStorage.setItem("layoutType", 8);
                      const customEvent = new CustomEvent("customStorageChange", {
                        detail: { key: "layoutType", newValue: 8 },
                      });
                      window.dispatchEvent(customEvent);

                      requestPayment(selectedPaymentMethod, cart.total);
                      openPaymentModalByCustomer();

                      const waitForCardPaymentToComplete = () => {
                        setTimeout(() => {
                          if (!isCardPaymentLoadingRef.current) {
                            // Show the "Thank You" screen
                            // setShowFinal(true);
                            // setLayoutType(7);

                            setTimeout(() => {
                              // After 5 seconds, move to the next layout
                              setShowFinal(false);
                              if (localStorage.getItem("layoutType") === "9") {
                                deleteReceipt();
                                return;
                              }

                              if (posConfig.eBill === "Y") {
                                setLayoutType(1);
                                localStorage.setItem("layoutType", 1);

                                const customEvent1 = new CustomEvent("customStorageChange", {
                                  detail: { key: "layoutType", newValue: 1 },
                                });
                                window.dispatchEvent(customEvent1);
                              } else {
                                setKioskUI(0);
                                localStorage.setItem("kioskUI", 0);

                                const customEvent = new CustomEvent("customStorageChange", {
                                  detail: { key: "kioskUI", newValue: 0 },
                                });
                                window.dispatchEvent(customEvent);
                              }
                            }, 5000);
                          } else {
                            waitForCardPaymentToComplete();
                          }
                        }, 500);
                      };

                      waitForCardPaymentToComplete();
                    }}
                  >
                    Pay Now
                  </Button>

                  <Button
                    style={{
                      width: "50%",
                      height: "5.5vh",
                      backgroundColor: "#fff",

                      color: "#2F3856",
                      borderRadius: "5px",
                      fontSize: "2vh",
                      fontWeight: 500,
                      border: "1px solid #2F3856",
                    }}
                    onClick={() => {
                      setSelectedPaymentMethod({});
                      setKioskUI(3);
                      const customEvent = new CustomEvent("customStorageChange", {
                        detail: { key: "kioskUI", newValue: 3 },
                      });
                      window.dispatchEvent(customEvent);
                      localStorage.setItem("kioskUI", 3);
                    }}
                  >
                    Cancel Payment
                  </Button>
                </div>
              </footer>
              {/* <div className="bottom-img">
                <img
                  src={backImg}
                  style={{ height: "2.7vw" }}
                  onClick={() => {
                    setKioskUI(3);
                    db.products
                      // .limit(10)
                      .toArray()
                      .then((productsFetched) => {
                        setProductsList([...productsFetched]);
                        setOriginalProductsList([...productsFetched]);
                        setSelectedCategory("All");
                      });
                    localStorage.setItem("layoutType", 1);
                    const customEvent1 = new CustomEvent("customStorageChange", {
                      detail: { key: "layoutType", newValue: 1 },
                    });
                    window.dispatchEvent(customEvent1);
                    localStorage.setItem("kioskUI", 3);
                    const customEvent = new CustomEvent("customStorageChange", {
                      detail: { key: "kioskUI", newValue: 3 },
                    });
                    window.dispatchEvent(customEvent);
                  }}
                />
                <p style={{ color: "#fff", margin: "0" }}>Back</p>
              </div> */}
            </div>
          ) : null}

          {/* {layoutType === 3 ? (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img src={backImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      ) : (
        <div className="left-bottom-images">
          <div className="bottom-img">
            <img
              src={backImg}
              style={{ height: "2.7vw" }}
              onClick={() => {
                setKioskUI(3);
                localStorage.setItem("layoutType", 1);
                const customEvent1 = new CustomEvent("customStorageChange", {
                  detail: { key: "layoutType", newValue: 1 },
                });
                window.dispatchEvent(customEvent1);
                localStorage.setItem("kioskUI", 3);
                const customEvent = new CustomEvent("customStorageChange", {
                  detail: { key: "kioskUI", newValue: 3 },
                });
                window.dispatchEvent(customEvent);
              }}
            />
            <p style={{ color: "#fff", margin: "0" }}>Back</p>
          </div>
          <div className="bottom-img">
            <img src={volumeImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Volume</p>
          </div>
          <div className="bottom-img">
            <img src={wheelchairImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
          </div>
          <div className="bottom-img">
            <img src={helpImg} style={{ height: "2.7vw" }} />
            <p style={{ color: "#fff", margin: "0" }}>Help</p>
          </div>
        </div>
      )} */}

          {layoutType === 3 ? (
            <div className="left-bottom-images">
              <div className="bottom-img">
                <img src={backImg} style={{ height: "2.7vw" }} />
                <p style={{ color: "#fff", margin: "0" }}>Back</p>
              </div>
              <div className="bottom-img">
                <img src={helpImg} style={{ height: "2.7vw" }} />
                <p style={{ color: "#fff", margin: "0" }}>Help</p>
              </div>
            </div>
          ) : layoutType === 0 ? (
            <div className="left-bottom-images">
              {/* <div className="bottom-img">
              <img
                src={backImg}
                style={{ height: "2.7vw" }}
                onClick={() => {
                  setKioskUI(3);
                  db.products
                    // .limit(10)
                    .toArray()
                    .then((productsFetched) => {
                      setProductsList([...productsFetched]);
                      setOriginalProductsList([...productsFetched]);
                      setSelectedCategory("All");
                    });
                  localStorage.setItem("layoutType", 1);
                  const customEvent1 = new CustomEvent("customStorageChange", {
                    detail: { key: "layoutType", newValue: 1 },
                  });
                  window.dispatchEvent(customEvent1);
                  localStorage.setItem("kioskUI", 3);
                  const customEvent = new CustomEvent("customStorageChange", {
                    detail: { key: "kioskUI", newValue: 3 },
                  });
                  window.dispatchEvent(customEvent);
                }}
              />
              <p style={{ color: "#fff", margin: "0" }}>Back</p>
            </div> */}
              {/* <div className="bottom-img">
              <img src={volumeImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Volume</p>
            </div>
            <div className="bottom-img">
              <img src={wheelchairImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
            </div> */}
              {/* <div className="bottom-img">
              <img src={helpImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Help</p>
            </div> */}
            </div>
          ) : layoutType === 1 || layoutType === 5 || layoutType === 4 ? (
            <div className="left-bottom-images">
              {/* <div className="bottom-img">
              <img src={volumeImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Volume</p>
            </div>
            <div className="bottom-img">
              <img src={wheelchairImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
            </div> */}
              {/* <div className="bottom-img">
              <img src={helpImg} style={{ height: "2.7vw" }} />
              <p style={{ color: "#fff", margin: "0" }}>Help</p>
            </div> */}
            </div>
          ) : null}
        </div>
        // </Spin>
      )}
    </>
  );
};

export default PaymentSelection;
