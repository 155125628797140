import Axios from "axios";

const printToken = (vendor, documentNo, orderDate) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let tokenTemplate = tillData.tillAccess.cwrTill.kotPrintTemplate.xmlcode2 ? JSON.parse(tillData.tillAccess.cwrTill.kotPrintTemplate.xmlcode2) : {};

  // Process the token template with vendor data
  const processedToken = processTokenTemplate(tokenTemplate, {
    productionCenter: vendor.productionCenter,
    token: vendor.token,
    items: vendor.items,
    documentNo: documentNo,
    orderDate: orderDate,
  });

  const decodedData = JSON.stringify(processedToken, null, 2);

  // Send to printer
  Axios.post(`${printerURL}printer`, decodedData, {
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(() => {
      console.log(`Token printed successfully for ${vendor.productionCenter} (Token: ${vendor.token})`);
    })
    .catch((error) => {
      console.error(`Failed to print token for ${vendor.productionCenter}:`, error);
    });
};

const replacePlaceholders = (text, data) => {
  return text.replace(/{{([^}]+)}}/g, (match, placeholder) => {
    // Handle currentDateTime
    if (placeholder === "currentDateTime") {
      return new Date().toLocaleString();
    }

    // Get the value from the data object
    let value = getDeepValue(data, placeholder);

    // Format numbers correctly
    if (typeof value === "number") {
      return value === 0 ? "0.00" : value.toFixed(2);
    }

    // Handle expressions like {{grossTotal + grossReturnTotal}}
    if (placeholder.includes("+") || placeholder.includes("-") || placeholder.includes("*") || placeholder.includes("/")) {
      try {
        const resolvedExpression = placeholder.replace(/\b\w+\b/g, (varName) => {
          const val = getDeepValue(data, varName);
          return typeof val === "number" ? val : 0;
        });
        const result = eval(resolvedExpression);
        return result === 0 ? "0.00" : result.toFixed(2);
      } catch (error) {
        console.error("Error evaluating expression:", placeholder, error);
        return "0.00";
      }
    }

    return value !== null && value !== undefined ? value : "";
  });
};

const getDeepValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : null), obj);
};

const formatValue = (value, format) => {
  if (format === "currency" && typeof value === "number") {
    return value.toFixed(2);
  }
  return value;
};

const processTokenTemplate = (template, data) => {
  const processedTemplate = JSON.parse(JSON.stringify(template));

  processedTemplate.elements = processedTemplate.elements.flatMap((element) => {
    // Process text elements
    if (element.type === "text" && element.content && typeof element.content === "string") {
      element.content = replacePlaceholders(element.content, data);
    }

    // Process loop elements
    if (element.type === "loop" && element.data && typeof element.data === "string") {
      const loopDataPath = element.data.replace(/{{|}}/g, "");
      const loopData = getDeepValue(data, loopDataPath) || [];
      if (Array.isArray(loopData)) {
        return loopData.flatMap((loopItem) => {
          return element.elements.map((loopElement) => {
            const newElement = JSON.parse(JSON.stringify(loopElement));
            if (newElement.type === "text" && newElement.content && typeof newElement.content === "string") {
              newElement.content = replacePlaceholders(newElement.content, loopItem);
            }
            return newElement;
          });
        });
      }
      return []; // Return empty array if loop data is not an array
    }

    // Process table elements
    if (element.type === "table" && element.data && typeof element.data === "string" && element.dataMapping) {
      const dataPath = element.data.replace(/{{|}}/g, ""); // e.g., "items"
      const tableData = getDeepValue(data, dataPath) || [];

      const columnKeys = element.columns.map((col, idx) => ({
        key: Object.keys(element.dataMapping).find((k) => element.dataMapping[k] === Object.values(element.dataMapping)[idx]),
        width: col.width,
        alignment: col.alignment,
        format: col.format
      }));

      element.data = tableData.flatMap((item) => {
        // Main row
        const mainRow = {};
        for (const targetKey in element.dataMapping) {
          mainRow[targetKey] = item[element.dataMapping[targetKey]];
        }

        // Extra rows based on extraRows configuration
        const extraRows = (element.extraRows || []).flatMap((extra) => {
          // Handle multiple fields in a single row
          if (extra.fields && Array.isArray(extra.fields)) {
            const allHidden = extra.fields.every((fieldConfig) => {
              const value = item[fieldConfig.field];
              return fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
            });

            if (allHidden) {
              return []; // Skip entirely if all fields are hidden
            }

            const extraRow = {};
            let hasContent = false;
            columnKeys.forEach((col) => {
              const fieldConfig = extra.fields.find(f => f.column === col.key);
              if (fieldConfig) {
                const value = item[fieldConfig.field];
                const shouldHide = fieldConfig.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
                if (!shouldHide && value !== null && value !== undefined) {
                  extraRow[col.key] = fieldConfig.format ? formatValue(value, fieldConfig.format) : value;
                  hasContent = true;
                } else {
                  extraRow[col.key] = "";
                }
              } else {
                extraRow[col.key] = "";
              }
            });

            return hasContent ? [extraRow] : []; // Only include if there's actual content
          }

          // Handle single field (e.g., name2)
          const value = item[extra.field];
          const shouldHide = extra.hideIfZeroOrEmpty && (value === null || value === undefined || value === 0);
          if (!shouldHide && value !== null && value !== undefined) {
            const extraRow = {};
            columnKeys.forEach((col) => {
              extraRow[col.key] = col.key === extra.column ? value : "";
              if (extra.format && col.key === extra.column) {
                extraRow[col.key] = formatValue(value, extra.format);
              }
            });
            return [extraRow];
          }
          return [];
        });

        return [mainRow, ...extraRows];
      }).filter(row => {
        // Final filter to remove any fully empty rows
        return Object.values(row).some(val => val !== "" && val !== null && val !== undefined);
      });
    }

    // Process barcode elements
    if (element.type === "barcode" && element.content) {
      element.content = replacePlaceholders(element.content, data);
    }

    // Process QR elements
    if (element.type === "qrcode" && element.content) {
      element.content = replacePlaceholders(element.content, data);
    }

    // Handle spacing and line elements
    if (element.type === "spacing" || element.type === "line") {
      return element;
    }

    return element;
  });

  return processedTemplate;
};

export default printToken;