import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Progress, Table, Badge, message } from "antd";
import { io } from "socket.io-client";
import POSLogo from "../../assets/images/NewLogoCW.svg";
import LockIcon from "../../assets/images/lock-icon.svg";
import sideMenu from "../../assets/images/side-menu.svg";
import userIcon from "../../assets/images/userId.svg";
import Online from "../../assets/images/green.svg";
import Offline from "../../assets/images/red.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import ScanIcon from "../../assets/images/scanIcon.svg";
import FilterIcon from "../../assets/images/filterNewICon.svg";
import Swiggy from "../../assets/images/swiggy1.svg";
import Zomato from "../../assets/images/zomato.svg";
import SwiggyBolt from "../../assets/images/swiggyBolt.svg";
import RejectIcon from "../../assets/images/Reject.svg";
import AcceptIcon from "../../assets/images/AcceptIcon.svg";
import DeleteProduct from "../../assets/images/delete1.svg";
import NotesImage from "../../assets/images/notes.svg";
import Axios from "axios";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import socket from "../../socket";
import BackIcon from "../../assets/images/backIcon.svg"


const Aggregator = () => {
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("All Channels");
  const [timer, setTimer] = useState(30);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false); // New flag to track status update
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrdersState, setFilteredOrdersState] = useState([]);
  const serverUrl = process.env.REACT_APP_serverUrl;
  const authHeaders = getOAuthHeaders();
  const statusDisplayMapping = {
    NEW: "New",
    AK: "Acknowledged",
    FR: "Food Ready",
    DI: "Dispatched",
    CC: "Cancelled",
  };
  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  })}`;

  // Update date-time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Fetch initial sales history on page load
  useEffect(() => {
    getSalesHistoryData();
  }, []);

  // Update filtered orders when searchValue or orders change
  useEffect(() => {
    if (searchValue) {
      const filtered = orders.filter((order) =>
        order.orderNo.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredOrdersState(filtered);
    } else {
      setFilteredOrdersState(orders);
    }
  }, [searchValue, orders]);

  // WebSocket Connection and Order Updates
  useEffect(() => {
   if(socket){
    socket.on("connect", () => {
      console.log("✅ Connected to WebSocket Server:", socket.id);
    });

    socket.on("order_update", (orderData) => {
      console.log("📦 New Order Update:", orderData);
      getSalesHistoryData();
      if (selectedOrder && selectedOrder.sOrderID === orderData.orderNo) {
        getSalesHistoryDetails(orderData.orderNo, orderData.status);
      }
    });

    socket.on("connect_error", (err) => {
      console.error("❌ WebSocket Connection Error:", err.message);
    });

    return () => {
      socket.off("connect");
      socket.off("order_update");
      socket.off("connect_error");
    };
   }
  }, [selectedOrder]);

  // Timer logic for NEW orders
  useEffect(() => {
    let countdown;
    if (selectedOrder && selectedOrder.status === "NEW" && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            updateOrderStatus(selectedOrder.sOrderID, "Acknowledged", true); // Auto-accept
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (countdown) clearInterval(countdown);
    };
  }, [selectedOrder, timer]);

  // Reset timer when selectedOrder changes to a NEW order
  useEffect(() => {
    if (selectedOrder && selectedOrder.status === "NEW") {
      setTimer(30); // Reset timer to 30s for NEW orders
    }
  }, [selectedOrder?.sOrderID]);

  // Fetch Sales History Data (for left-side list)
  const getSalesHistoryData = async () => {
    let setAuthTokens = authHeaders.access_token;
    const tillValue = JSON.parse(localStorage.getItem("tillValue"));
    const sortOptions = {
      documentno: "desc",
      dateordered: "desc",
      customer: null,
      totalAmount: null,
    };
    const filteredSortOptions = Object.fromEntries(Object.entries(sortOptions).filter(([key, value]) => value !== null));
    const sortData = JSON.stringify(filteredSortOptions).replace(/"/g, '\\"');
    setLoading(true);
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {salesHistory(
                      q: null,
                      filter_by: null,
                      startRow: "0",
                      endRow: "50",
                      sort_by: "${sortData}",
                      tillId: "${tillValue.cwr_till_id}"
                    ) {
                    sOrderID
                    documentno
                    dateordered
                    totalQty
                    orderTime
                    grosstotal
                    layAway
                    isReturn
                    ofdStatus
                    saleType {
                        cwrSaletypeId
                        name
                        value
                      }
                    cwrB2cCustomer {
                      name
                      }
                    cwrTill {
                      till
                    }
                  }         
                  }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const salesDataArray = response?.data?.data?.salesHistory || [];
      if (salesDataArray.length > 0) {
        const newOrders = salesDataArray
          .filter((salesData) => salesData.ofdStatus === "NEW" || salesData.ofdStatus === "AK" || salesData.ofdStatus === "FR") // Exclude Cancelled orders
          .map((salesData) => ({
            sOrderID: salesData.sOrderID,
            orderNo: salesData.documentno,
            orderTime: salesData.orderTime,
            channel: salesData.saleType?.name || "Unknown",
            status: salesData.ofdStatus || "NEW", // Source of truth for ofdStatus
            amount: salesData.grosstotal,
          }));
        setOrders(newOrders);
        setFilteredOrdersState(newOrders);
        if (!selectedOrder && newOrders.length > 0) {
          getSalesHistoryDetails(newOrders[0].sOrderID, newOrders[0].status);
        } else if (selectedOrder && !isStatusUpdating) { // Skip update if status is being updated
          const updatedOrder = newOrders.find((order) => order.sOrderID === selectedOrder.sOrderID);
          if (updatedOrder && updatedOrder.status !== selectedOrder.status) {
            setSelectedOrder((prev) => ({ ...prev, status: updatedOrder.status }));
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order history:", error);
      setLoading(false);
    }
  };

  // Fetch Sales History Details (for right-side details)
  const getSalesHistoryDetails = async (order_id, selectedOrderStatus) => {
    let setAuthTokens = authHeaders.access_token;
    setLoading(true);
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {salesHistoryDetails(
                      orderId: "${order_id}"
                    ) {
                      sOrderID
                      created
                      documentno
                      dateordered
                      cwrProductQty
                      orderTime
                      taxamt
                      grosstotal
                      discAmount
                      layAway
                      isReturn
                      paid
                      ofdStatus
                      csUser { user }
                      csBUnit { csBunitId name }
                      csbUnitLocation { fulladdress }
                      cwrB2cCustomer { cwrCustomerId code name mobileNo pincode email }
                      saleType { cwrSaletypeId name value }
                      cwrTill { cwrTillID till }
                      finReceiptPlan {
                        finReceiptPlanDetails {
                          amount
                          cwrPaymentmethod { cWRPaymentMethodID finPaymentmethodId integratedPayment paymentProvider }
                        }
                      }
                      line {
                        sOrderlineID
                        description
                        qty
                        netunit
                        linetax
                        unitprice
                        linegross
                        discount
                        product { mProductId name value upc }
                        uom { csUomId name }
                        tax { csTaxID name rate }
                      }
                    }
                  }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const orderData = response?.data?.data?.salesHistoryDetails?.[0];
      if (orderData) {
        const paymentMethods = orderData.finReceiptPlan?.[0]?.finReceiptPlanDetails?.map((detail) => ({
          name: detail.cwrPaymentmethod.paymentProvider || "Unknown",
          amount: detail.amount,
        })) || [];

        const lineItems = orderData.line.map((item) => ({
          name: item.product.name,
          quantity: item.qty,
          discount: item.discount || "0.00",
          tax: item.linetax || "0.00",
          price: item.linegross,
        }));
        const currentOrderInList = orders.find((order) => order.sOrderID === orderData.sOrderID);
        const detailedOrder = {
          sOrderID: orderData.sOrderID,
          orderNo: orderData.documentno,
          channel: orderData.saleType?.name || "Unknown",
          status: selectedOrderStatus || orderData.ofdStatus || "NEW", // Prioritize salesHistory status
          amount: orderData.grosstotal,
          paymentMode: paymentMethods.length > 0 ? paymentMethods[0].name : "Unknown",
          eta: "30 Min",
          customerName: orderData.cwrB2cCustomer?.name || "Unknown",
          customerPhone: orderData.cwrB2cCustomer?.mobileNo || "N/A",
          customerEmail: orderData.cwrB2cCustomer?.email || "N/A",
          customerAddress: orderData.csbUnitLocation?.fulladdress || "N/A",
          items: lineItems,
          orderTime: orderData.orderTime,
          tax: orderData.taxamt,
          discount: orderData.discAmount,
          paid: orderData.paid,
        };

        setSelectedOrder(detailedOrder);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setLoading(false);
    }
  };

  // Update Order Status
  const updateOrderStatus = async (sOrderID, newStatus, isAuto = false) => {
    let setAuthTokens = authHeaders.access_token;
    const statusMapping = {
      Acknowledged: "AK",
      Cancelled: "CC",
      "Food Ready": "FR",
      Dispatched: "DI",
    };
    const statusCode = statusMapping[newStatus] || newStatus;
    setLoading(true);
    setIsStatusUpdating(true); // Set flag before update
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            upsertOfdOrderStatus(order: [{
              sOrderID: "${sOrderID}"
              ofdStatus: "${statusCode}"
            }]) { 
              status
              message   
            }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const result = response?.data?.data?.upsertOfdOrderStatus;
      if (result?.status === "200") {
        if (isAuto) {
          message.success("Order automatically accepted after timeout!");
        } else {
          message.success(result?.message);
        }
        await getSalesHistoryData(); // Refresh order list with latest status

        // Update selectedOrder status manually without changing other details
        if (selectedOrder?.sOrderID === sOrderID) {
          setSelectedOrder((prev) => ({ ...prev, status: statusCode }));
          // Optionally refresh details if needed, but status is already updated
          await getSalesHistoryDetails(sOrderID, statusCode);
        }

        if (statusCode === "CC" && selectedOrder?.sOrderID === sOrderID) {
          setSelectedOrder(null); // Deselect if rejected
        }
      } else {
        console.error("Status update failed:", result?.message);
        message.error("Failed to update order status.");
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      message.error("Error updating order status.");
    } finally {
      setLoading(false);
      setIsStatusUpdating(false); // Reset flag after update
    }
  };

  const syncOrder = async (order) => {
    let setAuthTokens = authHeaders.access_token;
    const precision = tillData?.tillAccess?.csBunit?.currencies[0]?.prcPrecision || 2;
    const orderJson = {
      sOrderID: order.sOrderID,
      cSClientID: tillData.tillAccess.csClientId,
      cSBunitID: tillData.tillAccess.csBunit.csBunitId,
      created: order.orderTime,
      createdby: order.createdBy,
      updated: order.orderTime,
      updatedby: order.createdBy,
      csDoctypeId: tillData.tillAccess.csBunit.cwrCsDoctypeId,
      sCustomerId: tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID, // Default B2C customer
      sCustomerBillingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
      sCustomerShippingId: tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID,
      sPricelistId: tillData.tillAccess.csBunit.cwrSpricelistId,
      documentno: order.orderNo,
      dateordered: order.orderTime,
      datepromised: order.orderTime,
      csPaymenttermID: null,
      finPaymentmethodId: order.paymentMode ? order.paymentMode : null, // Assuming paymentMode from details
      csCurrencyId: tillData?.tillAccess?.csBunit?.currencies[0]?.csCurrencyId || "",
      mWarehouseId: tillData.tillAccess.csBunit.mWarehouse.mWarehouseID,
      cwrLongitude: "",
      cwrLatitude: "",
      csUserId: tillData.tillAccess.csUserId,
      cwrB2cCustomerId: order.customer?.cwrCustomerId ? order.customer?.cwrCustomerId : order.customer?.b2cCustomerId,
      orderreference: "",
      cwrPayref: "",
      cwrPayremarks: "",
      description: order?.description || "",
      storeDailyOpsTillid: localStorage.getItem("storeDailyOpsTillid"),
      cwrTillId: tillData.tillAccess.cwrTill.cwrTillID,
      redemption: 0, // Assuming no loyalty
      accumulation: 0,
      redeemRefId: null,
      roundoff: parseFloat(order?.roundOff?.toFixed(precision)) || 0,
      cwrProductQty: order?.totalQty || 0,
      cwrProductCount: order?.totalQty || 0,
      ofdStatus: "Dispatched", // Set as Dispatched for completion
      ofdIspaid: "Y",
      posLoyaltyCalculation: posConfig?.disablePOSLoyaltyCalculation,
      mPricingruleId: order?.mPricingruleId ?? null,
      cwrSaletypeId: order.channel === "Swiggy" ? "SWIGGY" : "ZOMATO" ? "ZOMATO" : "", // Example mapping
      salesRepId: order?.salesRepId || null,
      discAmount: parseFloat(order.discount?.toFixed(precision)) || 0,
      creditAmount: order?.creditAmount, // Assuming no credit
      metaData: {},
      giftCard: [],
      pricingCoupon: [
        {
          mPricingCouponId: null,
          redemptionCount: null,
          referenceId: null,
        },
      ],
      orderTotal: parseFloat(order.amount?.toFixed(precision)) || 0,
      nettotal: parseFloat((order.amount - order.tax)?.toFixed(2)) || 0,
      taxamt: parseFloat(order.tax?.toFixed(2)) || 0,
      isReturn: "N", // Assuming no returns
      sOrderReturnId: order?.items?.[0]?.sOrderReturnId || null,
      layAway: order.layAway || null,
      payments: order.paymentMode ? [{ paymentMethod: order.paymentMode, amount: order.amount }] : [],
      tax: order.tax ? [{ amount: order.tax }] : [],
      line: order.items?.map((item) => ({
        productName: item.name,
        qty: item.quantity,
        price: item.price,
        discount: item.discount,
        tax: item.tax,
      })) || [],
    };

    const stringifiedData = JSON.stringify(orderJson);
    const newStringRequest = JSON.stringify(stringifiedData.replace(/\\"/g, '\\"'));

    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
            posOrderProcessor1(
              tillId: "${tillData.tillAccess.cwrTill.cwrTillID}", 
              orderId: "${order.sOrderID}", 
              documentNo: "${order.orderNo}", 
              orderJson: ${newStringRequest}
            ) {
              documentno
              status
              message
            }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });

      const result = response?.data?.data?.posOrderProcessor1;
      if (result?.status === "200") {
        message.success(`Order ${result.documentno} synced successfully!`);
      } else {
        console.error("Sync failed:", result?.message);
        message.error("Failed to sync order to backend.");
      }
    } catch (error) {
      console.error("Error syncing order:", error);
      message.error("Error syncing order to backend.");
    }
  };

  // Filter orders by status and channel, excluding Cancelled (CC)
  const filteredOrders = selectedChannel === "All Channels"
    ? filteredOrdersState
    : filteredOrdersState.filter((order) => order.channel.toLowerCase() === selectedChannel.toLowerCase());

  const newOrders = filteredOrders.filter((order) => order.status === "NEW");
  const preparingOrders = filteredOrders.filter((order) => order.status === "AK"); // Acknowledged
  const dispatchedOrders = filteredOrders.filter((order) => order.status === "FR"); // Food Ready

  // Table data for selected order
  const getTableData = (order) => {
    if (!order || !order.items) return [];
    return order.items.map((item, index) => ({
      key: index.toString(),
      product: item.name,
      grossstd: item.quantity,
      qty: item.discount || "0.00",
      remainingQty: item.tax || "0.00",
      qtyToReturn: `₹${item.price}`,
      netValue: `₹${item.price}`,
    }));
  };

  // Handle order selection
  const handleOrderClick = (order) => {
    getSalesHistoryDetails(order.sOrderID, order.status);
  };

  // Handle channel selection
  const handleChannelClick = (channel) => {
    setSelectedChannel(channel);
  };
  // Handle search input
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  // Handle status updates
  const handleAccept = () => {
    if (selectedOrder) {
      updateOrderStatus(selectedOrder.sOrderID, "Acknowledged");
    }
  };

  const handleReject = () => {
    if (selectedOrder) {
      updateOrderStatus(selectedOrder.sOrderID, "Cancelled");
    }
  };

  const handleMarkAsReady = () => {
    if (selectedOrder) {
      updateOrderStatus(selectedOrder.sOrderID, "Food Ready");
    }
  };

  const handleComplete = async () => {
    if (selectedOrder) {
      await updateOrderStatus(selectedOrder.sOrderID, "Dispatched"); // Update status first
      await syncOrder(selectedOrder); // Then sync to backend
    }
  };

  // Inside the Aggregator component, before the return statement
  const getUniqueChannels = () => {
    const channels = [...new Set(orders.map((order) => order.channel))]; // Get unique channels
    return channels.filter((channel) => channel && channel !== "Unknown"); // Filter out undefined or "Unknown"
  };

  // Map channel names to their icons
  const channelIcons = {
    swiggy: Swiggy,
    zomato: Zomato,
    SwiggyBolt: SwiggyBolt, // Add more mappings as needed
  };

  // Render buttons based on status
  const renderButtons = () => {
    if (!selectedOrder) return null;

    switch (selectedOrder.status) {
      case "NEW":
        return (
          <>
            <Button
              onClick={handleReject}
              style={{ height: "5.5vh", color: "#2F3856", borderRadius: "7px", width: "20%", marginRight: "1vw", marginTop: "2vh" }}
              disabled={loading}
            >
              <img style={{ height: "2.5vh", marginRight: "0.4vw" }} src={RejectIcon} /> Reject
            </Button>
            <Button
              onClick={handleAccept}
              style={{ height: "5.5vh", backgroundColor: "#2F3856", color: "#fff", borderRadius: "7px", width: "20%", marginTop: "2vh" }}
              disabled={loading}
            >
              <img style={{ height: "2.5vh", marginRight: "0.4vw" }} src={AcceptIcon} /> Accept
            </Button>
          </>
        );
      case "AK": // Acknowledged
        return (
          <Button
            onClick={handleMarkAsReady}
            style={{ height: "5.5vh", backgroundColor: "#E99013", color: "#fff", borderRadius: "7px", width: "20%", marginTop: "2vh" }}
            disabled={loading}
          >
            Mark as Ready
          </Button>
        );
      case "FR": // Food Ready
        return (
          <Button
            onClick={handleComplete}
            style={{ height: "5.5vh", backgroundColor: "#008000", color: "#fff", borderRadius: "7px", width: "20%", marginTop: "2vh" }}
            disabled={loading}
          >
            Dispatch
          </Button>
        );
      case "DI": // Dispatched
        return null;
      default:
        return null; // No buttons for other statuses (e.g., CC)
    }
  };

  return (
    <div style={{ background: colorVariable || "#f3f4f9", height: "100vh", overflow: "hidden" }}>
      {/* Header */}
      <Row
        style={{
          padding: "0.5% 0",
          height: "7.5vh",
          alignItems: "center",
          fontSize: "1vw",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col
          onClick={() => window.history.back()}
          style={{ cursor: "pointer" }}
          span={1}
        >
          <img src={BackIcon} style={{ paddingLeft: "1rem", height: "2vw" }} />
        </Col>
        <Col
          span={3}
          onDoubleClick={() => (window.location.href = "/#/pos")}
          style={{ cursor: "pointer" }}
        >
          <img src={POSLogo} style={{ height: "2.5vw", paddingLeft: "0.4vw" }} />
        </Col>

        <Col
          span={9}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        ></Col>
        <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "1em",
              color: "#929098",
            }}
          >
            {" "}
            {formattedDateTime}
          </span>
        </Col>
        <Col
          span={5}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => {
              //   setIsLocked(true);
            }}
            src={LockIcon}
            style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
              padding: "0.5vw",
              border: "1px solid #3C424B",
              borderRadius: "50%",
            }}
          >
            <img src={userIcon} style={{ height: "2.7vh" }} />
          </div>
          <div
            style={{
              marginLeft: "1vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                color: "#0F0718",
                fontSize: "1em",
                fontFamily: "Inter",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              {userData.user}
            </span>
            <span
              style={{
                color: "#0F0718",
                fontSize: "0.8em",
                fontFamily: "Inter",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
            </span>
          </div>
          <div style={{ marginLeft: "3%" }}>
            <img
              src={Online}
              style={{
                display: !navigator.onLine && "none",
                height: "2.5vh",
                cursor: "pointer",
                paddingBottom: "0.5vh",
              }}
            />{" "}
            <img
              src={Offline}
              style={{
                display: navigator.onLine && "none",
                height: "2.5vh",
                cursor: "pointer",
                paddingBottom: "0.5vh",
              }}
            />{" "}
          </div>
        </Col>
      </Row>

      {/* Search and Filters */}
      <Row style={{ fontSize: "1vw", padding: "0 1.2vw", alignItems: "center" }}>
        <Col span={15}>
          <Input
            placeholder="Search for Orders by Document Number"
            id="sm-product-search"
            className="productSearch-sm"
            style={{
              borderRadius: "12px",
              padding: "0.8vh 0.8vw",
              borderColor: "#fff",
              height: "5.5vh",
              borderRadius: "7px",
            }}
            prefix={<img src={SearchIcon} alt="" />}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </Col>
        {/* <Button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "3%",
            margin: "0 0.2vw 0 0.8vw",
            borderRadius: "7px",
            height: "5.5vh",
            borderColor: "#fff",
          }}
        >
          <img src={ScanIcon} style={{ height: "2.7vh" }} />
        </Button> */}
      </Row>

      {/* Channel Buttons */}
      <Row style={{ marginTop: "1.2vh", fontSize: "1vw", padding: "0 1.2vw" }} gutter={[8, 8]}>
        <Col>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "8vw",
              borderRadius: "20px",
              color: "#fff",
              height: "5.5vh",
              backgroundColor: "#2F3856",
              fontWeight: 500,
              fontFamily: "Inter",
              padding: "0 1vw",
              fontSize: "1em",
            }}
            onClick={() => handleChannelClick("All Channels")}
          >
            All Channels
          </Button>
        </Col>
        {getUniqueChannels().map((channel) => (
          <Col key={channel}>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                borderRadius: "20px",
                height: "5.5vh",
                fontWeight: 500,
                fontFamily: "Inter",
                border: "none",
                backgroundColor: selectedChannel === channel ? "#2F3856" : "#fff",
                padding: "0 0.8vw",
                gap: "0.3vw",
                color: selectedChannel === channel ? "#fff" : "#929098",
                fontSize: "1em",
              }}
              onClick={() => handleChannelClick(channel)}
            >
              <img
                src={channelIcons[channel] || ""}
                style={{ height: channel === "Zomato" ? "3vh" : "4vh", width: "auto", objectFit: "contain" }}
              />
              {channel}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Orders Section */}
      <Row gutter={[16, 16]} style={{ marginTop: "1.5vh", fontSize: "1vw", padding: "0 1.2vw" }}>
        <Col span={7}>
          {/* New Orders */}
          <div style={{ height: "22.5vh", borderRadius: "7px", backgroundColor: "#fff", padding: "0 15px 0 10px", marginBottom: "1vh", overflow: "auto" }}>
            <Row style={{ color: "#0F0718", fontSize: "1em", fontWeight: 500, paddingTop: "1vh" }}>New Orders ({newOrders.length})</Row>
            {newOrders.map((order) => (
              <div key={order.orderNo} onClick={() => handleOrderClick(order)} style={{ cursor: "pointer" }}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={3}>
                    <img
                      style={{ height: "7vh", width: "4vw" }}
                      src={channelIcons[order.channel] || ""}
                    />
                  </Col>
                  <Col span={12} style={{ display: "flex", alignItems: "center", paddingLeft: "1vw" }}>
                    <div>
                      <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1.1em", marginBottom: 0 }}>{order.orderNo}</p>
                      <p style={{ fontWeight: 400, color: "#929098", fontSize: "0.8em", marginBottom: 0 }}>{order.orderTime}</p>
                    </div>
                  </Col>
                  <Col span={9} style={{ textAlign: "right" }}>
                    <Badge color="#4F23F1" />
                    <span style={{ fontSize: "1em", color: "#929098" }}>New</span>
                    <p style={{ fontWeight: 600, color: "#2F3856", fontSize: "1.25em", marginBottom: 0 }}>₹{order.amount}</p>
                  </Col>
                </Row>
                <hr style={{ width: "98%", color: "#C5C7C9", margin: "0.6vh" }} />
              </div>
            ))}
          </div>

          {/* Preparing Orders */}
          <div style={{ height: "22.5vh", borderRadius: "7px", backgroundColor: "#fff", padding: "0 15px 0 10px", marginBottom: "1vh", overflow: "auto" }}>
            <Row style={{ color: "#0F0718", fontSize: "1em", fontWeight: 500, paddingTop: "1vh" }}>Preparing ({preparingOrders.length})</Row>
            {preparingOrders.map((order) => (
              <div key={order.orderNo} onClick={() => handleOrderClick(order)} style={{ cursor: "pointer" }}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={3}>
                    <img
                      style={{ height: "7vh", width: "4vw" }}
                      src={channelIcons[order.channel] || ""}
                    />
                  </Col>
                  <Col span={12} style={{ display: "flex", alignItems: "center", paddingLeft: "1vw" }}>
                    <div>
                      <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1.1em", marginBottom: 0 }}>{order.orderNo}</p>
                      <p style={{ fontWeight: 400, color: "#929098", fontSize: "0.8em", marginBottom: 0 }}>{order.orderTime}</p>
                    </div>
                  </Col>
                  <Col span={9} style={{ textAlign: "right" }}>
                    <Badge color="#E99013" />
                    <span style={{ fontSize: "1em", color: "#929098" }}>Acknowledged</span>
                    <p style={{ fontWeight: 600, color: "#2F3856", fontSize: "1.25em", marginBottom: "0" }}>₹{order.amount}</p>
                  </Col>
                </Row>
                <hr style={{ width: "98%", color: "#C5C7C9", margin: "0.6vh" }} />
              </div>
            ))}
          </div>

          {/* Dispatched Orders */}
          <div style={{ height: "31.2vh", borderRadius: "7px", backgroundColor: "#fff", padding: "0 15px 0 10px", overflow: "auto" }}>
            <Row style={{ color: "#0F0718", fontSize: "1em", fontWeight: 500, paddingTop: "1vh" }}>Ready to Dispatch ({dispatchedOrders.length})</Row>
            {dispatchedOrders.map((order) => (
              <div key={order.orderNo} onClick={() => handleOrderClick(order)} style={{ cursor: "pointer" }}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={3}>
                    <img
                      style={{ height: "7vh", width: "4vw" }}
                      src={channelIcons[order.channel] || ""}
                    />
                  </Col>
                  <Col span={12} style={{ display: "flex", alignItems: "center", paddingLeft: "1vw" }}>
                    <div>
                      <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1.1em", marginBottom: 0 }}>{order.orderNo}</p>
                      <p style={{ fontWeight: 400, color: "#929098", fontSize: "0.8em", marginBottom: 0 }}>{order.orderTime}</p>
                    </div>
                  </Col>
                  <Col span={9} style={{ textAlign: "right" }}>
                    <Badge color="#008000" />
                    <span style={{ fontSize: "1em", color: "#929098" }}>Food Ready</span>
                    <p style={{ fontWeight: 600, color: "#2F3856", fontSize: "1.25em", marginBottom: 0 }}>₹{order.amount}</p>
                  </Col>
                </Row>
                <hr style={{ width: "98%", color: "#C5C7C9", margin: "0.6vh" }} />
              </div>
            ))}
          </div>
        </Col>

        {/* Order Details */}
        <Col span={17}>
          {selectedOrder ? (
            <div style={{ backgroundColor: "#fff", height: "70vh", borderRadius: "7px" }}>
              <Row style={{ padding: "2vh 1.2vw 0 1.2vw" }}>
                <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ height: "4.5vh" }}
                    src={selectedOrder.channel.toLowerCase() === "swiggy" ? SwiggyBolt : Zomato}
                  />
                  <p style={{ color: "#0F0718", fontSize: "1.5em", fontWeight: 600, marginBottom: 0, paddingLeft: "0.8vw" }}>
                    Order No: {selectedOrder.orderNo || "N/A"}
                  </p>
                </Col>
                <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  {selectedOrder.status === "NEW" && (
                    <>
                      <p style={{ marginBottom: 0, fontSize: "0.9em", color: "#0F0718", fontWeight: 400, paddingRight: "0.6vw" }}>
                        Accept Order ({timer}s)
                      </p>
                      <Progress
                        type="circle"
                        percent={(timer / 30) * 100}
                        width={45}
                        strokeWidth={7}
                        strokeColor="#FF5D0D"
                        format={() => (
                          <div style={{ textAlign: "center" }}>
                            <p style={{ margin: 0, fontSize: "1.2em", fontWeight: 600, color: "#FF5D0D" }}>{timer}</p>
                            <p style={{ margin: 0, fontSize: "0.7em", color: "#FF5D0D" }}>Sec</p>
                          </div>
                        )}
                      />
                    </>
                  )}
                </Col>
              </Row>

              <Row style={{ margin: "1vh 0 0 1.2vw" }}>
                <Col span={10}>
                  <Row>
                    <Col span={9} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>Channel</Col>
                    <Col span={12} style={{ marginLeft: "1.5vw", fontWeight: 500, fontSize: "1.1em", color: "#0F0718", fontFamily: "Inter" }}>
                      {selectedOrder.channel || "N/A"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={9} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>Status</Col>
                    <Col span={12} style={{ fontWeight: 500, fontSize: "0.8em", color: "#0F0718", fontFamily: "Inter", display: "flex", alignItems: "center", marginLeft: "1.5vw" }}
                    >
                      <p style={{ backgroundColor: "#4F23F1", color: "#fff", borderRadius: "3px", padding: "3px 8px 3px 8px", marginBottom: 0 }}>
                        {statusDisplayMapping[selectedOrder.status] || selectedOrder.status || "N/A"}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={9} style={{ fontWeight: "400", fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>Order Amount</Col>
                    <Col span={12} style={{ marginLeft: "1.5vw", fontWeight: 500, fontSize: "1.1em", color: "#0F0718", fontFamily: "Inter" }}>
                      ₹{selectedOrder.amount || "0"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={9} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>Tax</Col>
                    <Col span={12} style={{ marginLeft: "1.5vw", fontWeight: 500, fontSize: "1.1em", color: "#0F0718", fontFamily: "Inter" }}>
                      ₹{selectedOrder.tax || "0"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={9} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>Discount</Col>
                    <Col span={12} style={{ marginLeft: "1.5vw", fontWeight: 500, fontSize: "1.1em", color: "#0F0718", fontFamily: "Inter" }}>
                      ₹{selectedOrder.discount || "0"}
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={9} style={{ fontWeight: 500, fontSize: "1.1em", color: "#0F0718", fontFamily: "Inter" }}>
                      {selectedOrder.customerName || "N/A"}
                    </Col>
                    <Col span={12}>
                      <p style={{ color: "#FF5D0D", backgroundColor: "#FF5D0D47", fontSize: "0.8em", padding: "2px 5px", borderRadius: "5px", border: "1px solid #FF5D0D", marginBottom: "0" }}>
                        High Value Customer
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={12} style={{ fontWeight: 600, fontSize: "1.1em", color: "#2F3856", fontFamily: "Inter" }}>
                      {selectedOrder.customerPhone || "N/A"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={12} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>
                      {selectedOrder.customerEmail || "N/A"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={12} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>
                      {selectedOrder.customerAddress || "N/A"}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1vh" }}>
                    <Col span={12} style={{ fontWeight: 400, fontSize: "0.9em", color: "#0F0718", fontFamily: "Inter" }}>
                      {selectedOrder.orderTime || "N/A"}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Table
                className="sm-aggregator-table"
                style={{
                  margin: "2vh 1vw 0 1vw",
                  overflowY: "auto",
                  border: "none",
                  fontSize: "1vw",
                  borderRadius: "7px",
                  padding: "0px 7px",
                  border: "1px solid rgba(146, 144, 152, 0.4)",
                  height: "30vh",
                  background: "#fff",
                  overflow: "auto",
                }}
                id="sm-view-order-table"
                pagination={false}
                dataSource={getTableData(selectedOrder)}
                columns={[
                  { title: "Item Name", dataIndex: "product", width: "62%" },
                  { title: "Qty", dataIndex: "grossstd", width: "18%" },
                  { title: "Discount", dataIndex: "qty", width: "22%" },
                  { title: "Tax", dataIndex: "remainingQty", width: "20%" },
                  { title: "Price", dataIndex: "qtyToReturn", width: "20%" },
                  { title: "Net Value", dataIndex: "netValue", width: "20%" },
                  {
                    title: "",
                    width: "20%",
                    render: () => (
                      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <img src={DeleteProduct} style={{ height: "2.4vh", width: "2vw", cursor: "pointer" }} />
                        <img src={NotesImage} style={{ height: "2.2vh", width: "2vw", cursor: "pointer" }} />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ) : (
            <div style={{ backgroundColor: "#fff", height: "70vh", borderRadius: "7px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <p>No order selected</p>
            </div>
          )}

          {/* Dynamic Buttons */}
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "1.5vh" }}>
            {renderButtons()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Aggregator;