import { useEffect } from "react";
import db from "../database";
import { getOAuthHeaders } from "../constants/oAuthValidation";
import Axios from "axios";

let dataString = "";
let timeoutId;

const BarCodeHandler = (props) => {
  const authHeaders = getOAuthHeaders();
  const { setSelectedItems } = props;
  const tillData = JSON.parse(localStorage.getItem("tillData"));

  const handleKeyPress = (e) => {
    const { charCode, key } = e;

    // Reset timeout if it exists
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    // Set a timeout to clear dataString after inactivity
    timeoutId = setTimeout(() => {
      dataString = "";
      timeoutId = undefined;
    }, 100); // Reduced to 100ms for faster reset between scans

    // Build the barcode string
    if (
      (charCode >= 48 && charCode <= 57) || // 0-9
      (charCode >= 65 && charCode <= 90) || // A-Z
      (charCode >= 97 && charCode <= 122) || // a-z
      charCode === 45 || // -
      charCode === 32 // space
    ) {
      dataString += key;
    }

    // Process barcode when Enter (charCode 13) is pressed
    const productName = localStorage.getItem("productName");
    if (charCode === 13 && dataString !== "" && productName === null) {
      let products = [];
      const posConfig = tillData?.posScanConfigs?.filter((item) => item.formula !== null && item.scanTrxType === "PR");
      const posConfigItem = posConfig?.filter((item) => dataString.startsWith(item.startsWith));

      if (posConfigItem?.length > 0) {
        const config = posConfigItem[0];
        if (config.delimiter) {
          products = dataString.split(config.delimiter).filter(Boolean); // Split and remove empty entries
        }
      }
      // Process the products sequentially
      processProducts(products.length > 0 ? products : [dataString]);

      // Reset state
      dataString = "";
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const getProduct = async (formula, newData, tillData, config) => {
    let productValue = newData.substr(Number(formula.property["product.value"]?.position - 1), Number(formula.property["product.value"]?.length));
    productValue = config?.delimiter ? productValue.replace(/^0+/, "") : productValue;
    const operators = ["+", "-", "/", "*"];
    const operatorFunctions = {
      "+": (a, b) => a + b,
      "-": (a, b) => a - b,
      "*": (a, b) => a * b,
      "/": (a, b) => a / b,
    };

    await db.products
      .where("value")
      .equalsIgnoreCase(productValue)
      .toArray()
      .then(async (product) => {
        console.log(product, productValue);
        const uomData = product[0]?.uomData[0];
        let orderNo = "";
        const currenciesList = tillData.tillAccess.csBunit.currencies;
        let scanFlag = false;
        let cartlineQty;

        if (formula.property["cartline.qty"] !== undefined) {
          if (typeof formula.property["cartline.qty"] === "number") {
            cartlineQty = formula.property["cartline.qty"];
          } else if (typeof formula.property["cartline.qty"] === "string") {
            const foundOperator = operators.find((op) => formula.property["cartline.qty"].includes(op));
            const splitArr = formula.property["cartline.qty"].split(foundOperator);
            let qtyArr = splitArr.map((splitItem) => {
              let value;
              if (splitItem.includes("cartline")) {
                if (splitItem.includes("qty")) {
                  if (typeof formula.property["cartline.qty"] === "number") {
                    value = formula.property["cartline.qty"];
                  } else if (typeof formula.property["cartline.qty"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                        (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1)
                      ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                    );
                  }
                } else if (splitItem.includes("total")) {
                  if (typeof formula.property["cartline.total"] === "number") {
                    value = formula.property["cartline.total"];
                  } else if (typeof formula.property["cartline.total"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                } else if (splitItem.includes("unitprice")) {
                  if (typeof formula.property["cartline.unitprice"] === "number") {
                    value = formula.property["cartline.unitprice"];
                  } else if (typeof formula.property["cartline.unitprice"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                }
              } else {
                value = product[0].sunitprice;
              }
              return value;
            });
            cartlineQty = operatorFunctions[foundOperator](qtyArr[0], qtyArr[1]);
          } else if (typeof formula.property["cartline.qty"] === "object") {
            cartlineQty = parseFloat(
              (
                Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1)
              ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
            );
          }
        }

        let cartlineUnitprice;
        if (formula.property["cartline.unitprice"] !== undefined) {
          if (typeof formula.property["cartline.unitprice"] === "number") {
            cartlineUnitprice = formula.property["cartline.unitprice"];
          } else if (typeof formula.property["cartline.unitprice"] === "string") {
            const foundOperator = operators.find((op) => formula.property["cartline.unitprice"].includes(op));
            const splitArr = formula.property["cartline.unitprice"].split(foundOperator);
            let unitpriceArr = splitArr.map((splitItem) => {
              let value;
              if (splitItem.includes("cartline")) {
                if (splitItem.includes("qty")) {
                  if (typeof formula.property["cartline.qty"] === "number") {
                    value = formula.property["cartline.qty"];
                  } else if (typeof formula.property["cartline.qty"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                        (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1)
                      ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                    );
                  }
                } else if (splitItem.includes("total")) {
                  if (typeof formula.property["cartline.total"] === "number") {
                    value = formula.property["cartline.total"];
                  } else if (typeof formula.property["cartline.total"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                } else if (splitItem.includes("unitprice")) {
                  if (typeof formula.property["cartline.unitprice"] === "number") {
                    value = formula.property["cartline.unitprice"];
                  } else if (typeof formula.property["cartline.unitprice"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                }
              } else {
                value = product[0].sunitprice;
              }
              return value;
            });
            cartlineUnitprice = operatorFunctions[foundOperator](unitpriceArr[0], unitpriceArr[1]);
          } else {
            cartlineUnitprice = parseFloat(
              (
                Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
              ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
            );
          }
        }

        let cartlineTotal;
        if (formula.property["cartline.total"] !== undefined) {
          if (typeof formula.property["cartline.total"] === "number") {
            cartlineTotal = formula.property["cartline.total"];
          } else if (typeof formula.property["cartline.total"] === "string") {
            const foundOperator = operators.find((op) => formula.property["cartline.total"].includes(op));
            const splitArr = formula.property["cartline.total"].split(foundOperator);
            let totalArr = splitArr.map((splitItem) => {
              let value;
              if (splitItem.includes("cartline")) {
                if (splitItem.includes("qty")) {
                  if (typeof formula.property["cartline.qty"] === "number") {
                    value = formula.property["cartline.qty"];
                  } else if (typeof formula.property["cartline.qty"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                        (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData?.stdprecision) : 1)
                      ).toFixed(uomData && uomData?.decimal === "Y" ? uomData?.stdprecision : 3)
                    );
                  }
                } else if (splitItem.includes("total")) {
                  if (typeof formula.property["cartline.total"] === "number") {
                    value = formula.property["cartline.total"];
                  } else if (typeof formula.property["cartline.total"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                } else if (splitItem.includes("unitprice")) {
                  if (typeof formula.property["cartline.unitprice"] === "number") {
                    value = formula.property["cartline.unitprice"];
                  } else if (typeof formula.property["cartline.unitprice"] === "object") {
                    value = parseFloat(
                      (
                        Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                        (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                      ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                    );
                  }
                }
              } else {
                value = product[0]?.sunitprice || 0;
              }
              return value;
            });
            cartlineTotal = operatorFunctions[foundOperator](totalArr[0], totalArr[1]);
          } else {
            cartlineTotal = parseFloat(
              (
                Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
              ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
            );
          }
        }

        if (formula.property["production.orderNo"] !== undefined) {
          orderNo = newData.substr(Number(formula.property["production.orderNo"]?.position - 1), Number(formula.property["production.orderNo"]?.length));
        }

        if (formula.property["product.unitprice"] !== undefined) {
          if (formula.property["product.unitprice"]?.type === "event") {
            const workflowRules = await db.POSWorkFlowRules.toArray();
            if (workflowRules && workflowRules.length > 0) {
              await Promise.all(
                workflowRules.map(async (res) => {
                  if (res.eventId === "productPriceOnScan") {
                    const serverUrl = process.env.REACT_APP_serverUrl;
                    const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;

                    if (res.apiSource === "APB") {
                      const value = { orderno: orderNo, value: productValue };
                      const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
                      const newStringRequest = JSON.stringify(newStringifiedFields);
                      const apiBuilder = {
                        query: `mutation { executeAPIBuilder(apiBuilderId:"${res.apiEndPoint}", params: ${newStringRequest})} `,
                      };
                      const headers = {
                        "Content-Type": "application/json",
                        Authorization: `${authHeaders.access_token}`,
                      };

                      const apiBuilderResponse = await Axios.post(CWCoreServicesUrl, apiBuilder, { headers: headers });

                      if (apiBuilderResponse.data.data.executeAPIBuilder.length > 0) {
                        const response = JSON.parse(apiBuilderResponse.data.data.executeAPIBuilder);
                        product[0].mBatchId = response?.length > 0 ? response[0]?.m_batch_id : null;
                        product[0].batchno = response?.length > 0 ? response[0]?.batch_no : null;
                        cartlineUnitprice = parseFloat(response?.length > 0 ? response[0].sale_price.toFixed(currenciesList[0].stdPrecision || 2) : 0);
                      }
                    } else if (res.apiSource === "RES") {
                      console.log("-------------->Retail");
                    } else if (res.apiSource === "CAP") {
                      console.log("-------------->Core");
                    }
                  }
                })
              );
            }
          }
        }

        if (cartlineUnitprice || cartlineTotal) {
          scanFlag = true;
        }

        const productSegment = localStorage.getItem("productSegment");
        if (productSegment) {
          setSelectedItems((prev) => {
            const newItem = {
              ...product[0],
              weight: cartlineQty,
              sunitprice: cartlineUnitprice || product[0].sunitprice,
            };
            console.log([...prev, newItem], "------------selected");
            return [...prev, newItem];
          });
        }
      });
  };

  const barcodeScanFromConfig = async (data) => {
    const processSingleProduct = async (config, productData) => {
      let formula = JSON.parse(config.formula);
      formula.calculateTax = config.calculateTax || "N";
      await getProduct(formula, productData, tillData, config);
    };

    let newData = data.toString();
    let posConfig = tillData.posScanConfigs.filter((item) => item.formula !== null && item.scanTrxType === "PR");
    let productsToProcess = [];

    if (posConfig.length === 1) {
      productsToProcess.push({ config: posConfig[0], data: newData });
    } else {
      let posConfigItem = posConfig.filter((item) => newData.startsWith(item.startsWith));
      if (posConfigItem.length > 0) {
        productsToProcess.push({ config: posConfigItem[0], data: newData });
      }
    }

    if (productsToProcess.length > 0) {
      for (const { config, data } of productsToProcess) {
        await processSingleProduct(config, data);
      }
    }
  };

  // Sequential processing of products
  const processProducts = async (products) => {
    try {
      for (const product of products) {
        await barcodeScanFromConfig(product); // Wait for each onScan to complete
        await new Promise((resolve) => setTimeout(resolve, 350)); // Small delay to prevent overload
      }
      console.log("All products processed successfully.");
    } catch (error) {
      localStorage.removeItem("productSegment");
      console.error("Error processing products:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      dataString = ""; // Reset on unmount
      clearTimeout(timeoutId); // Clear any pending timeout
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []); // Empty dependency array is fine here since tillData is static

  return null;
};

export default BarCodeHandler;
