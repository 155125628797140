import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Divider, Button, message, Tooltip, Modal, Input, Spin, Badge, Layout, Menu, List, Progress } from "antd";
import { LoadingOutlined, ArrowLeftOutlined, MoreOutlined, ControlOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../constants/config";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import BarCodeComponent from "../../../lib/BarCodeComponet";
import InfiniteScroll from "react-infinite-scroll-component";
import AddCircle from "../../../assets/images/add-circle.svg";
import cwPosLogo from "../../../assets/images/cwpos-green.png";
import MenuIcon from "../../../assets/images/menu.png";
import All from "../../../assets/images/all.svg";
import ParkedOrder from "../../../assets/images/parkedOrder.svg";
import InProgress from "../../../assets/images/inProgress.svg";
import Pending from "../../../assets/images/pending.svg";
import Prepared from "../../../assets/images/prepared.svg";
import Completed from "../../../assets/images/completed.svg";
import Logo from "../../../assets/images/logo-without-bg.png";
import Logout from "../../../assets/images/logout.png";
import userIcon from "../../../assets/images/userIcon.svg";
import leftLoading from "../../../assets/images/opo.gif";
import SyncIcon from "../Retail/SvgComponent/SyncIcon";
import TableDineIN from "./SvgComponents/TableDineIn";
import TakeAway from "./SvgComponents/TakeAway";
import TableMenu from "./SvgComponents/TableMenu";
import TableCustomer from "./SvgComponents/TableCustomer";
import CashManagementIcon from "../Retail/SvgComponent/CashManagementIcon";
import unlinkTill from "../../../assets/images/unlink-icon.svg";
import openDrawerIcon from "../../../assets/images/openDrawer.svg";
import printerIcon from "../../../assets/images/printerIcon.svg";
import Dinner from "../../../assets/images/dinner.svg";
import Bag from "../../../assets/images/bag.svg";
import AddCust from "../../../assets/images/add-customer.svg";
import EmptyChair from "../../../assets/images/table-chair.svg";
import DeleteGreen from "../../../assets/images/delete-green.svg";
import InvoiceGreen from "../../../assets/images/invoice-green.svg";
import SettingsGreen from "../../../assets/images/settings-btn.svg";
import OffersGreen from "../../../assets/images/offers-green.svg";
import NotesLine from "../../../assets/images/note-line.svg";
import data from "../../../constants/retailPos.json";
import db from "../../../database";
import { clock } from "../../../utility/clock";
import RestaurantModals from "./RestaurantModals";
import Timer from "../../../assets/images/timer.svg";
import TextArea from "rc-textarea";
import Keyboard from "react-simple-keyboard";
import moment from "moment";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { kotPrinter } from "../../../lib/printer/kotPrinter";
import { cancelKOTPrinter } from "../../../lib/printer/cancelKOTPrinter";
// import { sendOrder } from "../../../socket";
// import { socket } from "../../../socket";
import { SyncData } from "./Tables-Management/SyncData";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import MenuLayout from "./MenuLayout";
import CashManagement from "./cashManagement";
import OrderListComponent from "./OrderListComponent";
import { useGlobalContext } from "../../../lib/auth/globalStore";
import { processSync } from "../../Sync/syncData";
import TableManagement from "./Tables-Management/tables";
import TableOrder from "./SvgComponents/TableOrder";
import CustomersComponent from "./CustomersComponent";
import HomeDeliveryComponent from "./HomeDeliveryComponent";
import { useTranslation } from "react-i18next";
import RetailModals from "../Retail/RetailModals";
import { getMultilingualText } from "../../../utility/getMultilingualText ";
import { useEventBus } from "../../../lib/hooks/EventBusProvider";
import printController from "../../../lib/printer/printController";
import { timeStamp } from "../../../utility/clock";

const FBLayout = (props) => {
  const {
    filteredPaymentMethods,
    getFilteredPaymentMethods,
    handleSelectedSaleTypeForProducts,
    selectedSaleType,
    setSelectedSaleType,
    saleTypeProducts,
    setSaleTypeProducts,
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    showAddNewCustomerFields,
    decreaseProductQty,
    deleteCart,
    giftCardData,
    setGiftCardData,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    // loading,
    // setLoading,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    setFilterdDate,
    orderType,
    parkBill,
    parkedList,
    productCategories,
    productBrands,
    handleBrandCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    productSearchInput,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    // selectProduct,
    selectedProductInCart,
    setSelectedProductInCart,
    selectedProductQty,
    // selectedRowKeys,
    // setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setQtyNumberFlag,
    filtersFlag,
    setFiltersFlag,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    setSalesHistoryType,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    prevProductsListRef,
    prevHistoryRef,
    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    setDisplayOfferProductSelection,
    offerProductsList,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    upsertPOSLog,
    removeCutomer,
    onChangeTotalQuantity,
    filterDrawer,
    setFilterDrawer,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    // parked Data
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    setFilterdParkedList,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    selectLayAwayOrder,
    // Cash Management
    setAddCashFlag,
    setCouponInput,
    // Order History
    setOrderHistoryInput,
    searchOrderHistory,
    changeOrderHistorySearchType,
    orderHistoryInput,
    orderHistorySearchInputRef,
    orderHistoryDetails,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
    paymentModal,
    tillDataPaymentMethods,
    requestPayment,
    setCouponModalVisible,
    paymentCardRef,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    setAmount,
    setNumb,
    onChangeAmount,
    paymentModalInputRef,
    processOrder,
    paymentModalLoyalityMessages,
    handleAmount,
    handleCashPayment,
    customerFlag,
    setCustomerFlag,
    setOverPayedAmount,
    overPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    selectedProductCategory,
    setCashAddInFlag,
    addCashFlag,
    isPrintModeXML,
    setSelectedKeys,
    setDisplayReturnOrderSearch,
    displayReturnOrderSearch,
    getSearchedItem,
    isProductsVisible,
    setIsProductsVisible,
    pickProduct,
    // gigt card
    validateGiftCard,
    giftCardFlag,
    setGiftCardFlag,
    giftCardItems,
    giftCardType,
    setGiftCardType,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    setIsGiftCardFlag,
    // keyboard
    setKeyboardType,
    keyboardType,
    // layout,
    // setLayout,
    // inputName,
    // setInputName,
    keyboardParkbill,
    keyboardRef,
    keyboardProduct,
    handleKeyboardInput,
    // handleKeyPress,
    searchHistoryInput,
    setSearchhistoryInput,
    showEditOldCustomerFields,
    setEditFlag,
    openPaymentModalByCustomer,
    // setSelectedProductInCart,
    setParkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    quantityInputRef,
    setIsInputFocused,
    isInputFocused,
    getCategoryProducts,
    salesHistoryType,
    setStartRowData,
    startRowData,
    cartObj,
    documentSequence,
    setDocumnetSequence,
    notesValue,
    setNotesValue,
    selectedProductForNotes,
    setSelectedProductForNotes,
    couponModalVisible,
    displayCustomerSearch,
    displayAddNewCustomer,
    displayEditOldCustomer,
    isGiftCardFlag,
    loyalityOtpModalVisible,
    productsData,
    setProductsData,
    orderDelay,
    setOrderDelay,
    addToBagFlag,
    setAddToBagFlag,
    setStockList,
    stockList,
    allProductCategories,
    orderTypeSelection,
    setOrderTypeSelection,
    // restaurantProductCategory,
    tillDocumentSequence,
    addDefinedProductWithAddons,
    manualDiscountTypes,
    setManualDiscountTypes,
    manualDiscountForm,
    setSelectedManualDiscountTypeValue,
    isSaleTypeFlag,
    setIsSaleTypeFlag,
    showProducts,
    setShowProducts,
    keyValue,
    setKeyValue,
    tableCards,
    setTableCards,
  } = props;
  const { Sider, Content, Footer } = Layout;
  const history = useHistory();
  const { t } = useTranslation();
  const multilingual = process.env.REACT_APP_MULTILINGUAL === "true" && localStorage.getItem("selectedLanguage") !== "English";
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const accessController = JSON.parse(tillData?.tillAccess?.cwrTill?.accessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  // SAVING BILL BLOCK START //
  const storedLocatedList = JSON.parse(localStorage.getItem("locatedList"));
  const initialLocatedList = storedLocatedList ? storedLocatedList : [];
  const [locatedList, setLocatedList] = useState(initialLocatedList);
  const [selectedTable, setSelectedTable] = useState(
    JSON.parse(localStorage.getItem("tableName")) !== "null" ? JSON.parse(localStorage.getItem("tableName")) : history?.location?.state || ""
  );
  const [orderedData, setOederedData] = useState([]);
  const [fbOrderId, setFbOrderId] = useState(uuidv4().replace(/-/g, "").toUpperCase());
  const inputRef = useRef(null);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [orderCanceled, setOrderCanceled] = useState(false);
  const [cancelItemFlag, setCancelItemFlag] = useState(false);
  const [cancelQty, setCancelQty] = useState("");
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [showOrderCompleteFlag, setShowOrderCompleteFlag] = useState(false);
  const [syncLoad, setSyncLoad] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  // const [tableCards, setTableCards] = useState([]);
  // Table screen start
  // const [keyValue, setKeyValue] = useState("0");
  const [isCashMangement, setIsCashManagement] = useState(false);
  const [clock1, setClock1] = useState(clock());
  // const [showProducts, setShowProducts] = useState(false);
  const [occupiedDataTablesData, setOcupiedTablesData] = useState([]);
  const [saleType, setSaleType] = useState("");
  const [reservedTablesData, setReservedTablesData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const userPreferences = JSON.parse(localStorage.getItem("userPreferences")) || { dateFormat: "DD-MM-YYYY", dateTimeFormat: "DD-MM-YYYY HH:mm:ss" };
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const { dispatchEvent } = useEventBus();

  useEffect(() => {
    const cartData = {
      items: [],
      couponInput: [],
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
      giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: tillData?.tillAccess?.csBunit?.b2cCustomer || {},
      salesRepId: null,
      cardPaymentData: {},
      documentno: `${tillData?.tillAccess?.cwrTill?.prefix}${parseFloat(localStorage.getItem("documentSequence")) + 1}`,
    };
    localStorage.setItem("cartObj", JSON.stringify(cartData));
    // setCart(cartData);
  }, []);

  useEffect(() => {
    db.pricingRules.toArray().then((pr) => {
      const manualPricingRules = pr.filter((rule) => rule.manualDiscount === "Y");
      setManualDiscountTypes([...manualPricingRules]);
    });
  }, []);

  useEffect(() => {
    const fetchAndHandleSaleType = async () => {
      try {
        let selectedSaleTypeArray;
        if (keyValue === "0") {
          if (posConfig.enableDineIn === "Y") {
            selectedSaleTypeArray = await getSaleTypeArray("DI");
            setSaleType("DI");
          } else if (posConfig.enableTakeAway === "Y" && posConfig.enableDineIn === "N") {
            selectedSaleTypeArray = await getSaleTypeArray("TA");
            setSaleType("TA");
          } else {
            setSaleType("HD");
          }
        }

        if (selectedSaleTypeArray) {
          handleSelectedSaleTypeForProducts(selectedSaleTypeArray)();
          // const selectedSaleTypeArray = await getSaleTypeArray(selectedSaleTypeArray);
          // if (selectedSaleTypeArray) {
          //   handleSelectedSaleTypeForProducts(selectedSaleTypeArray)();
          // }
        }
      } catch (error) {
        console.error("Error fetching or handling sale type:", error);
      }
    };

    fetchAndHandleSaleType();
  }, []);

  useEffect(() => {
    if (keyValue === "0" && posConfig.enableTakeAway === "Y") {
      localStorage.setItem("dineIn", "N");
    }
  }, [keyValue]);

  const getSaleTypeArray = async (type) => {
    try {
      const saleTypes = await db.posSaletypes.toArray();
      const selectedSaleTypeArray = saleTypes.filter((saleType) => saleType.cwrSaletype.value === type);
      return selectedSaleTypeArray.length > 0 ? selectedSaleTypeArray[0] : null;
    } catch (error) {
      console.error("Error fetching sale types:", error);
      return [];
    }
  };

  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  let cleanToken;
  const authHeaders = getOAuthHeaders();
  if (authHeaders) {
    cleanToken = authHeaders.access_token;
  }

  const handleKeyvalue = (value) => {
    setKeyValue(value);
    if (value === "0") {
      // history.push("/table-management");
    } else if (value === "2") {
      // let newCart = createNewCart(tillData);
      // setCart(newCart);
      localStorage.setItem("homeDelivery", "N");
    }
    localStorage.setItem("dineIn", "Y");

    if (value !== "2") {
      localStorage.setItem("homeDelivery", "Y");
    }
  };

  // CLOCK BLOCK START //
  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     setDisplayClock(clock());
  //   }, 5000);
  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, []);
  // CLOCK BLOCK END

  useEffect(async () => {
    await db.products.toArray().then((productsFetched) => {
      setProductsList([...productsFetched.slice(0, 50)]);
    });

    const handleOrderReceived = (order) => {
      if (order.type === "Y") {
        // history.push("/table-management");
        // history.push("/pos");
        setKeyValue("0");
      }
    };

    // socket.on("orderReceived", handleOrderReceived);
    // Clean up the event listener when the component unmounts
    return () => {
      // socket.off("orderReceived", handleOrderReceived);
    };
  }, []);

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        if (!selectedTable) return;

        // let newCart = createNewCart(tillData);
        let newCart = cart;

        await modifyDocumentSequence();

        const tableData = await db.tableData.where("cwrFbTableId").equals(selectedTable?.cwrFbTableId).toArray();

        if (tableData.length > 0) {
          if (tableData[0].ordered === "N" || !tableData[0].ordered) {
            await handleNewOrder(tableData[0], newCart, userPreferences);
          } else {
            await handleExistingOrder(selectedTable);
          }

          await cleanupCompletedOrders();
        }
      } catch (error) {
        console.error("Error in useOrderManagement:", error);
      }
    };

    fetchTableData();
  }, [orderCanceled, selectedTable]);

  const createNewCart = (tillData) => {
    return {
      items: [],
      couponInput: [],
      total: 0,
      tax: 0,
      discount: 0,
      paid: 0,
      change: 0,
      totalQty: 0,
      roundOff: 0,
      payments: [],
      redemptionPoints: 0,
      accumulationPoints: 0,
      creditAmount: 0,
      sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
      referenceId: uuidv4().replace(/-/g, "").toUpperCase(),
      giftCardRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      couponRefId: uuidv4().replace(/-/g, "").toUpperCase(),
      customer: tillData.tillAccess.csBunit.b2cCustomer,
      salesRepId: null,
      cardPaymentData: {},
      documentno: `${tillData.tillAccess.cwrTill.prefix}${parseFloat(localStorage.getItem("documentSequence")) + 1}`,
    };
  };

  const modifyDocumentSequence = async () => {
    const currentSequence = parseFloat(localStorage.getItem("documentSequence"));
    localStorage.setItem("documentSequence", currentSequence + 1);
  };

  const handleNewOrder = async (tableData, newCart, userPreferences) => {
    let allTableData = await db.tableData.toArray();
    const updatedResponse = {
      ...tableData,
      fbOrderId: uuidv4().replace(/-/g, "").toUpperCase(),
      lines: [],
      ordered: "Y",
      fbOrderSync: "N",
      fbOrderStatus: "IP",
      dineIn: localStorage.getItem("dineIn"),
      createdTime: moment(new Date()).format(userPreferences.dateTimeFormat),
      cart: newCart,
    };

    const updatedTableData = allTableData.map((item) => {
      if (item.cwrFbTableId === tableData.cwrFbTableId) {
        item.ordered = "Y";
        item.documentno = newCart.documentno;
        item.statusType = "OCU";
        item.color = "#a7c957";
      }
      return item;
    });

    await db.fbOrderData.add(updatedResponse);
    await db.tableData.clear();
    await db.tableData.bulkPut(updatedTableData);

    SyncData(updatedResponse, "upsertTableStatus");
    setCart(updatedResponse.cart || newCart);
  };

  const handleExistingOrder = async (selectedTable) => {
    const ordersFetched = await db.fbOrderData.where("cwrFbTableId").equals(selectedTable?.cwrFbTableId).toArray();

    if (ordersFetched.length > 0) {
      ordersFetched.forEach((fbOrder) => {
        if (fbOrder.fbOrderStatus === "IP") {
          let cartData = {
            ...fbOrder.cart,
            discount: fbOrder.cart.discount || 0,
            orderTime: fbOrder.creatdeTime,
            tableNo: fbOrder.name,
            statusType: "OCU",
            sOrderID: fbOrder?.cart?.sOrderID || uuidv4().replace(/-/g, "").toUpperCase(),
          };
          setCart(cartData);
        }
      });
    }
  };

  const cleanupCompletedOrders = async () => {
    await db.fbOrderData
      .where("fbOrderStatus")
      .equals("CO")
      .and((order) => order.fbOrderSync === "Y")
      .delete();
  };

  useEffect(async () => {
    const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
    let cartData = JSON.parse(localStorage.getItem("cartObj"));
    if (cartData.items.length <= 0 && cartData.customer.cwrCustomerId !== defaultCustomer.cwrCustomerId) {
      cartData.customer = defaultCustomer;
      setCart(cartData);
      localStorage.setItem("cartObj", JSON.stringify(cartData));
    }
    let products = await db.products.toArray();
    setTempProducts(products);
    if (tillaccess?.dineIn === "N") {
      cart.customer = tillData.tillAccess.csBunit.b2cCustomer;
      setCart(cart);
    }
    const newArray = cart.items.filter((item) => item.isSelected === "Y");
    setCartItems(newArray);
  }, [cart]);

  // SAVING BILL BLOCK END //

  // Add ONS Modal Starts //

  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleAddOnModal = (record, event) => {
    event.stopPropagation();
    setDisplayAddOnSelection(true);
    setSelectedProduct(record);
    const requiredListFilter = _.filter([...record.productAddons], (item) => item.mAddonGroup.minqty === 1);
    const optionsListFilter = _.filter([...record.productAddons], (item) => item.mAddonGroup.minqty === 0);
    setAddOnsList({
      requiredList: requiredListFilter || [],
      optionsList: optionsListFilter || [],
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }

    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  const handleAdd = async () => {
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        productAddons: selectedAddons || [],
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    handleAddOnModalClose();
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  // Add ONS Modal Ends //

  // Cart Functionality Starts //

  // const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectItem, setSelectItem] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const selectProduct = (record, index) => {
    const newArray = cart.items
      .map((item, itemIndex) => {
        if (record.uniqId === item.uniqId && item.productId === record.productId) {
          if (item.isSelected === "N" || item.isSelected === undefined) {
            item.isSelected = "Y";
          } else if (item.isSelected === "Y") {
            item.isSelected = "N";
            selectProductInCart([]);
          }
        }
        return item;
      })
      .filter((item) => item.isSelected === "Y");

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < cart.items.length; i += 1) {
      totalPrice += cart.items[i].nettotal;
      totalItemsQty += cart.items[i].weight;
      totalTax += cart.items[i].taxAmount;
      totalDiscounts += cart.items[i].discount;
      cart.items[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    setCartItems(newArray);
    setCart({
      ...cart,
      items: [...cart.items],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      tableId: selectedTable?.cwrFbTableId,
    });
  };

  const addProductWithAddons = (addToCart, qty) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    const index = addedToCart.findIndex(
      (p) => p.productId === addToCart.productId && p.order === "N"
      // &&
      // p.upc === addToCart.upc &&
      // p.mBatchId === addToCart.mBatchId
    );

    if (index >= 0) {
      if (weight > 0) {
        message.success(`${addedToCart[index].name} Added Successfully`);
      }
      addedToCart[index].weight = addedToCart[index].weight + weight;
      if (addedToCart[index].weight === 0) {
        addedToCart.splice(index, 1);
      } else if (addedToCart[index].weight < 0) {
        // check if the resulting weight is negative
        message.error(`Invalid weight for ${addedToCart[index].name}`);
        addedToCart[index].weight = addedToCart[index].weight - weight; // undo the weight update
      } else {
        // calculate tax and net total
        const addOnsPriceSum = _.sumBy(addToCart?.productAddons, "price");
        const mrp =
          addToCart.productAddons?.length > 0
            ? addOnsPriceSum + parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight
            : parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        const presentProduct = addedToCart[index];
        addedToCart.splice(index, 1);
        addedToCart.unshift(presentProduct);
        addedToCart.reverse();
      }
    } else {
      if (weight !== 0) {
        addToCart.weight = weight;
        if (addToCart.weight < 0) {
          // check if the weight is negative
          addToCart.weight = Math.abs(weight);
        } else {
          const addOnsPriceSum = _.sumBy(addToCart?.productAddons, "price");
          const mrp =
            addToCart.productAddons?.length > 0 ? (addOnsPriceSum + parseFloat(addToCart.salePrice)) * addToCart.weight : parseFloat(addToCart.salePrice) * addToCart.weight;
          const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
          addToCart.taxAmount = tax;
          addToCart.nettotal = mrp;
          addedToCart.push(addToCart);
        }
      } else {
        addedToCart[index].weight = addedToCart[index].weight + weight;
        if (addedToCart[index].weight === 0) {
          addedToCart.splice(index, 1);
        } else if (addedToCart[index].weight < 0) {
          // check if the resulting weight is negative
          addedToCart[index].weight = addedToCart[index].weight - weight; // undo the weight update
        }
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    addedToCart.reverse();

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;
    setCart({
      ...cart,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      tableId: selectedTable?.cwrFbTableId,
    });

    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    localStorage.setItem("locatedList", JSON.stringify(locatedList));
    // checkProductDiscounts(addToCart);
  };

  // Cart Functionality Ends //

  // Table Management Starts //

  const handleDinein = () => {
    // history.push("/table-management");
    history.push("/pos");
  };

  const handleCancelItem = async () => {
    let cancelFlag = true;
    if (cartItems.length > 0) {
      let orderIdNo;
      let orderLines = [];
      const seenNames = new Set();
      const newID = new Set();
      await db.fbOrderData
        .where("cwrFbTableId")
        .equals(selectedTable?.cwrFbTableId)
        .toArray()
        .then(async (ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (response) => {
              if (response.fbOrderStatus === "IP") {
                orderIdNo = response.fbOrderId;
                response.cart.items.map((item) => {
                  cart.items.map(async (obj) => {
                    if (obj.productId === item.productId && obj.isSelected === "Y" && !seenNames.has(item.fbOrderLineId) && !newID.has(obj.uniqId)) {
                      if (obj.weight === 1) {
                        seenNames.add(item.fbOrderLineId);
                        newID.add(obj.uniqId);
                        obj.nettotal = 0;
                        obj.realPrice = 0;
                        obj.salePrice = 0;
                        obj.taxAmount = 0;
                        obj.total = 0;
                        obj.canceled = "Y";
                        obj.isCanceled = "Y";
                        obj.isSelected = "N";
                      } else if (obj.weight > 1 && obj.nettotal > 0) {
                        cancelFlag = false;
                        setCancelQty(obj.weight);
                        setLayout("num");
                        const inputVal = obj.weight;
                        let name = "default";

                        setTimeout(() => {
                          setInputs({
                            ["default"]: obj.weight,
                          });
                        }, 100);
                        return setCancelItemFlag(true);
                      }
                    }
                  });
                });

                if (cancelFlag === true) {
                  let addedToCart = cart.items;
                  let totalTax = 0;
                  let totalPrice = 0;
                  let totalItemsQty = 0;
                  let totalDiscounts = 0;
                  for (let i = 0; i < addedToCart.length; i += 1) {
                    totalPrice += addedToCart[i].nettotal;
                    totalItemsQty += addedToCart[i].weight;
                    totalTax += addedToCart[i].taxAmount;
                    totalDiscounts += addedToCart[i].discount;
                    addedToCart[i].key = i;
                  }

                  const roundOffValue = Math.round(totalPrice);
                  const totalRoundOff = totalPrice - roundOffValue;
                  totalPrice = roundOffValue;

                  setCart({
                    ...cart,
                    items: [...addedToCart],
                    total: totalPrice,
                    tax: totalTax,
                    discount: totalDiscounts,
                    totalQty: totalItemsQty,
                    roundOff: totalRoundOff,
                  });
                  let array1 = [];
                  for (let i = 0; i < cartItems.length; i++) {
                    let item = cartItems[i];
                    let printer = item.productionCenter;
                    if (!array1.hasOwnProperty(`${printer}`)) {
                      array1[`${printer}`] = [];
                    }
                    array1[`${printer}`].push(cartItems[i]);
                  }
                  if (posConfig?.enableCancelKOTPrint === "Y") {
                    cancelKOTPrinter(array1);
                  }
                  response.cart = {
                    ...cart,
                    items: [...addedToCart],
                    total: totalPrice,
                    tax: totalTax,
                    discount: totalDiscounts,
                    totalQty: totalItemsQty,
                    roundOff: totalRoundOff,
                  };
                  response.fbOrderSync = "N";

                  let orderLines = [];
                  response.cart.items.map((item) => {
                    let fbOrderLineId = item.fbOrderLineId;
                    let fbMetaOrderId = uuidv4().replace(/-/g, "").toUpperCase();
                    let metaString = { ...item };
                    metaString.tax = cart.tax;
                    metaString.total = cart.total - item.discount;
                    metaString.totalQty = cart.totalQty;
                    metaString.customer = cart.customer;
                    metaString.sOrderID = cart.sOrderID;
                    metaString.fbMetaOrderId = fbMetaOrderId;
                    metaString.fbOrderLineId = fbOrderLineId;
                    metaString.isSelected = "N";
                    orderLines.push(`{
                            fbOrderId: "${orderIdNo}"
                            fbOrderLineId: "${fbOrderLineId}"
                            mPoductId: "${item.productId}"
                            mBatchId: null
                            description: "good"
                            csUomId: "${item.uom}"
                            csTaxId: "${item.tax}"
                            discount:${item.discount}
                            line: 1
                            qty: ${item.weight}
                            unitPrice: ${item.realPrice}
                            listPrice: 30
                            lineNet: 2.6
                            lineTax: ${item.taxRate}
                            lineGross: 30
                            sOrderLineId: null
                            isOrdered: "Y"
                            isCanceled: "${item.isCanceled === "Y" ? "Y" : "N"}"
                            meta:[{
                              key:"orderLine"
                              value:${JSON.stringify(JSON.stringify(metaString))}
                              fbOrderLineMetaId:"${fbMetaOrderId}"
                            }]
                        }`);
                  });
                  response.lines = orderLines;
                  await db.fbOrderData.put(response, response.fbOrderId);
                  const order = {
                    fbOrder: {
                      fbOrderId: response.fbOrderId,
                      order: response,
                    },
                  };
                  // sendOrder(order);
                  SyncData([response], "upsertFbOrder");
                }
              }
            });
          }
        });
    } else {
      const seenNames = new Set();
      const newID = new Set();
      let orderIdNo;
      await db.fbOrderData
        .where("cwrFbTableId")
        .equals(selectedTable?.cwrFbTableId)
        .toArray()
        .then(async (ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (response) => {
              if (response.fbOrderStatus === "IP") {
                orderIdNo = response.fbOrderId;
                cart.items.map((obj) => {
                  cartItems.map((item) => {
                    if (obj.productId === item.productId && !seenNames.has(item.fbOrderLineId) && !newID.has(obj.uniqId) && obj.order === "Y") {
                      if (obj.weight === 1) {
                        seenNames.add(item.fbOrderLineId);
                        newID.add(obj.uniqId);
                        obj.nettotal = 0;
                        obj.realPrice = 0;
                        obj.salePrice = 0;
                        obj.taxAmount = 0;
                        obj.total = 0;
                        obj.canceled = "Y";
                        obj.isCanceled = "Y";
                      } else if (obj.weight > 1 && obj.nettotal > 0) {
                        cancelFlag = false;
                        setCancelQty(obj.weight);
                        setLayout("num");
                        const inputVal = obj.weight;
                        let name = "default";

                        setTimeout(() => {
                          setInputs({
                            ["default"]: obj.weight,
                          });
                        }, 100);
                        return setCancelItemFlag(true);
                      }
                    }
                  });
                });
                let addedToCart = cart.items;
                let totalTax = 0;
                let totalPrice = 0;
                let totalItemsQty = 0;
                let totalDiscounts = 0;
                for (let i = 0; i < addedToCart.length; i += 1) {
                  totalPrice += addedToCart[i].nettotal;
                  totalItemsQty += addedToCart[i].weight;
                  totalTax += addedToCart[i].taxAmount;
                  totalDiscounts += addedToCart[i].discount;
                  addedToCart[i].key = i;
                }
                const roundOffValue = Math.round(totalPrice);
                const totalRoundOff = totalPrice - roundOffValue;
                totalPrice = roundOffValue;

                setCart({
                  ...cart,
                  items: [...addedToCart],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                });

                response.cart = {
                  ...cart,
                  items: [...addedToCart],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                };
                response.fbOrderSync = "N";

                let orderLines = [];
                response.cart.items.map((item) => {
                  let fbOrderLineId = item.fbOrderLineId;
                  let fbMetaOrderId = uuidv4().replace(/-/g, "").toUpperCase();
                  let metaString = { ...item };
                  metaString.tax = cart.tax;
                  metaString.total = cart.total - item.discount;
                  metaString.totalQty = cart.totalQty;
                  metaString.customer = cart.customer;
                  metaString.sOrderID = cart.sOrderID;
                  metaString.fbMetaOrderId = fbMetaOrderId;
                  metaString.fbOrderLineId = fbOrderLineId;
                  metaString.isSelected = "N";
                  orderLines.push(`{
                      fbOrderId: "${orderIdNo}"
                      fbOrderLineId: "${fbOrderLineId}"
                      mPoductId: "${item.productId}"
                      mBatchId: null
                      description: "good"
                      csUomId: "${item.uom}"
                      csTaxId: "${item.tax}"
                      discount:${item.discount}
                      line: 1
                      qty: ${item.weight}
                      unitPrice: ${item.realPrice}
                      listPrice: 30
                      lineNet: 2.6
                      lineTax: ${item.taxRate}
                      lineGross: 30
                      sOrderLineId: null
                      isOrdered: "Y"
                      isCanceled: "${item.isCanceled === "Y" ? "Y" : "N"}"
                      meta:[{
                        key:"orderLine"
                        value:${JSON.stringify(JSON.stringify(metaString))}
                        fbOrderLineMetaId:"${fbMetaOrderId}"
                      }]
                  }`);
                });
                response.lines = orderLines;
                await db.fbOrderData.put(response, response.fbOrderId);

                let array1 = [];

                for (let i = 0; i < cartItems.length; i++) {
                  let item = cartItems[i];
                  let printer = item.productionCenter;
                  if (!array1.hasOwnProperty(`${printer}`)) {
                    array1[`${printer}`] = [];
                  }
                  array1[`${printer}`].push(cartItems[i]);
                }
                setTimeout(() => {
                  if (posConfig?.enableCancelKOTPrint === "Y") {
                    cancelKOTPrinter(array1);
                  }
                }, 500);
              }
            });
          }
        });
    }
  };

  const removeItems = async () => {
    setCancelItemFlag(false);
    let cancelFlag = true;
    let modifiedData = [];
    let removedProduct = [];
    if (cartItems.length > 0) {
      let orderLines = [];
      const seenNames = new Set();
      const newID = new Set();
      await db.fbOrderData
        .where("cwrFbTableId")
        .equals(selectedTable?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (response) => {
              if (response.fbOrderStatus === "IP") {
                let filteredData = [];
                cart.items.map((product) => {
                  response.cart.items.map((item) => {
                    if (product.productId === item.productId && product.isSelected === "Y") {
                      const countToRemove = Math.min(getCancelValue(inputName), product.weight);
                      const reducedWeight = product.weight - getCancelValue(inputName);
                      const addOnsPriceSum = _.sumBy(product?.productAddons, "price");
                      const mrp =
                        product.productAddons?.length > 0 ? addOnsPriceSum + parseFloat(product.salePrice) * reducedWeight : parseFloat(product.salePrice) * reducedWeight;
                      const tax = mrp - mrp / (1 + product.taxRate / 100);
                      const mrp1 =
                        product.productAddons?.length > 0 ? addOnsPriceSum + parseFloat(product.salePrice) * countToRemove : parseFloat(product.salePrice) * countToRemove;
                      const tax1 = mrp - mrp / (1 + product.taxRate / 100);

                      if (product.weight - parseFloat(getCancelValue(inputName)) === 0) {
                        product.nettotal = 0;
                        product.realPrice = 0;
                        product.taxAmount = 0;
                        product.salePrice = 0;
                        modifiedData.push(product);
                        product.isSelected = "N";
                        product.weight = product.weight - parseFloat(getCancelValue(inputName));
                        cart.total = cart.total - mrp1;
                      }

                      if (product.weight - parseFloat(getCancelValue(inputName)) !== 0 && parseFloat(getCancelValue(inputName)) < product.weight) {
                        modifiedData.push({
                          ...product,
                          name: product.name,
                          weight: parseFloat(getCancelValue(inputName)),
                          taxAmount: tax1,
                          nettotal: mrp1,
                          total: mrp1,
                          isSelected: "N",
                        });
                        let cartItem = product;
                        product.nettotal = product.nettotal - mrp1;
                        product.taxAmount = product.taxAmount - tax1;
                        product.isSelected = "N";
                        product.weight = product.weight - parseFloat(getCancelValue(inputName));
                        cart.total = cart.total - mrp1;
                      }

                      seenNames.add(item.fbOrderLineId);
                      newID.add(product.uniqId);
                    }
                  });
                });
                setCart(cart);
                if (cancelFlag === true) {
                  let orderId = localStorage.getItem("orderId") === null ? 1 : Number(localStorage.getItem("orderId")) + 1;
                  localStorage.setItem("orderId", orderId);
                  let newCart = cart;
                  let orderIdNo = response.fbOrderId;
                  let orderItems = [];
                  cart.items.map((item) => {
                    let fbOrderLineId = uuidv4().replace(/-/g, "").toUpperCase();
                    let fbMetaOrderId = uuidv4().replace(/-/g, "").toUpperCase();
                    let metaString = { ...item };
                    item.fbOrderLineId = fbOrderLineId;
                    metaString.tax = newCart.tax;
                    metaString.total = newCart.total - item.discount;
                    metaString.totalQty = newCart.totalQty;
                    metaString.customer = newCart.customer;
                    metaString.sOrderID = newCart.sOrderID;
                    metaString.fbOrderLineId = fbOrderLineId;
                    metaString.fbMetaOrderId = fbMetaOrderId;
                    metaString.weight = item.weight;
                    metaString.isSelected = "N";
                    metaString.uniqId = fbOrderLineId;
                    orderItems.push(`{
                        fbOrderId: "${orderIdNo}"
                        fbOrderLineId: "${fbOrderLineId}"
                        mPoductId: "${item.productId}"
                        mBatchId: null
                        description: "good"
                        csUomId: "${item.uom}"
                        csTaxId: "${item.tax}"
                        discount:${item.discount}
                        line: 1
                        qty: ${item.weight}
                        unitPrice: ${item.realPrice}
                        listPrice: 30
                        lineNet: 2.6
                        lineTax: ${item.taxRate}
                        lineGross: 30
                        sOrderLineId: null
                        isOrdered: "Y"
                        isCanceled: "Y"
                        meta:[{
                          key:"orderLine"
                          value:${JSON.stringify(JSON.stringify(metaString))}
                          fbOrderLineMetaId:"${fbMetaOrderId}"
                        }]
                        }`);
                  });
                  let obj = {
                    fbOrderId: orderIdNo,
                    cancelItems: orderLines,
                  };
                  // cart.items = [...filteredData];
                  response.cart = cart;
                  response.lines = orderItems;
                  response.fbOrderSync = "N";
                  await db.fbOrderData.put(response, response.fbOrderId);
                  const order = {
                    fbOrder: {
                      fbOrderId: response.fbOrderId,
                      order: response,
                    },
                  };
                  let array1 = [];
                  for (let i = 0; i < modifiedData.length; i++) {
                    let item = modifiedData[i];
                    let printer = item.productionCenter;
                    if (!array1.hasOwnProperty(`${printer}`)) {
                      array1[`${printer}`] = [];
                    }
                    array1[`${printer}`].push(modifiedData[i]);
                  }
                  if (posConfig?.enableCancelKOTPrint === "Y") {
                    cancelKOTPrinter(array1);
                  }
                  // sendOrder(order);
                  SyncData(obj, "upsertFbOrder");
                }
                setOrderCanceled(!orderCanceled);
              }
            });
          }
        });
    }
  };

  const manualDiscountRestaurant = async (values) => {
    if (selectedProductInCart) {
      const pr = await db.pricingRules.toArray();
      const index = pr.findIndex((md) => md.mPricingrulesId === selectedProductInCart?.mPricingruleId && md.manualDiscount === "Y");
      if (index !== -1) {
        const pricingRule = pr[index];
        if (pricingRule.type !== "TD") {
          let discountValue =
            pricingRule.discountType === "V"
              ? selectedProductInCart.discount
              : ((selectedProductInCart.discount / (selectedProductInCart?.realPrice * selectedProductInCart?.weight)) * 100).toFixed(2);
          manualDiscountForm.setFieldsValue({
            discountName: pricingRule.mPricingrulesId,
            discountValue: discountValue,
          });
          setManualDiscountInput(discountValue);
          setSelectedManualDiscountTypeValue(pricingRule);
        } else {
          const matchedPricingRule = cart?.manualDiscountData?.totalLevelDiscount?.findIndex((item) => item.pricingRule === pricingRule.mPricingrulesId);
          manualDiscountForm.setFieldsValue({
            discountName: pricingRule.mPricingrulesId,
            discountValue: cart?.manualDiscountData?.totalLevelDiscount[matchedPricingRule]?.discountValue,
          });
          setManualDiscountInput(cart?.manualDiscountData?.totalLevelDiscount[matchedPricingRule]?.discountValue);
          setSelectedManualDiscountTypeValue(pricingRule);
        }
      } else {
        manualDiscountForm.resetFields();
        setManualDiscountInput("");
        setSelectedManualDiscountTypeValue("");
      }
    }
    parseInt(cart.total) > 0 && setManualDiscountModalVisible(true);
  };

  const handleCancelOrder = async () => {
    await db.tableData
      .where("cwrFbTableId")
      .equals(selectedTable.cwrFbTableId)
      .toArray()
      .then(async (response) => {
        if (response.length > 0) {
          response[0].status = "OPN";
          response[0].salesRepId = response[0].waiter;
          response[0].lines = [];
          response[0].color = "rgba(146, 144, 152, 0.5)";
          response[0].statusType = "OPN";
          response[0].fbOrderSync = "N";
          response[0].tableSync = "N";
          await db.tableData.update(response[0].cwrFbTableId, response[0]);
          SyncData(response[0], "upsertTableStatus");
          await db.fbOrderData
            .where("cwrFbTableId")
            .equals(selectedTable?.cwrFbTableId)
            .toArray()
            .then((ordersFetched) => {
              if (ordersFetched.length > 0) {
                ordersFetched.map(async (fbOrder) => {
                  if (fbOrder.fbOrderStatus === "IP") {
                    db.fbOrderData.where("fbOrderId").equals(fbOrder.fbOrderId).modify({ fbOrderSync: "N", fbOrderStatus: "CL" });
                    SyncData(fbOrder, "upsertFbOrder");
                  }
                });
              }
            });
          // history.push("/table-management");
          history.push("/pos");
        }
      });
  };

  const [selectedItemsModalOpen, setSelectedItemsModalOpen] = useState(false);

  const handleOrder = () => {
    let newLocated = [...locatedList];
    let newCart = cart;
    newCart?.items?.map((cartItem, index) => (cartItem.parkedItem = true));
    let newTempData = newCart?.items?.filter((cartItem, index) => cartItem.order === "N");
    const index = locatedList?.findIndex((item) => item.cwrFbTableId === selectedTable?.cwrFbTableId);
    let serviceData = {};
    if (index !== -1) {
      newLocated[index] = {
        ...newLocated[index],
        cart: newCart,
      };
      serviceData = newLocated[index];
    } else {
      let object = {
        name: selectedTable?.table,
        cart: newCart,
        noOfPersons: selectedTable?.noOfPersons,
        dineIn: localStorage.getItem("dineIn"),
      };
      newLocated = [...newLocated, object];
      serviceData = object;
    }
    setLocatedList([...newLocated]);
    if (newCart.totalQty > 0 && newTempData.length > 0) {
      setOederedData(newTempData);
      setSelectedItemsModalOpen(true);
    }
  };

  const handleDisplayOk = async () => {
    let orderId = localStorage.getItem("orderId") === null ? 1 : Number(localStorage.getItem("orderId")) + 1;
    // setLoading(true);
    localStorage.setItem("orderId", orderId);
    setSelectedItemsModalOpen(false);
    let uniqId = uuidv4().replace(/-/g, "").toUpperCase();
    selectedTable.uniqId = uniqId;
    localStorage.removeItem("tableName");
    localStorage.setItem("tableName", JSON.stringify(selectedTable));
    let newCart = cart;
    let orderLines = [];
    await db.fbOrderData
      .where("cwrFbTableId")
      .equals(selectedTable?.cwrFbTableId)
      .toArray()
      .then(async (ordersFetched) => {
        if (ordersFetched.length > 0) {
          ordersFetched.map(async (fbOrder) => {
            if (fbOrder.fbOrderStatus === "IP") {
              db.tableData
                .where("cwrFbTableId")
                .equals(selectedTable?.cwrFbTableId)
                .modify((eventValue) => {
                  eventValue.items = newCart.items;
                  eventValue.totalAmount = newCart.total;
                  eventValue.documentno = newCart.documentno;
                  eventValue.time = moment(new Date()).format(userPreferences.dateTimeFormat);
                });
              orderedData.map((item) => {
                let fbOrderLineId = uuidv4().replace(/-/g, "").toUpperCase();
                let fbMetaOrderId = uuidv4().replace(/-/g, "").toUpperCase();
                let metaString = { ...item };
                item.fbOrderLineId = fbOrderLineId;
                metaString.tax = newCart.tax;
                metaString.total = newCart.total - item.discount;
                metaString.totalQty = newCart.totalQty;
                metaString.customer = newCart.customer;
                metaString.sOrderID = newCart.sOrderID;
                metaString.fbMetaOrderId = fbMetaOrderId;
                metaString.fbOrderLineId = fbOrderLineId;
                metaString.isSelected = "N";
                orderLines.push(`{
                      fbOrderId: "${fbOrder.fbOrderId}"
                      fbOrderLineId: "${fbOrderLineId}"
                      mPoductId: "${item.productId}"
                      mBatchId: null
                      description: "good"
                      csUomId: "${item.uom}"
                      csTaxId: "${item.tax}"
                      discount:${item.discount}
                      line: 1
                      qty: ${item.weight}
                      unitPrice: ${item.realPrice}
                      listPrice: 30
                      lineNet: 2.6
                      lineTax: ${item.taxRate}
                      lineGross: 30
                      sOrderLineId: null
                      isOrdered: "Y"
                      isCanceled: "N"
                      meta:[{
                        key:"orderLine"
                        value:${JSON.stringify(JSON.stringify(metaString))}
                        fbOrderLineMetaId:"${fbMetaOrderId}"
                      }]
                  }`);
              });
              let serviceData = {};
              let array1 = [];

              for (let i = 0; i < cart.items.length; i++) {
                let item = cart.items[i];
                let printer = item.productionCenter;
                if (!array1.hasOwnProperty(`${printer}`)) {
                  array1[`${printer}`] = [];
                }
                array1[`${printer}`].push(cart.items[i]);
              }

              setCart(newCart);
              if (posConfig?.enableKOTPrint === "Y") {
                kotPrinter(array1, fbOrder.fbOrderId);
              }
              newCart?.items?.map((cartItem, index) => {
                cartItem.order = "Y";
                cartItem.update = "N";
              });

              fbOrder.lines = [...(fbOrder?.lines ? fbOrder.lines : []), ...orderLines];
              fbOrder.fbOrderSync = "N";
              fbOrder.cart = newCart;
              let object = {
                name: selectedTable?.table,
                cart: newCart,
                noOfPersons: selectedTable?.noOfPersons,
                statusType: "OCU",
                fbOrderId: fbOrder.fbOrderId,
                dineIn: localStorage.getItem("dineIn"),
                uniqId: uniqId,
              };
              serviceData = object;
              db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
              // await db.tableData.put(fbOrder, fbOrder.cwrFbTableId);
              const order = {
                fbOrder: {
                  fbOrderId: fbOrder.fbOrderId,
                  order: fbOrder,
                },
              };
              // sendOrder(order);
              SyncData(fbOrder, "upsertFbOrder");
            }
          });
          const updatedTableData = await db.tableData.toArray();
          setTableCards(updatedTableData);
          const ocuTables = updatedTableData.filter((table) => table.statusType === "OCU");
          setOcupiedTablesData(ocuTables);
          const resTables = updatedTableData.filter((table) => table.statusType === "RES");
          setReservedTablesData(resTables);
        }
      });
    // history.push("/table-management");
    // history.push("/pos");
    setKeyValue("0");
    setShowProducts(false);
  };

  // Kitchen Display Modal Starts //

  const orderStatus = [
    {
      title: "All",
      imgSrc: All,
      statusValue: "NE",
    },
    {
      title: "Parked",
      imgSrc: ParkedOrder,
      statusValue: "NE",
    },
    {
      title: "Pending",
      imgSrc: Pending,
      statusValue: "NE",
    },
    {
      title: "In Progress",
      imgSrc: InProgress,
      statusValue: "UP",
    },
    {
      title: "Prepared",
      imgSrc: Prepared,
      statusValue: "PR",
    },
    {
      title: "Completed",
      imgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOrderItemsModal, setDisplayOrderItemsModal] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(orderStatus[0]);

  const orderDetailsList = JSON.parse(localStorage.getItem("orderDetails"));
  const initialOrderList = orderDetailsList ? orderDetailsList : [];
  const [orderDetails, setOrderDetails] = useState(initialOrderList);

  const handleOrderStatus = async () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salesPrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(orderDetails, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              orderDetails.push(orderObj);
            } else {
              orderDetails[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
        getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
        cWCOrderId
        orderNo
        dateCreated
        customerId
        customerName
        mobileNo
        total
        noOfItems
        totalQty
        lineItems{
            productId
            name
            productCode
            quantity
            price
            subTotal
            addOnProducts{
                id
                name
                price
            }
        }
    }
    }`,
    };
    await Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    })
      .then((response) => {
        const { getNewOmsOrders } = response.data.data;
        getNewOmsOrders?.map((item, index) => {
          let obj = { ...item };
          obj.status = "NE";
          obj.noOfItems = item.lineItems.length;
          obj.posOrders = "N";
          let time = new Date(item.dateCreated);
          let newTime = time.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          obj.dateCreated = newTime;
          let findIndex = _.findIndex(orderDetails, ["cWCOrderId", item.cWCOrderId]);
          if (findIndex === -1) {
            orderDetails.push(obj);
          } else {
            orderDetails[findIndex] = obj;
          }
          return null;
        });
        localStorage.setItem("orderDetails", JSON.stringify([...orderDetails]));
        setOrderDetails([...orderDetails]);
      })
      .catch((error) => console.log(error));
    setDisplayOrderItemsModal(true);
  };

  const handleOrderStatusSelection = (record) => {
    setSelectedOrderStatus(record);
  };

  const nextOrderStatus = () => {
    let newOrderStatus = [...orderStatus];
    let statusIndex = _.findIndex(newOrderStatus, (item) => item.title === selectedOrderStatus.title);
    if (statusIndex <= newOrderStatus.length - 1) setSelectedOrderStatus(newOrderStatus[statusIndex + 1]);
  };

  const setStatus = (status) => {
    let newStatus = "";
    switch (status) {
      case "NE":
        newStatus = "UP";
        break;
      case "UP":
        newStatus = "PR";
        break;
      case "PR":
        newStatus = "DE";
        break;
      default:
        newStatus = "NE";
    }
    return newStatus;
  };

  const handleStatusButton = (record) => {
    if ((record.status === "NE" || record.status === "UP" || record.status === "PR") && record.posOrders === "N") {
      handleInProgress(record);
    } else {
      let newOrderDetails = [...orderDetails];
      let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
      newOrderDetails[recordIndex].status = setStatus(record.status);
      localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      setOrderDetails([...newOrderDetails]);
      nextOrderStatus();
    }
  };

  const handleInProgress = async (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "${record.status}"
        })
        {
            status
            message
        }
    }`,
    };
    await Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        let newOrderDetails = [...orderDetails];
        let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOrderDetails[recordIndex].status = setStatus(record.status);
        localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
        setOrderDetails([...newOrderDetails]);
        nextOrderStatus();
      }
    });
  };

  // Kitchen Display Modal Ends //

  // BARCODE READER BLOCK START //

  const [barcodeOrderList, setBarcodeOrderList] = useState([]);
  const [barcodeOrderStatusModal, setBarcodeOrderStatusModal] = useState(false);

  const barcodeOrderInput = async (data) => {
    let str = _.startsWith(data, "m", 0);
    if (str === true) {
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      let newId = data.slice(7);
      const paramsInput = {
        query: `query{
          verifyOMSOrder(omsOrderId:"${newId}"){
          cWCOrderId
          orderNo
          dateCreated
          customerId
          customerName
          mobileNo
          total
          noOfItems
          totalQty
          lineItems{
              productId
              name
              productCode
              quantity
              price
              subTotal
              addOnProducts{
                  id
                  name
                  price
              }
          }
      }
      }`,
      };
      await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      }).then((response) => {
        const { verifyOMSOrder } = response.data.data;
        if (verifyOMSOrder) {
          let newBarcodeOrderList = [];
          let orderDate = "";
          verifyOMSOrder?.map((item, index) => {
            let obj = {
              ...item,
            };
            obj.status = "NE";
            obj.noOfItems = item.lineItems.length;
            obj.posOrders = "N";
            let time = new Date(item.dateCreated);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            obj.dateCreated = newTime;
            orderDate = time.toLocaleDateString();
            newBarcodeOrderList.push(obj);
            return null;
          });
          setBarcodeOrderList([...newBarcodeOrderList]);
          if (tillaccess.kds_print === "Y") {
            handleBarcodeOrderPrint({
              ...newBarcodeOrderList[0],
              dateCreated: orderDate,
            });
            message.success(`Order ${newBarcodeOrderList?.[0]?.orderNo} Printed Successfully`);
          } else {
            setBarcodeOrderStatusModal(true);
          }
        }
      });
    }
  };

  const handleAccept = async (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "DE"
        })
        {
            status
            message
        }
    }`,
    };
    await Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        message.success(`Order ${barcodeOrderList[0]?.orderNo} Completed Successfully`);
        setBarcodeOrderStatusModal(false);
      }
    });
  };

  // BARCODE READER BLOCK START //

  // BARCODE ORDER PRINT START //
  const handleBarcodeOrderPrint = (printedData) => {
    const printWindow = window.open("", "printWindow", "width=0,height=0");
    let printedDataArray = printedData?.lineItems?.map((item) => ({
      ...item,
      productName: item?.name,
      price: item?.price,
      qty: item?.quantity,
    }));
    const barcodeOrderData = `
      <html>
      <style>
      th,td {
        padding: 15px;
      }
      </style>
        <div style="width:100%;">
        <div style="text-align:center; margin-bottom: 20px;"><h4>${tillData.tillAccess.csBunit.name}</h4></div>
        <div style="display:flex; margin-bottom: 20px;">
        <span>Order No</span>
          <span style="margin-left: 100px;">${printedData.orderNo}</span>
        </div>
        <div style="display:flex; margin-bottom: 20px;">
        <span>Date</span>
          <span style="margin-left: 100px;">${printedData.dateCreated}</span>
        </div>
        <hr />
        <table style="width:100%">
              <tr>
                <th style="text-align:left;"><strong>Product</strong></th>
                <th style="text-align:left;"><strong>Qty</strong></th>
                <th style="text-align:left;"><strong>Price</strong></th>
              </tr>
              ${printedDataArray?.map((item) => {
                return `<tr>
                  <td>${item.productName}</td>
                  <td>${item.qty}</td>
                  <td>${item.price}</td>
                </tr>
                ${item?.addOnProducts?.map((addonItem) => {
                  return `<tr>
                    <td>--${addonItem.name}</td>
                    </tr>`;
                })}
               `;
              })}
          </table>
          <div style="display:flex; margin-top: 30px;">
          <h2>Total</h2>
            <h2 style="margin-left: 100px; text-align: end">${_.sumBy(printedDataArray, "price")}</h2>
          </div>
          <div style="text-align:center; margin-bottom: 20px;"><h4>Thank You</h4></div>
        </div>
      </html>`;
    printWindow.document.write(barcodeOrderData);
    printWindow.print();
    printWindow.close();
  };

  // BARCODE ORDER PRINT END //

  // Bill Confirmation Starts //

  const [billConfirmationModalOpen, setBillConfirmationModalOpen] = useState(false);

  // Bill Confiemation Ends //

  const handleOrderSelection = (fieldName) => {
    setOrderTypeSelection(fieldName);
  };

  // Notes Functionality //

  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [layout, setLayout] = useState("default");
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState("");
  const [categorieFlag, setCategorieFlag] = useState(false);
  const [inputFieldName, setInputFieldName] = useState("");
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const keyboard = useRef(null);

  const handleNotesModal = (id) => {
    const selectedItem = cart.items.find((item) => item.productId === id);
    let notes = selectedItem?.notes === undefined ? "" : selectedItem?.notes;
    setInputName(id);
    setNotesModalOpen(true);
    setTimeout(() => {
      keyboard.current.setInput(notes);
    });
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    }
  };

  const handleKeyboardInputsNotes = (inputs) => {
    setInputs({
      ...inputs,
      [inputName]: inputs.default,
    });
    cart.items.map((item) => {
      if (item.productId === inputName) {
        item.notes = inputs.default;
      }
    });
  };

  const handleNotesValue = (event) => {
    const inputVal = event.target.value;
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });
    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (inputName) => {
    return inputs["default"] || "";
  };

  const handleKeyboardInputsQty = (inputs) => {
    setInputs({
      ...inputs,
      [inputName]: inputs.default,
    });
  };

  const handleCancelQtyValue = (event) => {
    const inputVal = event.target.value;
    setInputs({
      ["default"]: inputVal,
    });
    keyboard.current.setInput(inputVal);
  };

  const getCancelValue = (inputName) => {
    return inputs["default"] || "";
  };

  const handleNotesSubmit = () => {
    setKeyboardVisible(false);
    setTimeout(() => {
      setKeyboardVisible(true);
      setNotesModalOpen(false);
    }, 0);
  };

  const handleBillPrint = () => {
    cart["orderSelection"] = orderTypeSelection;
    htmlPrint(cart, "Receipt");
    setBillConfirmationModalOpen(false);
  };

  const selectAllProductCategory = (id, text) => {
    allProductCategories.map((item) => {
      let finalData = [];
      Object.entries(item.printArray).map((obj) => {
        if (obj[0] === id) {
          if (text === "allProducts") {
            db.products
              .offset(productsList.length)
              .limit(100)
              .toArray()
              .then((productsFetched) => {
                const additionalProductsFetched = productsList.concat(productsFetched);
                setProductsList([...additionalProductsFetched]);
              });
          } else if (text === "1") {
            obj[1].map((e) => {
              tempProducts.map((productsFetched) => {
                if (e.mProductCategoryId === productsFetched.mProductCategoryId) {
                  finalData.push(productsFetched);
                }
              });
            });
            setProductsList([...finalData]);
          }
          setProductCategoriesList(obj[1]);
        } else {
          tempProducts.map((productsFetched) => {
            if (id === productsFetched.mProductCategoryId) {
              finalData.push(productsFetched);
            }
          });
          setProductsList([...finalData]);
        }
      });
    });
  };

  const clearCart = async (type) => {
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    const tillSessionId = tillSession.tillSessionId;
    const newDocumentSequence = cart.parked === "Y" ? documentSequence : documentSequence + 1;
    let cartToDb = cart;
    cartToDb.orderTime = timeStamp();
    cartToDb.createdBy = tillData.tillAccess.csUserId;
    cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId;
    cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
    cartToDb.tillSessionId = tillSessionId;
    cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
    cartToDb.isSynced = 0;
    cartToDb.syncAttempts = 0;
    cartToDb.customerSearchKey = cart.customer.code;
    cartToDb.tax = 0;
    cartToDb.total = 0;
    cartToDb.docType = "Invoice";
    cartToDb.totalQty = 0;
    cartToDb.couponInput = [];
    cartToDb.discount = 0;
    cartToDb.isReturn = false;
    cartToDb.items.forEach((item) => {
      item.salePrice = 0;
      item.sunitprice = 0;
      item.netStd = 0;
      item.nettotal = 0;
      item.unitTax = 0;
      item.taxAmount = 0;
      item.weight = 0;
      item.unitPrice = 0;
      item.isReturn = false;
      item.netList = 0;
      item.discount = 0;
      item.linetax = 0;
      item.linenet = 0;
      item.linegross = 0;
      item.netunit = 0;
      item.listPrice = 0;
      item.grossunit = 0;
      item.grossstd = 0;
      item.grosslist = 0;
    });
    deleteCart(true, cart.parked === "Y" ? "parked" : cartToDb.type);
    setGiftCardData([]);
    if (type === "Layaway") {
      setDocumnetSequence(documentSequence);
    } else {
      localStorage.setItem("documentSequence", newDocumentSequence);
      setDocumnetSequence(newDocumentSequence);
      await db.orders.add(cartToDb);
    }
  };

  const handleVoid = async () => {
    Modal.confirm({
      title: "This action will cancel the ordered items. Do you wish to proceed?",
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        let cartToDb = JSON.parse(localStorage.getItem("cartObj"));
        const workflowRules = await db.POSWorkFlowRules.toArray();
        const approvers = await db.approvers.toArray();
        const cancelKotRule = workflowRules.find((res) => res.eventName === "Cancel KOT");

        if (cancelKotRule) {
          dispatchEvent(cancelKotRule.eventName, cancelKotRule.ruleName, cancelKotRule.ruleCondition, approvers, async (eventData) => {
            if (eventData?.approvalGranted) {
              await proceedWithCancellation(cartToDb);
            } else {
              Modal.error({
                title: "Approval Required",
                content: "The cancellation request was not approved.",
              });
            }
          });
        } else {
          await proceedWithCancellation(cartToDb);
        }
      },
    });
    async function proceedWithCancellation(cartToDb) {
      const dineInKey = localStorage.getItem("dineIn");
      if (dineInKey && dineInKey === "Y") {
        await db.fbOrderData
          .where("cwrFbTableId")
          .equals(selectedTable?.cwrFbTableId)
          .toArray()
          .then(async (ordersFetched) => {
            if (ordersFetched.length > 0) {
              for (const fbOrder of ordersFetched) {
                if (fbOrder.fbOrderStatus === "IP") {
                  fbOrder.fbOrderStatus = "CO";
                  fbOrder.fbOrderSync = "N";
                  await db.fbOrderData.where("fbOrderId").equals(fbOrder.fbOrderId).modify({
                    fbOrderSync: "N",
                    fbOrderStatus: "CO",
                  });
                  SyncData(fbOrder, "upsertFbOrder");

                  await db.tableData
                    .where("cwrFbTableId")
                    .equals(selectedTable?.cwrFbTableId)
                    .toArray()
                    .then(async (response) => {
                      if (response.length > 0) {
                        let tableData = { ...response[0] };
                        tableData.color = "rgba(146, 144, 152, 0.5)";
                        tableData.noOfPersons = "";
                        tableData.guestName = "";
                        tableData.guestType = "";
                        tableData.referredBy = "";
                        tableData.statusType = "OPN";
                        tableData.items = [];
                        SyncData(tableData, "upsertTableStatus");
                        await db.tableData.update(tableData.cwrFbTableId, tableData);

                        setTableCards((prevTableCards) => prevTableCards.map((card) => (card.cwrFbTableId === tableData.cwrFbTableId ? { ...card, ...tableData } : card)));

                        const updatedTableData = await db.tableData.toArray();
                        setOcupiedTablesData(updatedTableData.filter((table) => table.statusType === "OCU"));
                        setReservedTablesData(updatedTableData.filter((table) => table.statusType === "RES"));
                        // clearCart(cart.type);
                      }
                    });

                  history.push("/pos");
                  setKeyValue("0");
                  setShowProducts(false);
                }
              }
            }
          });
      } else {
        setCategorieFlag(false);
        clearCart(cart.type);
        // deleteCart(true, cart.parked === "Y" ? "parked" : cartToDb.type);
      }
    }
  };

  const tillUnlink = async () => {
    let parkedBills = JSON.parse(localStorage.getItem("parkedList"));
    let posConfig = JSON.parse(localStorage.getItem("posConfig"));
    //  console.log(cart.items.length)
    const orders = await db.orders.where("isSynced").equals(0).toArray();
    //  console.log(orders)
    if (parkedBills?.length > 0) {
      {
        message.error(`${t("unlink_till_parked_bill_error")}`);
      }
    } else if (cart?.items?.length > 0) {
      {
        message.error(`${t("unlink_till_cart_error")}`);
      }
    } else if (orders?.length > 0) {
      message.error("To unlink the Till please sync pending Orders from Order History");
    } else {
      // If none of the conditions is satisfied, you can provide a default message
      if (posConfig.shiftClose === "Y") {
        if (posConfig.showDenominations === "Y") {
          history.push("/close-till");
          localStorage.setItem("unlink", true);
        } else if (posConfig.showDenominations === "N") {
          history.push("/close-till-all");
          localStorage.setItem("unlink", true);
        }
      } else {
        history.push("/");
      }
    }
  };

  const [isUnlinkTillClicked, setIsUnlinkTillClicked] = useState(false);

  const handleUnlinkTillClick = () => {
    if (cart.paid > 0) {
      message.warning("Delete the applied payment method to continue.");
      return;
    }
    if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
      message.warning("Cart contains items. Please complete or clear the order before switching the section!");
      return;
    }
    setIsUnlinkTillClicked(true);
    tillUnlink();
  };

  // useEffect(() => {
  //   if (keyValue === "0" && accessController?.dineIn === "Y") {
  //     setKeyValue("2");
  //   }
  // }, []);

  useEffect(() => {
    if (keyValue === "0" && accessController?.dineIn === "N") {
      setKeyValue("2");
    }
  }, []);

  // keyValue, accessController

  const restaurantProductCategory = async (categorie) => {
    const filteredProducts = saleTypeProducts.filter((product) => product.mProductCategoryId === categorie);
    setProductsData(filteredProducts);
    // if (saleTypeProducts.length > 0) {
    //   const filteredProducts = saleTypeProducts.filter((product) => product.mProductCategoryId === categorie);
    //   setProductsData(filteredProducts);
    // }
    // else {
    //   await db.products
    //     .where("mProductCategoryId")
    //     .equals(categorie)
    //     .toArray()
    //     .then((response) => {
    //       setProductsData(response);
    //     });
    // }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;
  const tableLayoutProps = {
    saleType,
    setSaleType,
    handleAmount,
    handleCashPayment,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    requestPayment,
    amount,
    setAmount,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    posConfig,
    setSelectedProductInCart,
    selectedProductId,
    setSelectedProductId,
    manualDiscountRestaurant,
    filteredPaymentMethods,
    getFilteredPaymentMethods,
    keyValue,
    setKeyValue,
    cart,
    setCart,
    showProducts,
    setShowProducts,
    cart,
    selectedSaleType,
    setSelectedSaleType,
    selectProductCategory,
    restaurantProductCategory,
    productsData,
    pickProduct,
    handleAddOnModal,
    decreaseProductQty,
    deleteProduct,
    increaseProductQty,
    handleOrder,
    handleVoid,
    setAddCashFlag,
    setCashAddInFlag,
    selectedTable,
    setSelectedTable,
    setKeyValue,
    keyValue,
    paymentVisible,
    setPaymentVisible,
    setSelectedTable,
    showOrderCompleteFlag,
    setShowOrderCompleteFlag,
    addDefinedProductWithAddons,
    userPreferences,
    occupiedDataTablesData,
    setOcupiedTablesData,
    reservedTablesData,
    setReservedTablesData,
    saleTypeProducts,
    setSaleTypeProducts,
    paymentVisible,
    setNumb,
    processOrder,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    ...props,
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#fff" }} spin />} spinning={false}>
      {syncLoad ? (
        <>
          <div style={{ backgroundColor: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", width: "100%" }}>
            <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
              <Col span={6}>
                <img src={Logo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} />
              </Col>
              <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "1vw", fontSize: "1vw" }}>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{formattedDateTime}</div>
                <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{clock1}</div>
              </Col>
            </Row>
            <div style={{ display: "flex", height: "90vh" }}>
              <div
                style={{
                  width: "48%",
                  background: "#fff",
                  height: "83vh",
                  margin: "1.5%",
                  marginRight: 0,
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1vw" }}>
                  <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
                    <img
                      src={leftLoading}
                      alt="Loading"
                      style={{
                        width: "100%",
                        height: "100%",
                        // transform: `rotate(${rotation}deg)`,
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "1.5em",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {percent}
                    </div>
                  </div>
                  <p style={{ color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "2.5em", marginBottom: 0 }}>Till loading in process</p>
                  {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
           <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
                </div>
              </div>
              <div style={{ width: "48%", height: "83vh", background: "#fff", margin: "1.5%", borderRadius: "10px", color: "#0F0718" }}>
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "17vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>
                  Product Categories
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productCategoryPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Products</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={productPercentage}
                  status="active"
                />
                <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Promotions</p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{ padding: "0 2.5vw", fontSize: "1em", color: "#0F0718" }}
                  percent={promotionPercentage}
                  status="active"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={data.posScreenStyles.main}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                backgroundColor: "#fff",
                height: "100vh",
                width: "6.7%",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
                paddingLeft: "0.5vw",
                paddingRight: "0.5vw",
                display: showOrderCompleteFlag ? "none" : "",
              }}
            >
              <Row style={{ fontSize: "1vw" }}>
                <Col span={24} style={{ marginTop: "1.4vh" }}>
                  <Tooltip>
                    <button
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#ffffff",
                        border: "1px solid #fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={Logo} alt="remove" style={{ height: "4.5vw" }} />
                    </button>
                  </Tooltip>
                </Col>
                {!showMoreMenu ? (
                  <>
                    <Col id="step1" span={24} style={{ marginTop: "0.7vh", display: posConfig.enableDineIn === "Y" ? "block" : "none" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          display: tillaccess?.dineIn === "Y" ? "block" : "none",
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          // display: accessController?.dineIn === "N" ? "block" : "none",
                          ...(keyValue === "0"
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                              }
                            : { backgroundColor: "#fff", border: "1px solid #fff" }),
                        }}
                        onClick={async () => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          setShowProducts(false);
                          const selectedSaleTypeArray = await getSaleTypeArray("DI");
                          handleSelectedSaleTypeForProducts(selectedSaleTypeArray)();
                          setSaleType("DI");
                          handleKeyvalue("0");
                          setPaymentVisible(false);
                          setShowProducts(false);
                          // setSaleTypeProducts([]);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TableDineIN color={keyValue === "0" ? "#FFF" : "#555359"} />
                          <span
                            className="posIcon"
                            style={{
                              color: keyValue === "0" ? "#fff" : "#555359",
                              fontWeight: 500,
                            }}
                          >
                            {getMultilingualText("Dine In")}
                          </span>
                        </div>
                      </button>
                    </Col>

                    <Col id="step4" span={24} style={{ marginTop: "1vh", display: posConfig.enableTakeAway === "Y" ? "block" : "none" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "2" || (keyValue === "0" && posConfig.enableDineIn === "N")
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                              }
                            : { backgroundColor: "#fff", border: "1px solid #fff" }),
                        }}
                        onClick={async () => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          setShowProducts(false);
                          const cartObj = JSON.parse(localStorage.getItem("cartObj"));
                          if (cartObj) {
                            cartObj.items = [];
                            console.log("cartObj-------->", cartObj);
                            localStorage.setItem("cartObj", JSON.stringify(cartObj));
                            setCart({ ...cartObj });
                          }
                          const selectedSaleTypeArray = await getSaleTypeArray("TA");
                          handleSelectedSaleTypeForProducts(selectedSaleTypeArray)();
                          setSaleType("TA");
                          handleKeyvalue("2");
                          localStorage.setItem("dineIn", "N");
                          setPaymentVisible(false);
                          // setSaleTypeProducts([]);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TableMenu color={keyValue === "2" || (keyValue === "0" && posConfig.enableDineIn === "N") ? "#FFF" : "#555359"} />
                          <span className="posIcon" style={{ color: keyValue === "2" || (keyValue === "0" && posConfig.enableDineIn === "N") ? "#fff" : "#555359" }}>
                            {getMultilingualText("Take Away")}
                          </span>
                        </div>
                      </button>
                    </Col>
                    <Col id="step2" span={24} style={{ marginTop: "1vh", display: posConfig.enableHomeDelivery === "Y" ? "block" : "none" }}>
                      <button
                        className="cartMenuItems"
                        disabled={tillaccess?.dineIn === "Y" ? false : true}
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "1" || (keyValue === "0" && posConfig.enableDineIn === "N" && posConfig.enableTakeAway === "N")
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                                padding: "0",
                              }
                            : {
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                padding: "0",
                              }),
                        }}
                        onClick={() => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          handleKeyvalue("1");
                          setSaleType("HD");
                          setPaymentVisible(false);
                          setShowProducts(false);
                          setSaleTypeProducts([]);
                          setIsCardPaymentFlag(false);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TakeAway color={keyValue === "1" || (keyValue === "0" && posConfig.enableDineIn === "N" && posConfig.enableTakeAway === "N") ? "#FFF" : "#555359"} />
                          <span
                            className="posMenuIcons"
                            style={{
                              color: keyValue === "1" || (keyValue === "0" && posConfig.enableDineIn === "N" && posConfig.enableTakeAway === "N") ? "#fff" : "#555359",
                              paddingTop: "0px",
                            }}
                          >
                            {getMultilingualText("Home Delivery")}
                          </span>
                        </div>
                      </button>
                    </Col>

                    <Col id="step4" span={24} style={{ marginTop: "1vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "3"
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                                padding: "0",
                              }
                            : {
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                padding: "0",
                              }),
                        }}
                        onClick={() => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          handleKeyvalue("3");
                          setShowOrderCompleteFlag(false);
                          // deleteCart();
                          setPaymentVisible(false);
                          setShowProducts(false);
                          setSaleTypeProducts([]);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TableOrder color={keyValue === "3" ? "#FFF" : "#fff"} />
                          <span className="posIcon" style={{ color: keyValue === "3" ? "#fff" : "#555359" }}>
                            {getMultilingualText("History")}
                          </span>
                        </div>
                      </button>
                    </Col>

                    <Col id="step3" span={24} style={{ marginTop: "1vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "5"
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                                padding: "0",
                              }
                            : {
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                padding: "0",
                              }),
                        }}
                        onClick={() => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          handleKeyvalue("5");
                          setPaymentVisible(false);
                          setShowProducts(false);
                          setSaleTypeProducts([]);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <CashManagementIcon color={keyValue === "5" ? "#FFF" : "#555359"} />
                          <span
                            className="posMenuIcons"
                            style={{
                              color: keyValue === "5" ? "#fff" : "#555359",
                              paddingTop: "0px",
                            }}
                          >
                            {getMultilingualText("Cash Management")}
                          </span>
                        </div>
                      </button>
                    </Col>
                    <Col id="step4" span={24} style={{ marginTop: "0.5vh", display: posConfig.showCustomers === "Y" ? "block" : "none" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "8"
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                                padding: "0",
                              }
                            : {
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                padding: "0",
                              }),
                        }}
                        onClick={() => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          handleKeyvalue("8");
                          setPaymentVisible(false);
                          setShowProducts(false);
                          setSaleTypeProducts([]);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <TableCustomer color={keyValue === "8" ? "#FFF" : "#555359"} />
                          <span className="posIcon" style={{ color: keyValue === "8" ? "#fff" : "#555359" }}>
                            {getMultilingualText("Customers")}
                          </span>
                        </div>
                      </button>
                    </Col>
                    {!multilingual && (
                      <Col id="step5" span={24} style={{ marginTop: "1vh" }}>
                        <button
                          className="cartMenuItems"
                          style={{
                            ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                            ...(keyValue === "6"
                              ? {
                                  backgroundColor: "#2F3856",
                                  border: "1px solid #2F3856",
                                  padding: "0",
                                }
                              : {
                                  backgroundColor: "#fff",
                                  border: "1px solid #fff",
                                  padding: "0",
                                }),
                          }}
                          onClick={async () => {
                            if (cart.paid > 0) {
                              message.warning("Delete the applied payment method to continue.");
                              return;
                            }
                            if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                              message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                              return;
                            }
                            handleKeyvalue("6");
                            setSyncLoad(true);
                            localStorage.removeItem("lastUpdatedTime");
                            let tillData = JSON.parse(localStorage.getItem("tillData"));
                            await processSync(tillData, setGlobalStore, "Incremental Sync");
                            setSyncLoad(false);
                            setGlobalStore({ percent: "0%" });
                            setGlobalStore({ productCategoryPercentage: "0" });
                            setGlobalStore({ productPercentage: "0" });
                            setGlobalStore({ promotionPercentage: "0" });
                            window.location.reload();
                            setKeyValue("0");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <SyncIcon color={keyValue === "6" ? "#FFF" : "#555359"} />
                            <span className="posMenuIcons" style={{ color: keyValue === "6" ? "#fff" : "#555359" }}>
                              {getMultilingualText("Product Sync")}
                            </span>
                          </div>
                        </button>
                      </Col>
                    )}

                    <Col id="moreMenuButton" span={24} style={{}}>
                      <button
                        className="cartMenuItems"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #fff",
                        }}
                        onClick={() => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          setShowMoreMenu(!showMoreMenu);
                          //  setKeyValue("0");
                        }}
                      >
                        <div
                          style={
                            {
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // flexDirection: "column",
                            }
                          }
                        >
                          <MoreOutlined style={{ color: "#555359" }} />
                          {/* <span className="posIcon" style={{ color: "#555359" }}>...</span> */}
                        </div>
                      </button>
                    </Col>
                  </>
                ) : (
                  <>
                    {multilingual && (
                      <Col id="step5" span={24} style={{ marginTop: "0.5vh" }}>
                        <button
                          className="cartMenuItems"
                          style={{
                            ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                            ...(keyValue === "6"
                              ? {
                                  backgroundColor: "#2F3856",
                                  border: "1px solid #2F3856",
                                  padding: "0",
                                }
                              : {
                                  backgroundColor: "#fff",
                                  border: "1px solid #fff",
                                  padding: "0",
                                }),
                          }}
                          onClick={async () => {
                            if (cart.paid > 0) {
                              message.warning("Delete the applied payment method to continue.");
                              return;
                            }
                            if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                              message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                              return;
                            }
                            handleKeyvalue("6");
                            setSyncLoad(true);
                            localStorage.removeItem("lastUpdatedTime");
                            let tillData = JSON.parse(localStorage.getItem("tillData"));
                            await processSync(tillData, setGlobalStore, "Incremental Sync");
                            setSyncLoad(false);
                            setGlobalStore({ percent: "0%" });
                            setGlobalStore({ productCategoryPercentage: "0" });
                            setGlobalStore({ productPercentage: "0" });
                            setGlobalStore({ promotionPercentage: "0" });
                            window.location.reload();
                            setKeyValue("0");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <SyncIcon color={keyValue === "6" ? "#FFF" : "#555359"} />
                            <span className="posMenuIcons" style={{ color: keyValue === "6" ? "#fff" : "#555359" }}>
                              {getMultilingualText("Product Sync")}
                            </span>
                          </div>
                        </button>
                      </Col>
                    )}
                    {/* <Col id="unlinkTill" span={24} style={{ marginTop: "2vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #fff",
                        }}
                        // onClick={() => {
                        // }}
                        onClick={tillUnlink}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={unlinkTill} style={{ height: "2.5vh" }} alt="" />
                          <span className="posMenuIcons" style={{ color: "#555359" }}>Unlink Till</span>
                        </div>
                      </button>
                    </Col> */}

                    <Col id="unlinkTill" span={24} style={{ marginTop: "2vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(isUnlinkTillClicked
                            ? {
                                backgroundColor: "#2F3856", // Active background color
                                border: "1px solid #2F3856",
                              }
                            : {
                                backgroundColor: "#fff", // Default background color
                                border: "1px solid #fff",
                              }),
                        }}
                        onClick={handleUnlinkTillClick}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={unlinkTill}
                            style={{
                              height: "3vh",
                              filter: isUnlinkTillClicked
                                ? "brightness(0) invert(1)" // Makes the icon white when clicked
                                : "none", // Default icon color
                            }}
                            alt="unlink till"
                          />
                          <span
                            className="posMenuIcons"
                            style={{
                              color: isUnlinkTillClicked ? "#fff" : "#555359", // White text color when clicked, default otherwise
                            }}
                          >
                            {getMultilingualText("Unlink Till")}
                          </span>
                        </div>
                      </button>
                    </Col>

                    <Col id="step5" span={24} style={{ marginTop: "0.5vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          ...(multilingual ? { height: "auto", minHeight: "10.2vh" } : { height: "4.2vw", minHeight: "4.2vh" }),
                          ...(keyValue === "6"
                            ? {
                                backgroundColor: "#2F3856",
                                border: "1px solid #2F3856",
                                padding: "0",
                              }
                            : {
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                padding: "0",
                              }),
                        }}
                        onClick={async () => {
                          if (cart.paid > 0) {
                            message.warning("Delete the applied payment method to continue.");
                            return;
                          }
                          if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                            message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                            return;
                          }
                          handleKeyvalue("6");
                          setSyncLoad(true);
                          localStorage.removeItem("lastUpdatedTime");
                          let tillData = JSON.parse(localStorage.getItem("tillData"));
                          await processSync(tillData, setGlobalStore, "Full Sync");
                          setSyncLoad(false);
                          setGlobalStore({ percent: "0%" });
                          setGlobalStore({ productCategoryPercentage: "0" });
                          setGlobalStore({ productPercentage: "0" });
                          setGlobalStore({ promotionPercentage: "0" });
                          window.location.reload();
                          setKeyValue("0");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <SyncIcon color={keyValue === "6" ? "#FFF" : "#555359"} />
                          <span className="posMenuIcons" style={{ color: keyValue === "6" ? "#fff" : "#555359" }}>
                            {getMultilingualText("Full Sync")}
                          </span>
                        </div>
                      </button>
                    </Col>

                    <Col id="printReceipt" span={24} style={{ marginTop: "2vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #fff",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={printerIcon} style={{ height: "3vh" }} alt="" />
                          <span className="posIcon" style={{ color: "#555359" }}>
                            {getMultilingualText("Print")}{" "}
                          </span>
                        </div>
                      </button>
                    </Col>

                    {/* Back Button to return to Main Menu */}
                    <Col id="backButton" span={24} style={{ marginTop: "2vh" }}>
                      <button
                        className="cartMenuItems"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #fff",
                        }}
                        onClick={() => setShowMoreMenu(false)}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          {/* <BackIcon color="#555359" /> */}
                          <ArrowLeftOutlined style={{ color: "#555359" }} />
                          <span className="posIcon" style={{ color: "#555359" }}>
                            {getMultilingualText("Back")}
                          </span>
                        </div>
                      </button>
                    </Col>
                    {/* <Col span={24} style={{ marginTop: "32vh" }}></Col> */}
                  </>
                )}
              </Row>
              <Col span={24} style={{ position: "absolute", bottom: 0, marginLeft: "0.5vw" }}>
                <div id="step6">
                  <button
                    className="cartMenuItems"
                    style={{ backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => {
                      if (cart.paid > 0) {
                        message.warning("Delete the applied payment method to continue.");
                        return;
                      }
                      if (cart.items.length > 0 && localStorage.getItem("dineIn") === "N") {
                        message.warning("Cart contains items. Please complete or clear the order before switching the section!");
                        return;
                      }
                      let posConfig = JSON.parse(localStorage.getItem("posConfig"));
                      if (posConfig.shiftClose === "Y") {
                        if (posConfig.showDenominations === "Y") {
                          history.push("/close-till");
                        } else if (posConfig.showDenominations === "N") {
                          history.push("/close-till-all");
                        }
                      } else {
                        history.push("/");
                      }
                      handleKeyvalue("8");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={Logout} style={{ height: "7vh" }} />
                    </div>
                  </button>
                </div>
              </Col>
            </div>

            <div style={{ width: "100%" }}>
              {keyValue === "0" ? (
                posConfig.enableDineIn === "Y" ? (
                  <TableManagement
                    {...tableLayoutProps}
                    categorieFlag={categorieFlag}
                    setCategorieFlag={setCategorieFlag}
                    addDefinedProductWithAddons={addDefinedProductWithAddons}
                    restaurantProductCategory={restaurantProductCategory}
                    productsData={productsData}
                    pickProduct={pickProduct}
                    increaseProductQty={increaseProductQty}
                    decreaseProductQty={decreaseProductQty}
                    deleteProduct={deleteProduct}
                    setDisplayCustomerSearch={setDisplayCustomerSearch}
                    handleVoid={handleVoid}
                    paymentVisible={paymentVisible}
                    setPaymentVisible={setPaymentVisible}
                    cart={cart}
                    setCart={setCart}
                    deleteCart={deleteCart}
                    setKeyValue={setKeyValue}
                    handleOrder={handleOrder}
                    showProducts={showProducts}
                    setShowProducts={setShowProducts}
                    tableCards={tableCards}
                    setTableCards={setTableCards}
                    occupiedDataTablesData={occupiedDataTablesData}
                    setOcupiedTablesData={setOcupiedTablesData}
                    reservedTablesData={reservedTablesData}
                    setReservedTablesData={setReservedTablesData}
                    setProductsData={setProductsData}
                    saleTypeProducts={saleTypeProducts}
                    setSaleTypeProducts={setSaleTypeProducts}
                    requestPayment={requestPayment}
                    showOrderCompleteFlag={showOrderCompleteFlag}
                    setShowOrderCompleteFlag={setShowOrderCompleteFlag}
                    handleCashPayment={handleCashPayment}
                  />
                ) : posConfig.enableTakeAway === "Y" ? (
                  <MenuLayout {...tableLayoutProps} categorieFlag={categorieFlag} setCategorieFlag={setCategorieFlag} setProductsData={setProductsData} />
                ) : (
                  <HomeDeliveryComponent
                    {...tableLayoutProps}
                    categorieFlag={categorieFlag}
                    setCategorieFlag={setCategorieFlag}
                    addDefinedProductWithAddons={addDefinedProductWithAddons}
                    restaurantProductCategory={restaurantProductCategory}
                    productsData={productsData}
                    pickProduct={pickProduct}
                    increaseProductQty={increaseProductQty}
                    decreaseProductQty={decreaseProductQty}
                    deleteProduct={deleteProduct}
                    setDisplayCustomerSearch={setDisplayCustomerSearch}
                    handleVoid={handleVoid}
                    setPaymentVisible={setPaymentVisible}
                    showProducts={showProducts}
                    setShowProducts={setShowProducts}
                    isSaleTypeFlag={isSaleTypeFlag}
                    setIsSaleTypeFlag={setIsSaleTypeFlag}
                    setProductsData={setProductsData}
                  />
                )
              ) : keyValue === "3" ? (
                <OrderListComponent {...tableLayoutProps} />
              ) : keyValue === "5" ? (
                <CashManagement {...tableLayoutProps} />
              ) : keyValue === "8" ? (
                <CustomersComponent {...tableLayoutProps} />
              ) : keyValue === "2" ? (
                <MenuLayout
                  {...tableLayoutProps}
                  categorieFlag={categorieFlag}
                  setCategorieFlag={setCategorieFlag}
                  addDefinedProductWithAddons={addDefinedProductWithAddons}
                  cart={cart}
                  setCart={setCart}
                  setProductsData={setProductsData}
                />
              ) : keyValue === "1" ? (
                <HomeDeliveryComponent
                  {...tableLayoutProps}
                  categorieFlag={categorieFlag}
                  setCategorieFlag={setCategorieFlag}
                  addDefinedProductWithAddons={addDefinedProductWithAddons}
                  restaurantProductCategory={restaurantProductCategory}
                  productsData={productsData}
                  pickProduct={pickProduct}
                  increaseProductQty={increaseProductQty}
                  decreaseProductQty={decreaseProductQty}
                  deleteProduct={deleteProduct}
                  setDisplayCustomerSearch={setDisplayCustomerSearch}
                  handleVoid={handleVoid}
                  setPaymentVisible={setPaymentVisible}
                  showProducts={showProducts}
                  setShowProducts={setShowProducts}
                  isSaleTypeFlag={isSaleTypeFlag}
                  setIsSaleTypeFlag={setIsSaleTypeFlag}
                  setProductsData={setProductsData}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
      <RestaurantModals
        displayAddOnSelection={displayAddOnSelection}
        handleAddOnModalClose={handleAddOnModalClose}
        addOnsList={addOnsList}
        selectedProduct={selectedProduct}
        selectedAddons={selectedAddons}
        handleAddOnValue={handleAddOnValue}
        currencyType={currencyType}
        selectedRowKeys={selectedRowKeys}
        selectedProductInCart={selectedProductInCart}
        decreaseProductQty={decreaseProductQty}
        handleQty={handleQty}
        increaseProductQty={increaseProductQty}
        handleAdd={handleAdd}
        selectedItemsModalOpen={selectedItemsModalOpen}
        setSelectedItemsModalOpen={setSelectedItemsModalOpen}
        selectProductInCart={selectProductInCart}
        handleDisplayOk={handleDisplayOk}
        displayOrderItemsModal={displayOrderItemsModal}
        orderStatus={orderStatus}
        selectedOrderStatus={selectedOrderStatus}
        handleOrderStatusSelection={handleOrderStatusSelection}
        handleOrderStatus={handleOrderStatus}
        setDisplayOrderItemsModal={setDisplayOrderItemsModal}
        orderDetails={orderDetails}
        handleStatusButton={handleStatusButton}
        barcodeOrderStatusModal={barcodeOrderStatusModal}
        setBarcodeOrderStatusModal={setBarcodeOrderStatusModal}
        barcodeOrderList={barcodeOrderList}
        handleBarcodeOrderPrint={handleBarcodeOrderPrint}
        handleAccept={handleAccept}
        cart={cart}
        tillData={tillData}
        openPaymentModal={openPaymentModal}
        billConfirmationModalOpen={billConfirmationModalOpen}
        setBillConfirmationModalOpen={setBillConfirmationModalOpen}
        // managementScreenShow={managementScreenShow}
        // setManagementScreenShow={setManagementScreenShow}
        parkedList={parkedList}
        openDisplayParkedBillModal={openDisplayParkedBillModal}
        orderTypeSelection={orderTypeSelection}
        manualDiscountModalVisible={manualDiscountModalVisible}
        setManualDiscountInput={setManualDiscountInput}
        setManualDiscountModalVisible={setManualDiscountModalVisible}
        // manualDiscountTypes={manualDiscountTypes}
        // setSelectedManualDiscountType={setSelectedManualDiscountType}
        // enableManualDiscountInput={enableManualDiscountInput}
        // manualDiscountInput={manualDiscountInput}
        // applyManualDiscount={applyManualDiscount}
        // removeAllDiscounts={removeAllDiscounts}
        // handleManualDiscountKeyPress={handleManualDiscountKeyPress}
        // loyalityOtpModalVisible={loyalityOtpModalVisible}
        // setLoyalityOtpModalVisible={setLoyalityOtpModalVisible}
        // setLoyaltyPaymentOtp={setLoyaltyPaymentOtp}
        // processOtpInput={processOtpInput}
        // loyaltyPaymentOtp={loyaltyPaymentOtp}
        notesModalOpen={notesModalOpen}
        setNotesModalOpen={setNotesModalOpen}
        handleCancelOrder={handleCancelOrder}
        // processLayaway={processLayaway}
      />
      <RetailModals {...props} />
    </Spin>
  );
};
export default FBLayout;
