import axios from "axios";
import moment from "moment";
import db from "../database";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../constants/oAuthValidation";

const authHeaders = getOAuthHeaders() || {}; // Ensure it's at least an empty object
const { access_token } = authHeaders;

if (access_token) {
    console.log("Token exists:", access_token);
} else {
    // Prevent infinite redirect loop
    if (window.location.pathname !== '/sign-in') {
        window.location.assign('/sign-in'); // No #
    }
}

const genericUrl = process.env.REACT_APP_genericUrl;
// let tillId;
const genericInstance = axios.create();
genericInstance.defaults.baseURL = genericUrl;
genericInstance.defaults.method = "POST";
genericInstance.defaults.headers.post["Content-Type"] = "application/json";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

genericInstance.interceptors.request.use(
    (config) => {
        const authHeaders = getOAuthHeaders() || {}; // Fetch token dynamically
        const { access_token } = authHeaders;

        if (access_token) {
            config.headers.Authorization = `${access_token}`;
        }
        config.async = true;
        config.crossDomain = true;
        return config;
    },
    (error) => Promise.reject(error)
);


genericInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const { message } = JSON.parse(JSON.stringify(error));
        if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
            //   generateToken();
        } else {
            return Promise.reject(error);
        }
    }
);


const syncDocTypes = async () => {
    await db.docTypesData.clear();
    const docTypeQuery = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"66f407348e3f1f618d89f888", params: "{}")} `,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${authHeaders.access_token}`,
    };
    try {
        const docTypesResponse = await genericInstance({
            data: docTypeQuery,
          });
    //   const docTypesResponse = await Axios.post(CWCoreServicesUrl, docTypeQuery, { headers: headers }, { async: true }, { crossDomain: true });
      if (docTypesResponse.status === 200) {
        let docTypesData = JSON.parse(docTypesResponse?.data?.data?.executeAPIBuilder);
        await db.docTypesData.bulkAdd(docTypesData);
        console.log("doc type data: ", " Synced");
      }
    } catch (error) {
      console.error("Error fetching doc types:", error);
      if (error.response) {
        console.log("Error data:", error.response.data);
        console.log("Status:", error.response.status);
        console.log("Headers:", error.response.headers);
      } else if (error.request) {
        console.log("Request error:", error.request);
      } else {
        console.log("Error message:", error.message);
      }
    }
  };

  const syncRFIDData = async (syncId) => {
    const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
    let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
    const value = { tag_value: "", lastSyncTime: `${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}` };
    const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
    const newStringRequest = JSON.stringify(newStringifiedFields);
    const todaySalesInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"64d323e6291b3b33ff234dd9", params: ${newStringRequest}) }`,
    };
  
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericInstance({
          data: todaySalesInput,
        });
  
        const executeAPIBuilder = response.data.data.executeAPIBuilder;
        const newResponse = JSON.parse(executeAPIBuilder) || [];
  
        if (newResponse.length > 0) {
          let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          if (lastUpdatedTime) {
            let recordsData = 0;
            await Promise.all(
              newResponse.map(async (item) => {
                const existingTag = await db.rfidData.where("tag_value").equals(item.tag_value).toArray();
                if (existingTag.length > 0) {
                  recordsData = recordsData + 1;
                  await db.rfidData.update(item.tag_value, item);
                } else {
                  recordsData = recordsData + 1;
                  await db.rfidData.add(item);
                }
              })
            );
            db.dataSyncSummary
              .where("syncId")
              .equals(syncId)
              .modify((event) => {
                event.type = { ...event.type, rfidData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
                event.records = (event.records ? event.records : 0) + recordsData;
              });
            if (latestIncrementalSync?.length > 0) {
              db.dataSyncSummary
                .where("syncId")
                .equals(latestIncrementalSync[0].syncId)
                .modify((event) => {
                  event.type = {
                    ...event.type,
                    rfidData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.rfidData },
                  };
                });
            }
          } else {
            await db.rfidData.bulkPut(newResponse);
            db.dataSyncSummary
              .where("syncId")
              .equals(syncId)
              .modify((event) => {
                event.type = { ...event.type, rfidData: { syncStartTime: syncStartTime, syncEndTime, records: newResponse.length } };
                event.records = (event.records ? event.records : 0) + newResponse.length;
              });
          }
          console.log("RFID Data: ", "Synced");
        } else {
          console.log("RFID Data: No new data to sync");
        }
        resolve(); // Resolve the Promise after successful execution
      } catch (error) {
        console.log("RFID Data: Sync Failed", error);
        reject(error); // Reject the Promise if an error occurs
      }
    });
  };

   const syncRFIDScanConfig = async (tillData) => {
    const value = {};
    const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
    const newStringRequest = JSON.stringify(newStringifiedFields);
    const todaySalesInput = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"670520c68a26395542b489ee", params: ${newStringRequest}) }`,
    };
  
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericInstance({
          data: todaySalesInput,
        });
        
        const executeAPIBuilder = response?.data?.data?.executeAPIBuilder;
        localStorage.setItem("posScanConfigurations", executeAPIBuilder || []);
        resolve(); // Resolve the Promise after successful execution
      } catch (error) {
        console.log("RFID Data: Sync Failed", error);
        reject(error); // Reject the Promise if an error occurs
      }
    });
  };
  
   const syncSalesRepData = async () => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const value = { cs_bunit_id: tillData.tillAccess.csBunit.csBunitId };
    const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
    const newStringRequest = JSON.stringify(newStringifiedFields);
    const salesRepData = {
      query: `mutation { executeAPIBuilder(apiBuilderId:"669fa7b4befce80600118eef", params: ${newStringRequest})}`,
    };
  
    return new Promise(async (resolve, reject) => {
      try {
        const response = await genericInstance({
          data: salesRepData,
        });
  
        const executeAPIBuilder = response.data.data.executeAPIBuilder;
        const newResponse = JSON.parse(executeAPIBuilder) || [];
        await db.salesRep.bulkPut(newResponse);
        console.log("Sales Rep Data: Sync Successful", newResponse);
        resolve(); // Resolve the Promise after successful execution
      } catch (error) {
        console.log("Sales Rep Data: Sync Failed", error);
        reject(error); // Reject the Promise if an error occurs
      }
    });
  };

  export { syncDocTypes,syncRFIDData,syncRFIDScanConfig,syncSalesRepData}