import React, { useState, useEffect, useMemo } from "react";
import { Modal, Input, Row, Col, Button, Table } from "antd";
import { CloseOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import DeleteIcon from "../../assets/images/delete1.svg";
import { v4 as uuidv4 } from "uuid";
import BarCodeHandler from "../BarcodeHandler";
import { Scrollbars } from "react-custom-scrollbars";

const ProductsPopup = (props) => {
  const { productData, visibleProductsModal, setVisibleProductsModal, selectedItems, setSelectedItems, onSubmit, selectedProduct } = props;
  const itemsPerPage = 10;

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showProductSelection, setShowProductSelection] = React.useState(false);

  // Filter and sort products
  useEffect(() => {
    let filtered = productData.filter((item) => {
      const lowerQuery = searchQuery.toLowerCase();
      const isValidSegment = item.productSegment !== "CB";
      const matchesSearch =
        item.name.toLowerCase().includes(lowerQuery) || item.value.toLowerCase().includes(lowerQuery) || (item.upc && item.upc.toLowerCase().includes(lowerQuery));
      return isValidSegment && matchesSearch;
    });

    filtered.sort((a, b) => {
      const aInCart = selectedItems.find((item) => item.mProductId === a.mProductId);
      const bInCart = selectedItems.find((item) => item.mProductId === b.mProductId);
      const aQty = aInCart ? aInCart.weight : 0;
      const bQty = bInCart ? bInCart.weight : 0;
      return (bQty > 1) - (aQty > 1);
    });

    setFilteredProducts(filtered);
  }, [searchQuery, productData, selectedItems]);

  // Calculate pagination values
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredProducts.slice(startIndex, endIndex);
  }, [filteredProducts, currentPage]);

  // Handle page navigation
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };

  const handleIncrease = (product) => {
    const isInCart = selectedItems.find((item) => item.mProductId === product.mProductId);
    if (isInCart) {
      setSelectedItems(selectedItems.map((item) => (item.mProductId === product.mProductId ? { ...item, weight: Number(item.weight) + 1 } : item)));
    } else {
      setSelectedItems([...selectedItems, { ...product, weight: 1, lineId: uuidv4().replace(/-/g, "").toUpperCase() }]);
    }
  };

  const handleDecrease = (product) => {
    const isInCart = selectedItems.find((item) => item.mProductId === product.mProductId);
    if (isInCart && isInCart.weight > 1) {
      setSelectedItems(selectedItems.map((item) => (item.mProductId === product.mProductId ? { ...item, weight: Number(item.weight) - 1 } : item)));
    } else if (isInCart && isInCart.weight <= 1) {
      setSelectedItems(selectedItems.filter((item) => item.mProductId !== product.mProductId));
    }
  };

  const handleQuantityChange = (product, value) => {
    const numValue = value === "" ? 0 : Number(value);
    const isInCart = selectedItems.find((item) => item.mProductId === product.mProductId);

    if (numValue <= 0 && isInCart) {
      setSelectedItems(selectedItems.filter((item) => item.mProductId !== product.mProductId));
    } else if (numValue > 0 && isInCart) {
      setSelectedItems(selectedItems.map((item) => (item.mProductId === product.mProductId ? { ...item, weight: numValue } : item)));
    } else if (numValue > 0 && !isInCart) {
      setSelectedItems([...selectedItems, { ...product, weight: numValue, lineId: uuidv4().replace(/-/g, "").toUpperCase() }]);
    }
  };

  const handleDelete = (itemToDelete) => {
    setSelectedItems(selectedItems.filter((item) => item.mProductId !== itemToDelete.mProductId));
  };

  const handleSubmit = () => {
    onSubmit(selectedItems);
    setVisibleProductsModal(false);
  };

  return (
    <Modal
      visible={visibleProductsModal}
      centered={true}
      width={"68%"}
      closable={true} // Enable closable
      closeIcon={
        <CloseOutlined
          style={{ color: "#2F3856", fontSize: "16px" }}
          onClick={() => {
            setVisibleProductsModal(false);
            localStorage.removeItem("productSegment");
          }}
        />
      } // Custom close icon
      footer={null}
      // onCancel={() => setVisibleProductsModal(false)}
    >
      <BarCodeHandler setSelectedItems={setSelectedItems} />
      {showProductSelection ? (
        <div style={{ padding: "5vh 0 0 0", height: "85vh", position: "relative" }}>
          <Input
            placeholder="Search Item Code / Description"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(0);
            }}
            style={{
              borderRadius: "7px",
              padding: "0.8vh 0.8vw",
              border: "1px solid #d9d9d9",
              width: "100%",
            }}
          />

          <Row style={{ padding: "2vh 0" }} gutter={[16, 16]}>
            {paginatedData.length > 0 ? (
              paginatedData.map((item, index) => {
                const cartItem = selectedItems.find((cartItem) => cartItem.mProductId === item.mProductId);
                const stdPrecision = item?.uomData?.[0]?.stdprecision ?? 0;
                const uomName = item?.uomData?.[0]?.name ?? "";
                const quantity = cartItem ? cartItem.weight : 0;

                return (
                  <Col key={index} span={12} style={{ padding: "0 8px" }}>
                    <Row style={{ padding: "1.5%", width: "100%", border: "1px solid #d9d9d9", height: "10vh" }}>
                      <Col span={12}>
                        <p
                          title={item.name}
                          style={{
                            margin: 0,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          {item.name}
                        </p>
                        <p style={{ margin: 0 }}>
                          {item.sunitprice} - {uomName}
                        </p>
                      </Col>
                      <Col span={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Button
                            onClick={() => handleDecrease(item)}
                            style={{
                              backgroundColor: "#2F3856",
                              color: "#fff",
                              width: "2.5vw",
                              height: "4vh",
                            }}
                            icon={<MinusOutlined />}
                          />
                          <Input
                            type="number"
                            min={0}
                            step={stdPrecision > 0 ? 0.1 ** stdPrecision : 0.1}
                            value={quantity}
                            onChange={(e) => handleQuantityChange(item, e.target.value)}
                            style={{
                              width: "60px",
                              height: "4vh",
                              // textAlign: "center",
                            }}
                            onBlur={(e) => {
                              const newValue = Number(e.target.value) || 0;
                              handleQuantityChange(item, newValue.toFixed(stdPrecision));
                            }}
                          />
                          <Button
                            onClick={() => handleIncrease(item)}
                            style={{
                              backgroundColor: "#2F3856",
                              color: "#fff",
                              width: "2.5vw",
                              height: "4vh",
                            }}
                            icon={<PlusOutlined />}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <p style={{ textAlign: "center", width: "100%" }}>No products found</p>
            )}
          </Row>

          <div style={{ position: "absolute", bottom: "0vh", width: "100%", padding: "1vh 0" }}>
            <Row align="middle" justify="space-between">
              <Col>
                <Button
                  disabled={currentPage === 0}
                  onClick={handlePreviousPage}
                  style={{
                    textAlign: "center",
                    backgroundColor: currentPage === 0 ? "#ccc" : "#2F3856",
                    width: "4vw",
                    height: "6.5vh",
                    color: "#fff",
                    borderRadius: "5px",
                    marginRight: "1vw",
                  }}
                >
                  {"<"}
                </Button>
                <span style={{ fontWeight: "bold", margin: "0 1vw" }}>
                  Page {currentPage + 1} of {totalPages || 1}
                </span>
                <Button
                  disabled={currentPage >= totalPages - 1}
                  onClick={handleNextPage}
                  style={{
                    textAlign: "center",
                    backgroundColor: currentPage >= totalPages - 1 ? "#ccc" : "#2F3856",
                    width: "4vw",
                    height: "6.5vh",
                    color: "#fff",
                    borderRadius: "5px",
                    marginLeft: "1vw",
                  }}
                >
                  {">"}
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => setShowProductSelection(false)}
                  style={{
                    backgroundColor: "#2F3856",
                    color: "#fff",
                    width: "8vw",
                    height: "6.5vh",
                    borderRadius: "5px",
                    marginRight: "1vw",
                  }}
                  disabled={selectedItems.length === 0}
                >
                  Okay
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div style={{ height: "81vh", position: "relative" }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: "2vh", marginTop: "3vh" }}>
            <Col>
              <h3>{selectedProduct?.name || ""}</h3>
            </Col>
            <Col>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowProductSelection(true)} style={{ backgroundColor: "#2F3856", borderColor: "#2F3856" }}>
                Add Products
              </Button>
            </Col>
          </Row>
          <Row style={{ padding: "0.1rem 0.75rem", fontSize: "0.75rem", fontWeight: 600, color: "#2F3856", borderBottom: "1px solid #e8e8e8", marginBottom: "0.5rem" }}>
            <Col span={6}>Name</Col>
            <Col span={4} style={{ textAlign: "right" }}>
              Unit Price
            </Col>
            <Col span={5} style={{ textAlign: "center" }}>
              Qty
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              Total Qty
            </Col>
            <Col span={5} style={{ textAlign: "center" }}>
              Total Price
            </Col>
            <Col span={2}></Col>
          </Row>
          <Scrollbars style={{ height: "60vh" }}>
            {selectedItems.map((item) => {
              const unitPrice = parseFloat(item.sunitprice) || 0;
              const quantity = parseFloat(item.weight * (selectedProduct.weight || 1)) || 0;
              const totalPrice = unitPrice * quantity;
              return (
                <Row
                  key={item.id || item.name}
                  style={{
                    padding: "0.5rem 0.9rem",
                    width: "100%",
                    marginBottom: "0.4rem",
                    height: "auto",
                    alignItems: "center",
                  }}
                >
                  <Col span={6}>
                    <p
                      title={item.name}
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        color: "#333",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {item.name}
                    </p>
                  </Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        color: "#2F3856",
                      }}
                    >
                      {unitPrice.toLocaleString("en-IN")}
                    </p>
                  </Col>
                  <Col span={5} style={{ textAlign: "center" }}>
                    {/* <p
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        color: "#2F3856",
                      }}
                    >
                      {item.weight}
                    </p> */}
                    <Input
                      type="number"
                      min={0}
                      value={item.weight}
                      onChange={(e) => handleQuantityChange(item, e.target.value)}
                      style={{
                        width: "60px",
                        height: "25px",
                        textAlign: "center",
                        fontSize: "0.8rem",
                      }}
                      onBlur={(e) => {
                        const newValue = Number(e.target.value) || 0;
                        handleQuantityChange(item, newValue.toString());
                      }}
                    />
                  </Col>
                  <Col span={2} style={{ textAlign: "right" }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        color: "#2F3856",
                      }}
                    >
                      {quantity}
                    </p>
                  </Col>
                  <Col span={5} style={{ textAlign: "center" }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.8rem",
                        color: "#2F3856",
                      }}
                    >
                      {totalPrice.toLocaleString("en-IN")}
                    </p>
                  </Col>
                  <Col span={2} style={{ textAlign: "center" }}>
                    <img
                      src={DeleteIcon}
                      style={{
                        height: "2.5vh",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(item)}
                    />
                  </Col>
                </Row>
              );
            })}
          </Scrollbars>
          <Button
            style={{
              position: "absolute",
              bottom: "0.5rem",
              right: "0.5rem",
              backgroundColor: "#2F3856",
              color: "#fff",
              width: "7vw",
              height: "4.5vh",
              borderRadius: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
              fontSize: "0.85rem",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ProductsPopup;
