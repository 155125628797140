import React, { useState, useEffect } from "react";
import { Spin, Card, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import { serverUrl } from "../../constants/config";
import { useHistory } from "react-router-dom";
import Axios from "axios";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import dashboardBG from "../../assets/images/dashboard_newbg.png";
import Products from "../../assets/images/dairy-product.svg";
import Category from "../../assets/images/category.svg";
import Warehouse from "../../assets/images/warehouse.svg";
import Offers from "../../assets/images/offers.svg";
import Price from "../../assets/images/currency.svg";
import CheckedTick from "../../assets/images/checkedTick.svg";
import db from "../../database";
import data from "../../constants/sync.json";
// import DefaultProductImage from "../../assets/images/default-product.webp";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import "../style.css";

const Sync = () => {
  const dynamicHeight = 0.5625;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingProductCategories, setLoadingProductCategories] = useState(true);
  const [loadingProductPricingRules, setLoadingProductPricingRules] = useState(true);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const username = localStorage.getItem("username");
  let cleanToken;
  const history = useHistory();

  useEffect(() => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const limit = 5000;
    let from = 0;
    const authHeaders = getOAuthHeaders();
    if (authHeaders) {
      cleanToken = authHeaders.access_token;
    }

    const processSync = async () => {
      try {
        await deletePreviousData();
        await syncProductCategories();
        await syncPosSaleTypes();
        await syncProductsUom();
        await syncPricingRules();
        await syncProducts();
      } catch (error) {
        console.log(error);
      }
    };

    const deletePreviousData = () => {
      return new Promise(async (deletionSuccess, deletionFailure) => {
        try {
          await db.productCategories.clear();
          await db.restaurantTables.clear();
          await db.products.clear();
          await db.pricingRules.clear();
          await db.posSaletypes.clear();
          await db.productUom.clear();
          deletionSuccess();
        } catch (error) {
          deletionFailure(error);
        }
      });
    };

    const syncProductsUom = () => {
      return new Promise(async (uomSyncSuccess, uomSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getUom{
                csUomId
                costingprecision
                description
                ediCode
                name
                stdprecision
                symbol
              }
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });
          const { getUom } = response.data.data;
          if (response.status === 200 && getUom.length > 0) {
            const lastId = await db.productUom.bulkAdd(getUom);
            console.log("POS Uom: ", " Synced");
            uomSyncSuccess(lastId);
          } else {
            uomSyncSuccess();
            console.log("POS Uom: ", getUom);
          }
        } catch (error) {
          console.log("POS Uom: ", " Sync Failed");
          uomSyncFailure(error);
        }
      });
    };

    const syncPosSaleTypes = () => {
      return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getPosSaletype(catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}"){
                cwrPcatalogueSaletypeId
                cSClientID
                cSBunitID
                created
                createdby
                updated
                updatedby
                isactive
                isPromoApplicable
                cwrPCatalogueId
                cwrSaletype{
                  cwrSaletypeId
                  value
                  name
                  isdefault
                }
              }
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });
          const { getPosSaletype } = response.data.data;
          if (response.status === 200 && getPosSaletype.length > 0) {
            const lastId = await db.posSaletypes.bulkAdd(getPosSaletype);
            console.log("POS Sale Types: ", " Synced");
            posSaleTypeSyncSuccess(lastId);
          } else {
            posSaleTypeSyncSuccess();
          }
        } catch (error) {
          console.log("POS Sale Types: ", " Sync Failed");
          posSaleTypeSyncFailure(error);
        }
      });
    };

    const syncProductCategories = () => {
      localStorage.setItem("sync1", true);
      return new Promise(async (productCategorySyncSuccess, productCategorySyncFailure) => {
        try {
          const paramsInput = {
            query: `query {
            productCategory(bunit:"${tillData.tillAccess.csBunit.csBunitId}"){
            mProductCategoryId
            value
            name
            description
            imageurl
            }
          }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });
          const { productCategory } = response.data.data;
          if (response.status === 200 && productCategory.length > 0) {
            const lastId = await db.productCategories.bulkAdd(productCategory);
            syncRestaurantTables();
            console.log("Product Category: ", " Synced");
            productCategorySyncSuccess(lastId);
          } else {
            productCategorySyncSuccess();
          }
        } catch (error) {
          setLoadingProductCategories(false);
          console.log("Product Category: ", " Sync Failed");
          productCategorySyncFailure(error);
        }
      });
    };

    const syncRestaurantTables = () => {
      return new Promise(async (restaurantTablesSuccess, restaurantTablesFailure) => {
        try {
          const paramsInput = {
            query: `query{
              getRestaurantTables(tillId:"${tillData.tillAccess.cwrTill.cwrTillID}"){
                cwrFbsectionId
                sectionName
                posTables{
                  cwrFbTableId  
                  cSBunitID
                  cSClientID
                  created
                  createdby
                  csWindowId
                  isactive
                  updated
                  updatedby
                  name
                  capacity
                  cwrFbFloorId
                }
              }  
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });

          if (response.data.data !== null && response.data.data !== undefined) {
            console.log(response);
            const { getRestaurantTables } = response?.data?.data;
            if (response.status === 200 && getRestaurantTables.length > 0) {
              const lastId = await db.restaurantTables.bulkAdd(getRestaurantTables);
              setLoadingProductCategories(false);
              restaurantTablesSuccess(lastId);
            } else {
              restaurantTablesSuccess();
            }
          }
        } catch (error) {
          setLoadingProductCategories(false);
          restaurantTablesFailure(error);
        }
      });
    };

    const syncPricingRules = () => {
      return new Promise(async (pricingRulesSyncSuccess) => {
        try {
          const paramsInput = {
            query: `query {
              getPricingRules(catalogueId: "${tillData.tillAccess.csBunit.cwrPcatalogueId}") {
                mPricingrulesId
                csClientId
                csBunitId
                created
                createdBy
                updated
                updatedBy
                type
                discountType
                name
                printedName
                description
                startDate
                endDate
                nextRule
                percentageDiscount
                amountDiscount
                minimumQty
                maximumQty
                xQty
                yQty
                billAmount
                maxBillAmount
                cwrSaletypeId
                fixedUnitPrice
                timeSpecific
                starttime
                endtime
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
                status
                iscoupon
                giftVoucherType
		            issueGiftVoucher
                manualDiscount
                mPricingXProducts {
                  mPricingXProductId
                  line
                  mProductId
                  mBatchId
                  isFree
                }
                mPricingYProducts {
                  mPricingYProductId
                  line
                  mProductId
                }
            
                mPricingCcategories {
                  mPricingCcategoryId
                  line
                  sCustomerCategoryId
                }
            
                mPricingPcategories {
                  mPricingPcategoryId
                  line
                  mProductCategoryId
                }
            
                mPricingBrands {
                  mPricingBrandId
                  line
                  mBrandId
                }
                mPricingBUnits {
                  mPricingBUnitId
                  line
                  mBunitPricingId
                }
                mPricingQuantities {
                  mPricingQuantityId
                  line
                  quantity
                  discountValue
                }
              }
            }`,
          };
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });
          const { getPricingRules } = response.data.data;
          if (response.status === 200 && getPricingRules.length > 0) {
            const lastId = await db.pricingRules.bulkAdd(getPricingRules);
            setLoadingProductPricingRules(false);
            console.log("Product Pricing Rules: ", " Synced");
            pricingRulesSyncSuccess(lastId);
          } else {
            setLoadingProductPricingRules(false);
            console.log("Product Pricing Rules: ", getPricingRules);
            pricingRulesSyncSuccess();
          }
        } catch (error) {
          setLoadingProductPricingRules(false);
          console.log("Product Pricing Rules: ", " Sync Failed");
          pricingRulesSyncSuccess();
        }
      });
    };

    const syncProducts = () => {
      return new Promise(async (productSyncSuccess, productSyncFailure) => {
        try {
          const paramsInput = {
            query: `query {
              getProducts1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}" from:${from} limit:${limit}) {
                mProductId
                csClientId
                csBunitId
                created
                createdby
                updated
                isactive
                updatedby
                sunitprice
                slistprice
                onhandQty
                description
                isVirtual
                isBestSeller
                cTaxId
                taxRate
                isPromoApplicable
                isavailable
                ismonday
                istuesday
                iswednesday
                isthursday
                isfriday
                issaturday
                issunday
                colorcode
                productionCenter
                cwrMenuTimeslot {
                  cwrMenuTimeslotId
                  name
                  startTime
                  endTime
                }
                mProduct {
                  value
                  name
                  name2
                  csTaxcategoryId
                  mProductCategoryId
                  csUomId
                  uomName
                  upc
                  batchedProduct
                  isManualQty
                  isDecimal
                  imageurl
                  shortDescription
                  hsncode
                  returnable
                  returnDays
                  description
                  batchedForSale
                  batchedForStock
                  multiPrice
                  shelfLife
                  taxCategory {
                    csTaxcategoryID
                    name
                    overRideTax
                    overRideCondition
                    contraTaxCategoryId
                    contraTaxCategory
                    {
                      contraTaxCategoryName
                      contraTaxId
                      contraTaxName
                      contraRate
                    }
                  }
                  mBatch {
                    mBatchId
                    batchno
                    upc
                    price
                    listPrice
                    startdate
                    enddate
                    life
                  }
                  productGroup {
                    mProductGroupId
                    name
                    value
                    description
                  }
                  productAddons {
                    mProductAddOnId
                    name
                    price
                    mAddonGroup {
                      mAddonGroupId
                      name
                      maxqty
                      minqty
                      type
                    }
                    mAddonProduct {
                      mProductId
                      name
                    }
                  }
                  productAttributes {
                    mProductAttributeId
                    value
                    mAttributeGroup {
                      mAttributeGroupId
                      name
                    }
                    mAttribute {
                      mAttributeId
                      name
                    }
                  }
                  productManufacturer {
                    mProductManufacturerId
                    name
                    value
                    description
                  }
                  productBrand {
                    brandId
                    name
                    value
                    description
                  }
                }
              }
            }`,
          };
          from += 5000;
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${cleanToken}`,
            },
          });
          const { getProducts1 } = response.data.data;
          const products = getProducts1;
          const productsLength = products.length;
          if (productsLength > 0) {
            const productsData = [];
            for (let i = 0; i < productsLength; i += 1) {
              const upcIndex = [];
              const batchIndex = [];
              if (products[i].mProduct.mBatch !== null) {
                for (let j = 0; j < products[i].mProduct.mBatch.length; j += 1) {
                  batchIndex.push(products[i].mProduct.mBatch[j].batchno);
                  upcIndex.push(products[i].mProduct.mBatch[j].upc);
                }
              }
              if (products[i].mProduct.upc !== null) {
                upcIndex.push(products[i].mProduct.upc);
              }
              const productDataObj = {
                mProductId: products[i].mProductId,
                csClientId: products[i].csClientId,
                csBunitId: products[i].csBunitId,
                created: products[i].created,
                createdby: products[i].createdby,
                updated: products[i].updated,
                isactive: products[i].isactive,
                updatedby: products[i].updatedby,
                sunitprice: products[i].sunitprice,
                slistprice: products[i].slistprice,
                onhandQty: products[i].onhandQty,
                description: products[i].description,
                shortDescription: products[i].mProduct.shortDescription,
                isVirtual: products[i].isVirtual,
                isBestSeller: products[i].isBestSeller,
                cTaxId: products[i].cTaxId,
                taxRate: products[i].taxRate,
                isPromoApplicable: products[i].isPromoApplicable,
                value: products[i].mProduct.value,
                name: products[i].mProduct.name,
                name2: products[i].mProduct.name2,
                csTaxcategoryId: products[i].mProduct.csTaxcategoryId,
                mProductCategoryId: products[i].mProduct.mProductCategoryId,
                csUomId: products[i].mProduct.csUomId,
                uomName: products[i].mProduct.uomName,
                upc: products[i].mProduct.upc,
                batchedProduct: products[i].mProduct.batchedProduct,
                batchedForSale: products[i].mProduct.batchedForSale,
                batchedForStock: products[i].mProduct.batchedForStock,
                isManualQty: products[i].mProduct.isManualQty,
                isDecimal: products[i].mProduct.isDecimal,
                imageurl: products[i].mProduct.imageurl,
                // imageurl: DefaultProductImage,
                taxCategory: products[i].mProduct.taxCategory.name,
                mBatch: products[i].mProduct.mBatch,
                hsncode: products[i].mProduct.hsncode,
                batchIndex: batchIndex,
                upcIndex: upcIndex,
                productManufacturerName: products[i].mProduct.productManufacturer.name,
                productManufacturerId: products[i].mProduct.productManufacturer.mProductManufacturerId,
                productBrandName: products[i].mProduct.productBrand.name,
                productBrandId: products[i].mProduct.productBrand.brandId,
                iscustomizable: products[i].mProduct?.iscustomizable,
                mProductGroupId: products[i]?.mProduct?.productGroup?.mProductGroupId || "",
                productCategoryName: products[i]?.mProduct?.productGroup?.name || "",
                productAddons: products[i]?.mProduct?.productAddons || [],
                multiPrice: products[i].mProduct.multiPrice,
                shelfLife: products[i].mProduct.shelfLife,
                overRideTax: products[i].mProduct.taxCategory.overRideTax,
                overRideCondition: parseFloat(products[i].mProduct.taxCategory.overRideCondition),
                contraRate: parseFloat(products[i].mProduct.taxCategory.contraTaxCategory?.contraRate),
                contraTaxId: products[i].mProduct.taxCategory.contraTaxCategory?.contraTaxId,
              };
              productsData.push(productDataObj);
            }
            const lastId = await db.products.bulkAdd(productsData);
            console.log("Synced products count: ", lastId);
            syncProducts();
          } else {
            setLoadingProducts(false);
            console.log("Products: ", " Synced");
            productSyncSuccess();
            history.push("/dashboard");
          }
        } catch (error) {
          setLoadingProducts(false);
          console.log("Products : ", " Sync Failed");
          productSyncFailure(error);
        }
      });
    };

    processSync();
  }, [history]);

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const antIcon = <LoadingOutlined style={{ fontSize: data.syncStyles.fontSize }} spin />;

  let sectionStyle = {
    width: data.syncStyles.sectionStyle.width,
    height: data.syncStyles.sectionStyle.height,
    backgroundImage: `url(${dashboardBG})`,
    backgroundPosition: data.syncStyles.sectionStyle.backgroundPosition,
    backgroundSize: data.syncStyles.sectionStyle.backgroundSize,
    backgroundRepeat: data.syncStyles.sectionStyle.backgroundRepeat,
    position: data.syncStyles.sectionStyle.position,
  };

  return (
    <div>
      <div style={data.syncStyles.header}>
        <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
          <Col span={3}>
            <img src={POSLogo} style={data.syncStyles.header.logoIcon} alt="" onClick={handlePosLogo} />
          </Col>
          <Col span={6}>
            <label style={data.syncStyles.header.headLabel}>{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label style={data.syncStyles.syncInfo}>{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={7} style={data.syncStyles.right}>
            <label style={data.syncStyles.syncGreet}>Hi, {username} &emsp;</label>
          </Col>
        </Row>
      </div>
      <div style={sectionStyle}>
        <Row style={{ padding: data.syncStyles.padding }} gutter={[24, 24]}>
          <Col xs={{ span: 12 }} lg={{ span: 4 }} />
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Card bodyStyle={{ padding: 5 }} style={data.syncStyles.cardSyncType}>
              <Spin indicator={antIcon} spinning={loadingProducts}>
                <Row
                  justify="end"
                  style={{
                    visibility: loadingProducts === false ? "visible" : "hidden",
                  }}
                >
                  <img style={data.syncStyles.iconStyl} src={CheckedTick} alt="" />
                </Row>
                <Row justify="center">
                  <img style={data.syncStyles.imgSyncType} src={Products} alt="" />
                </Row>
                <Row justify="center">
                  <label style={data.syncStyles.syncLabel}>Products</label>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Card bodyStyle={{ padding: 5 }} style={data.syncStyles.cardSyncType}>
              <Spin indicator={antIcon} spinning={loadingProductCategories}>
                <Row
                  justify="end"
                  style={{
                    visibility: loadingProductCategories === false ? "visible" : "hidden",
                  }}
                >
                  <img style={data.syncStyles.iconStyl} src={CheckedTick} alt="" />
                </Row>
                <Row justify="center">
                  <img style={data.syncStyles.imgSyncType} src={Category} alt="" />
                </Row>
                <Row justify="center">
                  <label style={data.syncStyles.syncLabel}>Product Categories</label>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Card bodyStyle={{ padding: 5 }} style={data.syncStyles.cardSyncType}>
              <Spin indicator={antIcon} spinning={loadingProductCategories}>
                <Row
                  justify="end"
                  style={{
                    visibility: loadingProductCategories === false ? "visible" : "hidden",
                  }}
                >
                  <img style={data.syncStyles.iconStyl} src={CheckedTick} alt="" />
                </Row>
                <Row justify="center">
                  <img style={data.syncStyles.imgSyncType} src={Warehouse} alt="" />
                </Row>
                <Row justify="center">
                  <label style={data.syncStyles.syncLabel}>Warehouse</label>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Card bodyStyle={{ padding: 5 }} style={data.syncStyles.cardSyncType}>
              <Spin indicator={antIcon} spinning={loadingProductPricingRules}>
                <Row
                  justify="end"
                  style={{
                    visibility: loadingProductPricingRules === false ? "visible" : "hidden",
                  }}
                >
                  <img style={data.syncStyles.iconStyl} src={CheckedTick} alt="" />
                </Row>
                <Row justify="center">
                  <img style={data.syncStyles.imgSyncType} src={Offers} alt="" />
                </Row>
                <Row justify="center">
                  <label style={data.syncStyles.syncLabel}>Price & Offers</label>
                </Row>
              </Spin>
            </Card>
          </Col>
          {/* <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Card bodyStyle={{ padding: 5 }} style={data.syncStyles.cardSyncType}>
              <Spin indicator={antIcon} spinning={loadingProductCategories}>
                <Row
                  justify="end"
                  style={{
                    visibility: loadingProductCategories === false ? "visible" : "hidden",
                  }}
                >
                  <img style={data.syncStyles.iconStyl} src={CheckedTick} alt="" />
                </Row>
                <Row justify="center">
                  <img style={data.syncStyles.imgSyncType} src={Price} alt="" />
                </Row>
                <Row justify="center">
                  <label style={data.syncStyles.syncLabel}>Price</label>
                </Row>
              </Spin>
            </Card>
          </Col> */}
          <Col xs={{ span: 12 }} lg={{ span: 4 }} />
        </Row>
      </div>
    </div>
  );
};

export default Sync;
