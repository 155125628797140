import React from "react";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import { LoadingOutlined, EnvironmentOutlined, CloseSquareFilled } from "@ant-design/icons";
import SalesHistoryIcon from "../../PointOfSale/Retail/SvgComponent/SalesHistoryIcon";
import POSLogo from "../../../assets/images/NewLogoCW.svg";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import { Row, Col, Input, Table, DatePicker, Button, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import sideMenu from "../../../assets/images/side-menu.svg";
import LockIcon from "../../../assets/images/lock-icon.svg";
import userIcon from "../../../assets/images/userId.svg";
import Online from "../../../assets/images/green.svg";
import Ofline from "../../../assets/images/red.svg";
import FilterIcon from "../../../assets/images/filterNewICon.svg";
import NotesImage from "../../../assets/images/notes.svg";
import DeleteProduct from "../../../assets/images/delete1.svg";
import InventoryHistorySupermarket from "./InventoryHistorySupermarket";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const SuperMarketInventory = (props) => {
  const {
    loading,
    tillData,
    colorVariable,
    addStock,
    setAddStock,
    searchQuery,
    setSearchQuery,
    showArrows,
    scrollLeft1,
    scrollRight,
    scrollContainerRef,
    handleButtonClick,
    selectedCategories,
    categoriesData,
    tableBodyRef,
    setRequestQty,
    requestQty,
    displayedData,
    setSelectedCategories,
    handleAddProducts,
    showHistory,
    setTillLink,
    productionNextNo,
    setShowHistory,
    formattedDateTime,
    setIsLocked,
    userData,
    setProductSearchInput,
    productSearchInput,
    handleRequestQtyChange,
    setShowComments,
    comments,
    setShowTargetLocation,
    setRemarksPopup,
    setRemarksTextArea,
    setSelectedRowIndex,
    setIsInputFocused,
    setWarehouseData,
    selectedWarehouse,
    inventoryOrder,
    setInventoryOrder,
    productionTypeData,
    getInventoryProducts,
    getInventoryCategory,
    getInventoryHistory,
    // posConfig,
  } = props;
  const history = useHistory();
  const authHeaders = getOAuthHeaders();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  let authToken;
  if (authHeaders && authHeaders.access_token) {
    authToken = authHeaders.access_token;
  }

  const deleteInventoryItems = (record) => {
    setInventoryOrder((prevOrder) => {
      const updatedItems = prevOrder.items.filter((item) => item.productId !== record.productId);
      return { ...prevOrder, items: updatedItems };
    });
  };

  const getWarehouseData = async () => {
    try {
      const stringifiedData = JSON.stringify({
        userId: userData?.user_id,
      });
      const newStringifiedFields = stringifiedData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);

      const paramsInput = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"674573339c05362ba20c6406", params: ${newStringRequest})}`,
      };
      const warehouseDataResponse = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authToken}`,
        },
      });
      const parsedData = JSON.parse(warehouseDataResponse.data.data.executeAPIBuilder);
      setWarehouseData(parsedData);
      setShowTargetLocation(true);
    } catch (err) {
      console.log("Error fetching warehouse data", err);
    }
  };

  const submitOrder = async () => {
    try {
      const addedItems = inventoryOrder.items.map((item) => ({
        stockRequestLineID: uuidv4().replace(/-/g, "").toUpperCase(),
        productID: item.productId,
        requestQty: item.requestQty,
        productCode: item.sku,
        productName: item.productName,
        category: item.productCategory,
        remarks: item?.remarks || "",
      }));

      const reqParams = {
        stockRequestID: inventoryOrder.stockRequestID,
        created: inventoryOrder.timestamp,
        createdby: inventoryOrder.user_id,
        createdbyName: inventoryOrder?.name,
        updated: inventoryOrder.timestamp,
        updatedby: inventoryOrder.user_id,
        updatedbyName: inventoryOrder?.name,
        csBunitId: inventoryOrder.csBunitId,
        date: moment().format("YYYY-MM-DD"),
        fromWarehouseId: inventoryOrder.fromWarehouseId,
        toWarehouseId: inventoryOrder.toWarehouseId,
        lines: addedItems,
      };

      const dataLoad = JSON.stringify(reqParams).replace(/"/g, '\\"');

      const mutationQuery = `
        mutation {
          transactionQueue(queue: {
            transactionQueueId: "${inventoryOrder.transactionQueueId}"
            transactionType: "SRQ"
            action: "Process"
            dataLoad: "${dataLoad}"
          }) {
            code
            message
            referenceNo
            recordId
          }
        }
      `;

      const response = await Axios.post(
        serverUrl,
        { query: mutationQuery },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.data.transactionQueue.code === "200") {
        localStorage.removeItem("inventoryOrderData");
        setInventoryOrder({
          items: [],
          stockRequestID: uuidv4().replace(/-/g, "").toUpperCase(),
          transactionQueueId: uuidv4().replace(/-/g, "").toUpperCase(),
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: userData?.user_id,
          name: userData?.name,
          csBunitId: tillData?.tillAccess?.csBunit?.csBunitId,
          fromWarehouseId: tillData?.tillAccess?.csBunit?.mWarehouse?.mWarehouseID,
          indentNo: `${productionTypeData[0]?.prefix || ""}${productionTypeData[0]?.nextno + 1}`,
          toWarehouseId: null,
        });
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      <div style={{ background: colorVariable || "#f3f4f9", height: "100vh" }}>
        {addStock ? (
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "1% 0 0 1.7%",
                }}
              >
                <div style={{ width: "98.3%", display: "flex" }}>
                  <Col span={16}>
                    <Input
                      placeholder="Search Item Code/Description"
                      id="sm-product-search"
                      className="productSearch-sm"
                      style={{
                        borderRadius: "7px",
                        padding: "0.8vh 0.8vw",
                        borderColor: "#fff",
                        width: "100%",
                        height: "6vh",
                      }}
                      value={searchQuery}
                      prefix={<img src={SearchIcon} alt="" />}
                      suffix={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {searchQuery.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => setSearchQuery("")} /> : null}
                        </div>
                      }
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </Col>
                </div>
              </div>

              <div
                style={{
                  padding: "1.5%",
                  // backgroundColor:"#fff"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {showArrows && (
                    <button
                      onClick={scrollLeft1}
                      style={{
                        width: "30px",
                        height: "6vh",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        background: "#f5f5f5",
                        cursor: "pointer",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        fontSize: "1.2rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {"<"}
                    </button>
                  )}
                  <div
                    ref={scrollContainerRef}
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      gap: "10px",
                      paddingBottom: "1%",
                      paddingLeft: "0.1rem",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    className="scroll-container"
                  >
                    <button
                      onClick={() => handleButtonClick("ALL")}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        // background: "#f5f5f5",
                        background: selectedCategories.length >= 0 ? "#2F3856" : "#f5f5f5",
                        color: selectedCategories.length >= 0 ? "white" : "#0F0718",
                        cursor: "pointer",
                        fontWeight: 600,
                        padding: "10px 15px",
                        height: "6vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      ALL
                    </button>
                    {categoriesData.map((category) => (
                      <button
                        // key={category.value}
                        key={category.m_product_category_id}
                        // onClick={() => handleButtonClick(category.value)}
                        onClick={() => handleButtonClick(category.m_product_category_id)}
                        style={{
                          padding: "10px 15px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          background: selectedCategories.includes(category.m_product_category_id) ? "#2F3856" : "#f5f5f5",
                          color: selectedCategories.includes(category.m_product_category_id) ? "white" : "#0F0718",
                          cursor: "pointer",
                          fontWeight: "500",
                          height: "6vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          whiteSpace: "nowrap",
                          width: "auto",
                        }}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                  {showArrows && (
                    <button
                      onClick={scrollRight}
                      style={{
                        width: "30px",
                        height: "6vh",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        background: "#f5f5f5",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                    >
                      {">"}
                    </button>
                  )}
                </div>

                <Table
                  className="sm-cart-table-pos"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "7px",
                    padding: "0px 7px",
                    height: "68vh",
                    background: "#fff",
                  }}
                  ref={tableBodyRef}
                  pagination={false}
                  sticky
                  id="sm-cart-table"
                  columns={[
                    {
                      title: "Item",
                      dataIndex: "productName",
                      key: "productName",
                      width: "20%",
                    },
                    {
                      title: "Category",
                      dataIndex: "productCategory",
                      key: "productCategory",
                      width: "15%",
                    },
                    {
                      title: "Brand",
                      dataIndex: "brand",
                      key: "brand",
                      width: "10%",
                      align: "center",
                    },
                    {
                      title: "UOM",
                      dataIndex: "uom",
                      key: "uom",
                      width: "10%",
                      align: "center",
                      render: (uomData) => {
                        return uomData && uomData.length > 0 ? uomData[0].ediCode : " ";
                      },
                    },
                    {
                      title: "Stock",
                      dataIndex: "qtyOnHand",
                      key: "qtyOnHand",
                      width: "10%",
                      align: "center",
                    },
                    {
                      title: "Request Qty",
                      dataIndex: "",
                      key: "",
                      width: "15%",
                      align: "center",
                      render: (text, record, index) => {
                        return (
                          <input
                            type="text"
                            value={requestQty[record.productId] || ""}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              const isDecimalAllowed = record?.uomData && record?.uomData[0]?.decimal === "Y";
                              if (isDecimalAllowed) {
                                if (/^\d*\.?\d*$/.test(value)) {
                                  setRequestQty((prev) => ({
                                    ...prev,
                                    [record.productId]: value,
                                  }));
                                }
                              } else {
                                if (/^\d*$/.test(value)) {
                                  setRequestQty((prev) => ({
                                    ...prev,
                                    [record.productId]: value,
                                  }));
                                }
                              }
                            }}
                            style={{
                              width: "70px",
                              textAlign: "center",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                        );
                      },
                    },
                  ]}
                  // dataSource={productsList}
                  // dataSource={filteredProductsList}
                  dataSource={displayedData}
                  loading={loading}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  marginTop: "0.5rem",
                  padding: "0px 1.5% 0px 0px",
                }}
              >
                <button
                  style={{
                    width: "10vw",
                    height: "6vh",
                    border: "1px solid #92909880",
                    borderRadius: "5px",
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    color: "#2F3856",
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    setAddStock(false);
                    setSelectedCategories("");
                    // setRequestQty("");
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{
                    width: "10vw",
                    height: "6vh",
                    border: "1px solid #92909880",
                    borderRadius: "5px",
                    color: "#fff",
                    backgroundColor: "#2F3856",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={handleAddProducts}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        ) : showHistory ? (
          <div></div>
        ) : (
          <>
            <Row
              style={{
                padding: "0.5% 0",
                height: "9vh",
                alignItems: "center",
                fontSize: "1vw",
              }}
            >
              <Col
                onClick={() => {
                  setTillLink(true);
                }}
                style={{ cursor: "pointer" }}
                span={1}
              >
                <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
              </Col>
              <Col span={3}>
                <img src={POSLogo} onClick={() => history.push("/pos")} style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }} />
              </Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "1.5em",
                    color: "#0F0718",
                    borderRadius: "2px",
                    marginTop: "0.45rem",
                    textAlign: "center",
                  }}
                >
                  Stock Indent
                </span>
              </Col>
              <Col
                span={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#0F0718",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1em",
                    paddingLeft: "1vw",
                  }}
                >
                  Indent No {inventoryOrder.indentNo}
                </span>
              </Col>
              <Col span={1} />

              {posConfig?.enableStockIndentHistory === "Y" ? (
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: "6vw",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    await getInventoryHistory();
                    setShowHistory(true);
                  }}
                >
                  <SalesHistoryIcon color={"#0F0718"} />
                </Col>
              ) : (
                <Col span={2} />
              )}
              {/* <Col span={2} /> */}
              <Col
                span={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1em",
                    color: "#929098",
                    marginLeft: "1.5vw",
                    marginRight: "1vw",
                  }}
                >
                  {" "}
                  {formattedDateTime}
                </span>
              </Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <img
                  onClick={() => {
                    setIsLocked(true);
                  }}
                  src={LockIcon}
                  style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    padding: "0.5vw",
                    border: "1px solid #3C424B",
                    borderRadius: "50%",
                  }}
                >
                  <img src={userIcon} style={{ height: "2.7vh" }} />
                </div>
                <div
                  style={{
                    marginLeft: "1vw",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#0F0718",
                      fontSize: "1em",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    {userData.user}
                  </span>
                  <span
                    style={{
                      color: "#0F0718",
                      fontSize: "0.8em",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                  </span>
                </div>
                <div style={{ marginLeft: "3%" }}>
                  <img
                    src={Online}
                    style={{
                      display: !navigator.onLine && "none",
                      height: "2.5vh",
                      cursor: "pointer",
                      paddingBottom: "0.5vh",
                    }}
                  />{" "}
                  &nbsp;
                  <img
                    src={Ofline}
                    style={{
                      display: navigator.onLine && "none",
                      height: "2.5vh",
                      cursor: "pointer",
                      paddingBottom: "0.5vh",
                    }}
                  />{" "}
                  &nbsp;
                </div>
              </Col>
            </Row>

            <div style={{ display: "flex", height: "91vh" }}>
              <div style={{ width: "63.9%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0 0 0 1.7%",
                  }}
                >
                  <div style={{ width: "93.5%" }}>
                    <Input
                      placeholder="Search Item Code/Description"
                      id="sm-product-search"
                      className="productSearch-sm"
                      style={{
                        borderRadius: "7px",
                        padding: "0.8vh 0.8vw",
                        borderColor: "#fff",
                      }}
                      value={productSearchInput}
                      onChange={(e) => {
                        setProductSearchInput(e.target.value);
                      }}
                      suffix={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {productSearchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => setProductSearchInput("")} /> : null}
                        </div>
                      }
                      prefix={<img src={SearchIcon} alt="" />}
                    />
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "4.5%",
                      margin: "0 1vw 0 0.5vw",
                      borderRadius: "7px",
                      height: "inherit",
                      borderColor: "#fff",
                    }}
                    id="sm-product-drawer"
                    // onClick={() => setIsProductsFilter(true)}
                    onClick={async () => {
                      await getInventoryProducts();
                      await getInventoryCategory();
                      setAddStock(true);
                    }}
                  >
                    <img src={FilterIcon} style={{ height: "2.7vh" }} />
                  </Button>
                </div>

                <div style={{ padding: "1.5%" }}>
                  <Table
                    className="sm-cart-table-pos"
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      border: "none",
                      borderRadius: "7px",
                      padding: "0px 7px",
                      height: "81vh",
                      background: "#fff",
                    }}
                    pagination={false}
                    sticky
                    id="sm-cart-table"
                    columns={[
                      {
                        title: "Item",
                        dataIndex: "productName",
                        key: "productName",
                        width: "35%",
                      },
                      {
                        title: "Category",
                        dataIndex: "productCategory",
                        key: "productCategory",
                        width: "20%",
                      },
                      {
                        title: "Brand",
                        dataIndex: "brand",
                        key: "brand",
                        width: "18%",
                        align: "center",
                      },
                      {
                        title: "UOM",
                        dataIndex: "uomData",
                        key: "uomData",
                        width: "15%",
                        align: "center",
                        render: (uomData) => {
                          return uomData && uomData.length > 0 ? uomData[0].ediCode : " ";
                        },
                      },
                      {
                        title: "Stock",
                        dataIndex: "qtyOnHand",
                        key: "qtyOnHand",
                        width: "12%",
                        align: "center",
                      },
                      {
                        title: "Reccomd Qty",
                        dataIndex: "",
                        key: "",
                        width: "22%",
                        align: "center",
                      },
                      {
                        title: "Request Qty",
                        dataIndex: "requestQty",
                        key: "requestQty",
                        width: "22%",
                        align: "center",
                        render: (text, record, index) => {
                          return (
                            <input
                              type="text"
                              // value={text || ""}
                              value={record.requestQty || ""}
                              onChange={(e) => handleRequestQtyChange(e.target.value, record)}
                              style={{
                                width: "70px",
                                textAlign: "center",
                                padding: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            />
                          );
                        },
                      },
                      {
                        title: "",
                        dataIndex: "",
                        key: "",
                        width: "10%",
                        render: (text, record, index) => {
                          return (
                            <div style={{ position: "relative", display: "flex", justifyContent: "space-evenly" }}>
                              <img
                                src={DeleteProduct}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteInventoryItems(record);
                                }}
                                id="sm-product-delete"
                                style={{
                                  height: "2.5vh",
                                  cursor: "pointer",
                                  right: 1,
                                  top: "15%",
                                }}
                              />
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setRemarksPopup(true);
                                  setIsInputFocused(true);
                                  setSelectedRowIndex(index);
                                  setRemarksTextArea(record.remarks || "");
                                }}
                                src={NotesImage}
                                alt=""
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  cursor: "pointer",
                                  paddingleft: "0.8vw",
                                }}
                              />
                            </div>
                          );
                        },
                        align: "center",
                      },
                    ]}
                    dataSource={inventoryOrder.items}
                    rowKey="key"
                    loading={false}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "35.6%",
                }}
              >
                <div
                  style={{
                    padding: "0% 0% 0 0.4%",
                    borderRadius: "7px",
                    paddingBottom: "0",
                    height: "39vh",
                    marginRight: "1%",
                    backgroundColor: "#fff",
                    position: "relative",
                    fontSize: "1vw",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4% 2% 0 3%",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Requested By</p>
                    </Col>

                    <Col span={16}>{userData.user}</Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4% 2% 0 3%",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Required By</p>
                    </Col>

                    <Col span={16}>
                      <DatePicker style={{ border: "1px solid #92909880" }} placeholder="Select date" format="DD/MM/YYYY" />
                    </Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6% 2% 0 3%",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Target Location</p>
                    </Col>
                    <Col span={16}>
                      <EnvironmentOutlined onClick={getWarehouseData} />
                    </Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "3% 2% 0 3%",
                    }}
                  >
                    <Col span={12} style={{ fontSize: "2em", color: "#0F0718", textAlign: "center" }}>
                      {selectedWarehouse?.name || "Main Warehouse"}
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "1.5vh",
                    padding: "0% 0% 0 0.4%",
                    borderRadius: "7px",
                    height: "24vh",
                    marginRight: "1%",
                    backgroundColor: "#fff",
                    position: "relative",
                    fontSize: "1vw",
                  }}
                >
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "3% 2% 0 3%",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}> Total Items</p>
                    </Col>

                    <Col span={16}>0</Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "3% 2% 0 3%",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Total Requested Qty</p>
                    </Col>
                    <Col span={16}>0</Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "1% 2% 0 3%",
                      fontSize: "1vw",
                    }}
                  >
                    <Col span={8} style={{ fontSize: "1em", color: "#0F0718" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Comments</p>
                    </Col>

                    <Col span={14} style={{ paddingTop: "2%" }}>
                      <TextArea
                        value={comments}
                        onFocus={() => {
                          setShowComments(true);
                        }}
                        style={{
                          resize: "none",
                        }}
                      ></TextArea>
                    </Col>
                  </Row>
                </div>

                <Button
                  className="suggest-qty-button"
                  style={{
                    marginTop: "1.5vh",
                    width: "100%",
                    padding: "0% 0% 0 0.4%",
                    borderRadius: "7px",
                    paddingBottom: "0",
                    height: "14.5vh",
                    marginRight: "1%",
                    backgroundColor: "#fff",
                    position: "relative",
                    fontSize: "1vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                  }}
                >
                  Suggest Qty
                </Button>

                <Row style={{ margin: "2% 0 0 0" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      style={{
                        width: "95%",
                        height: "6.5vh",
                        border: "1px solid #92909880",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (inventoryOrder.items.length > 0) localStorage.setItem("inventoryOrderData", JSON.stringify(inventoryOrder));
                      }}
                    >
                      Save
                    </button>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      if (inventoryOrder.items.length > 0) submitOrder();
                    }}
                  >
                    <button
                      style={{
                        width: "95%",
                        height: "6.5vh",
                        border: "1px solid #92909880",
                        borderRadius: "5px",
                        color: "#fff",
                        backgroundColor: "#2F3856",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}

        <InventoryHistorySupermarket {...props} />
      </div>
    </Spin>
  );
};

export default SuperMarketInventory;
