import React from "react";
import { clock, timeStamp, timeValidator, currentDay, dateValidator } from "../../../utility/clock";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import db from "../../../database";
import { upsertPOSLogService } from "../../../services";

function parseDateTime(dateTimeString) {
  return new Date(dateTimeString.replace(" ", "T") + "Z");
}

function calculateDuration(startTime, endTime) {
  const durationMilliseconds = endTime - startTime;
  const totalSeconds = Math.floor(durationMilliseconds / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
}

function formatDuration(duration) {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(duration.hours).padStart(2, "0");
  const minutes = String(duration.minutes).padStart(2, "0");
  const seconds = String(duration.seconds).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
const currentTime = getCurrentDateTime();
const getDescription = (record, type, OPK) => {
  let result = "";
  record.items?.forEach((item) => {
    switch (type) {
      case "DLN":
        result += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.unitPrice}, `;
        break;
      case "DON":
        result += `Till Opening Date and Time: ${record.orderTime}, `;
        break;
      case "DCL":
        result += `Till Closing Date and Time: ${record.orderTime}, `;
        break;
      case "DOR":
        result += `Order No: ${record.documentno}, SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.unitPrice}, Customer: ${record.customer?.name}, `;
        break;
      case "SRD":
        result += `Sales Rep ID: ${item.salesRepId}, Sales Rep Name: ${item.salesRepName}, Order No: ${record.documentno}, `;
        break;
      case "LSD":
        result += `Sales Rep ID: ${item.salesRepId}, Sales Rep Name: ${item.salesRepName}, Order No: ${record.documentno}, SKU: ${item.value}, `;
        break;
      case "SLR":
        result += `Order No: ${record.documentno}, SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.unitPrice}, Reason: null, `;
        break;
      case "DPO":
        result += `Order No: ${record.documentno}, SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.unitPrice}, `;
        break;
      case "RQT":
        result += `SKU: ${item.value}, Qty: ${item.weight}, Unit Price: ${item.unitPrice}, `;
        break;
      case "LAY":
        result += `Order No: ${record.documentno}, SKU: ${item.value}, Qty: ${item.weight}, `;
        break;
      case "PAY":
        result += `Order No: ${record.documentno}, Method: ${record.payments[0]?.name}, Amount: ${record.payments[0]?.amount}, `;
        break;
      case "DAP":
        result += `Order No: ${record.documentno}, Pricing Rule Name: ${item.discountName}, Discount Amount: ${item.discount}, Customer ID: ${record.customer?.cwrCustomerId}, Customer Name: ${record.customer?.name}, `;
        break;
      case "OPK":
        result += `Order No: ${record.documentno}, Action: ${OPK}, Time: ${item.time}, `;
        break;
      case "GCR":
        result += `Card No: ${item.cardNo}, Amount Redeemed: ${item.amount}, Customer ID: ${record.customer?.cwrCustomerId}, Customer Name: ${item.customerName}, `;
        break;
      case "CUP":
        result += `Order No: ${record.documentno}, Code: ${record.couponRefId}, Amount: ${item.amount}, Customer ID: ${record.customer?.cwrCustomerId}, Customer Name: ${record.customer?.name}, `;
        break;
      case "LOR":
        result += `Customer ID: ${record.customer?.cwrCustomerId}, Points: ${item.points}, `;
        break;
      default:
        result = `Unknown type: ${type}, `;
        break;
    }
  });
  if (type === "GCI") {
    if (record.customer?.name === "Anonymous Customer")
      result = `Card No: ${record.items[0]?.cardNo}, Amount: ${record.items[0]?.realPrice ? record.items[0]?.realPrice : record.items[0]?.salePrice}, `;
    else {
      result = `Card No: ${record.items[0]?.cardNo}, Amount: ${record.items[0]?.realPrice ? record.items[0]?.realPrice : record.items[0]?.salePrice}, Customer ID: ${
        record.customer?.cwrCustomerId
      }, Name: ${record.customer?.name} `;
    }
  } else if (type === "ACT") {
    result = `Customer ID: ${record.customer?.cwrCustomerId}, Name: ${record.customer?.name}, Contact: ${record.customer?.mobileNo}, `;
  } else if (type === "RCT") {
    result = `Customer ID: ${record.customer?.cwrCustomerId}, Name: ${record.customer?.name}, Order No: ${record.documentno}, `;
  } else if (type === "CSU") {
    result = `Customer ID: ${record.customer?.cwrCustomerId}, Updates: ${record.customer?.mobileNo}, `;
  } else if (type === "LGI") {
    result = `User ID: ${JSON.parse(localStorage.getItem("userData"))?.user_id}, Name: ${JSON.parse(localStorage.getItem("userData"))?.user}, Login Time: ${currentTime}, `;
  } else if (type === "LGO") {
    result = `User ID: ${JSON.parse(localStorage.getItem("userData"))?.user_id}, Name: ${JSON.parse(localStorage.getItem("userData"))?.user}, Logout Time: ${currentTime}, `;
  } else if (type === "CAI") {
    result = `Amount: ${record?.amount}, Reason: Initial Float, `;
  } else if (type === "CAO") {
    result = `Amount: ${record?.amount}, Reason: Cash Deposit, `;
  } else if (type === "RRC") {
    result += `Order No: ${record.documentno}, Approver: ${OPK}, `;
  }
  result = result.trim().slice(0, -1); // Remove trailing ';'
  return result;
};

// Upsert POS Log //
const upsertPOSLog = async (record, type, OPK) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const timeMark = timeStamp();
  const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
  const trxId = uuidv4().replace(/-/g, "").toUpperCase();
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  const posLogArray = [];
  let description = getDescription(record, type, OPK);
  let formattedDuration = null;

  // Calculate duration if orderTimeDetails are present
  if (record.orderTimeDetails) {
    const { orderStartTime, orderEndTime } = record.orderTimeDetails;
    const startTime = parseDateTime(orderStartTime);
    const endTime = parseDateTime(orderEndTime);
    const duration = calculateDuration(startTime, endTime);
    formattedDuration = formatDuration(duration);
  }
  posLogArray.push(`{
      type: "${type}", 
      action: "LOG", 
      description: "${description}", 
      date: "${currentDate}",
      time: "${timeMark}",    
      orderNo: "${record?.documentno}",
      remarks: "",
      transactionId: "${trxId}"
      status: "SCS"
      duration: ${formattedDuration ? `"${formattedDuration}"` : null}
    }`);

  function addLogActivity(db, type, description, timeMark, formattedDuration) {
    if (db.logActivity) {
      return db.logActivity
        .add({
          type: type,
          description: description,
          time: timeMark,
          duration: formattedDuration ? formattedDuration : null,
        })
        .catch((error) => {
          console.error("Error adding log activity:", error);
          // Optionally, you can attempt to create the object store here
          // and retry the operation
        });
    } else {
      console.warn("logActivity object store does not exist");
      // Optionally, you can attempt to create the object store here
    }
  }

  // Usage
  addLogActivity(db, type, description, timeMark, formattedDuration);

  let dbPromise = indexedDB.open("cwpos", 1);

  dbPromise.onupgradeneeded = function (event) {
    let db = event.target.result;
    if (!db.objectStoreNames.contains("logActivity")) {
      db.createObjectStore("logActivity", { keyPath: "id", autoIncrement: true });
    }
  };

  let shouldCallAxios = false;
  if (posConfig?.[type] === "Y") {
    shouldCallAxios = true;
  }
  if (shouldCallAxios) {
   try {
    const response = await upsertPOSLogService(tillData.tillAccess.cwrTill.cwrTillID,tillData.tillAccess.csUserId,tillData.tillAccess.csBunit.csBunitId,posLogArray);
    console.log(response)
   }catch {
    console.error("Error calling upsertPOSLogService")
   }
  }
};
export default upsertPOSLog;
