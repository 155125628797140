import React from "react";
import { useState, useRef, useEffect } from "react";
import ModalComponent from "./ModalComponent";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import Axios from "axios";
const PurchaseOrderCore = (props) => {
  const RenderComponent = props.component;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
  const { access_token, username } = getOAuthHeaders();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const textAreaRef = useRef(null);
  const keyboardRef = useRef(null);

  const formattedDateTime = `${new Date().toLocaleTimeString()} | ${new Date().toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [purchaseRemarks, setPurchaseRemarks] = useState(false);
  const [remarksText, setRemarksText] = useState("");
  const [savedRemarks, setSavedRemarks] = useState("");
  const [productData, setProductData] = useState([]);

  const [visibleProductsModal, setVisibleProductsModal] = useState(false);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    getSupplierData();
  }, []);

  const getSupplierData = async () => {
    try {
      // Proper JSON formatting
      const filterData = JSON.stringify({ name: "", p_supplier_id: "" });
      const newStringifiedFields = filterData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);
      // Correct GraphQL query string construction
      const paramsInput = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"6494408f4ce06c1d3c60a75b", params: ${newStringRequest})}`,
      };

      // API request
      const supplierRes = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${access_token}`,
        },
      });

      // Parsing the response
      const parsedData = JSON.parse(supplierRes.data.data.executeAPIBuilder);
      setSupplierData(parsedData);
      console.log(parsedData, "rsedData");
    } catch (err) {
      console.log("Error fetching warehouse data", err);
    }
  };

  const getProductsList = async (id) => {
    try {
      // Proper JSON formatting
      const filterData = JSON.stringify({ supplierId: id || "" });
      const newStringifiedFields = filterData.replace(/\\"/g, '\\"');
      const newStringRequest = JSON.stringify(newStringifiedFields);
      // Correct GraphQL query string construction
      const paramsInput = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"664edd98f534261632c87686", params: ${newStringRequest})}`,
      };

      // API request
      const supplierProductsRes = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${access_token}`,
        },
      });

      // Parsing the response
      const parsedData = JSON.parse(supplierProductsRes.data.data.executeAPIBuilder);
      setProductData(parsedData);
      setVisibleProductsModal(true);
      console.log(parsedData, "productsData");
    } catch (err) {
      setVisibleProductsModal(false);
      console.log("Error fetching warehouse data", err);
    }
  };

  const selectSupplier = (id) => {
    const supplier = supplierData.find((s) => s.recordid === id);
    setSelectedSupplier(supplier);
    getProductsList(id);
  };

  const componentProps = {
    loading,
    setLoading,
    formattedDateTime,
    colorVariable,
    supplierData,
    selectedSupplier,
    setSelectedSupplier,
    selectSupplier,
    productData,
    visibleProductsModal,
    setVisibleProductsModal,
    cart,
    setCart,
    tillData,
    access_token,
    userData,
    purchaseRemarks,
    setPurchaseRemarks,
    remarksText,
    setRemarksText,
    savedRemarks,
    setSavedRemarks,
  };
  return (
    <div>
      <RenderComponent {...componentProps} />
      <ModalComponent {...componentProps} />
    </div>
  );
};

export default PurchaseOrderCore;
